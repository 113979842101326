<style scoped lang="scss">
.tag-users {
    padding-bottom: 10px;
    line-height: 1.2;
    .icon-user-circle1 {
        margin-right: 10px;
        font-size: 24px;
        color: #ccc;
    }
}
.selected-tags {
    padding-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    .label {
        margin-bottom: 15px;
    }
    .el-tag {
        margin-right: 10px;
        margin-bottom: 15px;
    }
}
.tag-item {
    cursor: pointer;
    color: #666;
    border-color: #dadadd;
    margin-right: 10px;
    margin-top: 10px;
}
.dialog-list li {
  width: 50%;
  float: left;
  padding-left: 30px;
  padding-right: 20px;
  line-height: 30px;
  cursor: pointer;
  &.checked {
    color: #ff5000;
  }
  &:hover {
    background: #f8f8f8;
  }
}
.filter-header {
  padding-right: 50px;
  height: 40px;
  overflow: hidden;
  &.open {
    height: auto;
  }
  .collapse-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}
</style>

<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <el-row class="filter-header" :class="panel.search ? 'open': ''" :gutter="20" >
        <el-col>
          <el-button class="collapse-btn" type="text" @click="panelCollapse('search')">
            <span v-if="!panel.search">更多选项 <i class="el-icon-arrow-down"></i></span>
            <span v-else>收起 <i class="el-icon-arrow-up"></i></span>
          </el-button>
<!--          <el-input class="input-m" placeholder="手机号后四位" v-model="params.mobile" clearable>-->
<!--            <el-button slot="append" icon="el-icon-search" @click="memberSearch"></el-button>-->
<!--          </el-input>-->
<!--          <el-select v-model="params.have_consume" @change="memberSearch" placeholder="有无购买记录" clearable>-->
<!--            <el-option label="有记录" value="true"></el-option>-->
<!--            <el-option label="无记录" value="false"></el-option>-->
<!--          </el-select>-->
<!--          <el-select v-model="params.vip_grade" @change="vipGradeChange" placeholder="付费会员" clearable>-->
<!--            <el-option key="notvip" label="非付费会员" value="notvip"></el-option>-->
<!--            <el-option v-for="item in vipGrade" :key="item.lv_type" :label="item.grade_name" :value="item.lv_type"></el-option>-->
<!--          </el-select>-->
<!--          <el-select v-model="params.grade_id" @change="ifLevelData" placeholder="会员等级" clearable>-->
<!--            <el-option v-for="item in levelData" :key="item.grade_id" :label="item.grade_name" :value="item.grade_id"></el-option>-->
<!--          </el-select>-->
<!--          <el-select v-model="params.tag_id" placeholder="请选择标签" @change="memberSearch" clearable>-->
<!--            <el-option v-for="item in tag.list" :key="item.tag_id" :label="item.tag_name" :value="item.tag_id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--          <el-input class="input-m" placeholder="推荐人手机号" v-model="params.inviter_mobile" clearable>-->
<!--            <el-button slot="append" icon="el-icon-search" @click="memberSearch"></el-button>-->
<!--          </el-input>-->
          <el-date-picker v-model="created" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="dateChange" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
<!--          <el-input class="input-m" placeholder="备注" v-model="params.remarks" clearable>-->
<!--            <el-button slot="append" icon="el-icon-search" @click="memberSearch"></el-button>-->
<!--          </el-input>-->
<!--          <el-input class="input-m" placeholder="姓名" v-model="params.username" clearable>-->
<!--            <el-button slot="append" icon="el-icon-search" @click="memberSearch"></el-button>-->
<!--          </el-input>-->
<!--          <el-input v-if="system_mode === 'standard'" class="input-m" placeholder="导购员手机号" v-model="params.salesman_mobile" clearable>-->
            <el-button icon="el-icon-search" @click="memberSearch"></el-button>
<!--          </el-input>-->
<!--          <shop-select v-if="$store.getters.login_type != 'distributor'"  distributors :performInit='performInit' @update="storeHandle" @init="init"></shop-select>-->
          <!--distributors wxshops 需要哪个api传哪个-->
<!--          <el-select v-if="system_mode === 'standard'" v-model="currentShop" @change="shopHandle" clearable placeholder="请选择门店">-->
<!--            <el-option v-for="item in wxShopsList" :key="item.wxShopId" :label="item.storeName" :value="item.wxShopId">-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-button-group>
<!--            <el-button type="primary" @click="batchActionDialog('rel_tag')">打标签</el-button>-->
            <!-- <el-button type="primary" @click="batchActionDialog('give_coupon')">赠送优惠券</el-button> -->
<!--            <el-button v-if="$store.getters.login_type != 'distributor'" type="primary" @click="batchActionDialog('send_sms')">群发短信</el-button>-->
<!--            <el-button v-if="$store.getters.login_type != 'distributor'" type="primary" @click="handleVipGradeDelay(false)">付费会员延期</el-button>-->
<!--            <el-button type="primary" v-if="system_mode === 'standard'" @click="setSalesman(false)">设置导购员</el-button>-->
<!--            <el-button v-if="$store.getters.login_type != 'distributor'" type="primary" @click="batchActionDialog('set_grade')">会员等级设置</el-button>-->
<!--            <el-button type="primary" plain icon="el-plus-circle" @click="exportData">导出</el-button>-->
          </el-button-group>
<!--           <el-popover-->
<!--            placement="top-start"-->
<!--            width="200"-->
<!--            trigger="hover"-->
<!--            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">-->
<!--            <i class="el-icon-question" slot="reference"></i>-->
<!--          </el-popover>-->
        </el-col>
      </el-row>
<!--      <el-dialog title="会员下载" :visible.sync="downloadView" :close-on-click-modal="false">-->
<!--        <template v-if="downloadUrl">-->
<!--          <a :href="downloadUrl" download>{{downloadName}}</a>-->
<!--        </template>-->
<!--      </el-dialog>-->
      <el-card>
        <el-table :data="memberData" :row-key="getRowKeys" @selection-change="handleSelectionChange" v-loading="loading">
          <el-table-column type="selection" align="center" label="全选"></el-table-column>
<!--          <el-table-column type="expand" label="会员标签" width="100">-->
<!--            <template slot-scope="scope">-->
<!--              <template v-if="scope.row.tagList.length > 0">-->
<!--                <el-tag size="mini" closable v-for="(tag) in scope.row.tagList" :key="tag.tag_id" @close="relTagDelEvent(tag.tag_id, scope.row.user_id)" :color="tag.tag_color" :style="'color:'+ tag.font_color" effect="dark">{{tag.tag_name}}</el-tag>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span class="muted">暂无会员标签</span>-->
<!--              </template>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column prop="user_card_code" v-if="false" label="会员卡编号" width="130"></el-table-column>-->
<!--          <el-table-column prop="username" label="姓名" width="100"></el-table-column>-->

          <!-- <el-table-column prop="mobile" label="手机号"> -->
            <!-- <template slot-scope="scope"> -->
              <!-- {{scope.row.mobile}} -->
<!--              <el-tooltip v-if="$store.getters.login_type != 'distributor'" class="item" effect="dark" content="修改手机号" placement="top-start">-->
<!--                <el-button class="el-icon-edit" type="text" size="mini" @click="editMobile(scope.row)"></el-button>-->
<!--              </el-tooltip>-->
            <!-- </template> -->
          <!-- </el-table-column> -->
          <el-table-column prop="amp_dummy_id" label="系统随机ID"></el-table-column>
          <el-table-column prop="created_date" label="首次登陆时间"></el-table-column>
<!--          <el-table-column prop="sex" label="性别" width="70">-->
<!--            <template slot-scope="scope">-->
<!--              <span v-if="scope.row.sex=='2'">女</span>-->
<!--              <span v-else-if="scope.row.sex=='1'">男</span>-->
<!--              <span v-else>未知</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column prop="inviter" label="推荐人" width="130"></el-table-column>-->
<!--          <el-table-column prop="created" v-if="false" label="注册日期" width="120">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tooltip placement="top">-->
<!--                <div slot="content">注册时间<br/>{{scope.row.created|datetime('yyyy-MM-dd hh:mm:ss')}}</div>-->
<!--                <span>{{scope.row.created|datetime}}</span>-->
<!--              </el-tooltip>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column prop="disabled" label="禁用" width="80">-->
<!--            <template slot-scope="scope">-->
<!--              <el-switch v-model="scope.row.disabled" active-value="1" inactive-value="0" @change="acitonDisabled(scope.$index, scope.row)" active-color="#ff4949" inactive-color="#ccc"></el-switch>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column  label="备注">-->
<!--            <template slot-scope="scope">-->
<!--              <span v-if="scope.row.remarks">{{scope.row.remarks}}</span>-->
<!--              <span v-else class="muted">暂无备注</span>-->
<!--              <el-tooltip class="item" effect="dark" content="编辑备注" placement="top-start">-->
<!--                <el-button class="el-icon-edit" type="text" size="mini" @click="isEdit(scope.row)"></el-button>-->
<!--              </el-tooltip>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" @click="getDetail(scope.row.user_id)">详情</el-button>
<!--              <el-button v-if="$store.getters.login_type != 'distributor'" type="text" @click="infoUpdate(scope.row)">编辑基础信息</el-button>-->
<!--              <el-button v-if="$store.getters.login_type != 'distributor'" type="text" @click="gradeUpdate(scope.row)">编辑会员等级</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="content-center content-padded"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.page"
          :page-sizes="[10, 30, 50]"
          :page-size='params.pageSize'
          layout="total, sizes, prev, pager, next, jumper"
          :total='total_count'>
        </el-pagination>
      </el-card>
      <el-dialog :title="dialogTitle" :before-close="handleCancelLabelsDialog" :visible.sync="dialogIsShow" width="50%">
        <template v-if="params.action_type == 'give_coupon'">
          <el-radio-group v-model="card_type" @change="changeStaffCouponsPage(1)">
            <el-radio-button label="all" value="all">全部</el-radio-button>
            <el-radio-button label="cash" value="cash">代金券</el-radio-button>
            <el-radio-button label="discount" value="discount">折扣券</el-radio-button>
            <el-radio-button label="gift" value="gift">兑换券</el-radio-button>
          </el-radio-group>
          <ul class="dialog-list clearfix" v-loading="staffCoupons.loading">
            <template v-for="(item, index) in staffCoupons.list">
              <li :key="index" :class="item.checked?'checked':''" @click="selectStaffItems(item)"> <i v-if="item.checked" class="el-icon-check"></i> {{item.title}}</li>
            </template>
          </ul>
          <div class="content-padded">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="changeStaffCouponsPage"
              :current-page="staffCoupons.page.currentPage"
              :page-size="staffCoupons.page.pageSize"
              :total="staffCoupons.page.total">
            </el-pagination>
          </div>
          <div style="display: none;">
            <template v-for="(item, index) in staffCoupons.temp">
              <li :key="index">{{item.title}}</li>
            </template>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitSelected">确定赠送</el-button>
          </span>
        </template>
        <template v-if="params.action_type == 'rel_tag'">
          <div class="selected-tags view-flex">
            <div class="label">已选中标签：</div>
            <div class="view-flex-item">
              <el-tag :key="index" v-for="(tag, index) in tag.currentTags" closable size="small" :disable-transitions="false" @close="tagRemove(index)">
                {{tag.tag_name}}
              </el-tag>
            </div>
          </div>
          <div>
            <el-tag class="tag-item" :key="index" v-for="(tag, index) in tag.tags" size="medium" color="#ffffff" :disable-transitions="false" @click.native="tagAdd(tag, index)">
              {{tag.tag_name}}
            </el-tag>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="tag.dialog = false">取 消</el-button>
            <el-button type="primary" @click="submitMemberTag">确 定</el-button>
          </span>
        </template>
        <template v-if="params.action_type == 'send_sms'">
          <el-form>
            <el-form-item class="content-center">
              <el-input focus type="textarea" :rows="4" placeholder="请输入短信内容" v-model="sms_content" @blur="checkNum"></el-input>
              <span>{{sms_content.length}}</span>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="onSmsSends">确定发送</el-button>
            </el-form-item>
          </el-form>
          <template>
            <el-alert title="短信基础" type="info" description="1、短信字符<=67个字符，按照70个字符一条短信计算；短信内容中，汉字、字母、符号、数字、空格全按一个字符算，不分半角、全角。含有标签（如短信签名、商品名、链接）的短信以实际发送时字符数计算；超过规定的67个字符的短信内容将会被拆分为两条或多条短信
                            2、短信内容禁止有‘测试，test，错乱字符’等，这类短信会被拦截不予发送。
                            3、短信接收不到原因：手机号黑名单发送不了；内容不正规不允发送；时间段导致延迟；被手机拦截软件拦截；该手机号短信接收上限；该手机号状态不正常（欠费，停机，空号，信号不正常）详询客服
                            4、 短信内容切记出现敏感类字眼，如出现有的话，系统是发送不出去被拦截掉的哦。
                            5、 短信内容当中不允许出现两个【】或 ⎣⎤ 括弧">
            </el-alert>
            <el-alert title="营销短信（手动输入的短信）" type="info" description="营销短信发送时间为8：00~21:00，双十一双十二大促期间，短信发送时间可24小时。">
            </el-alert>
            <el-alert title="短信限制注意事项" type="info" description="1、通知短信加营销短信同一号码月限制50条。2、相同内容，相同手机号，日限制2条，第3条会被拦截。">
            </el-alert>
          </template>
        </template>
        <template v-if="params.action_type == 'vip_delay'">
          <el-form label-width="100px">
            <el-form-item class="content-center" label="付费会员类型">
              <el-row>
                <el-col :span="8">
                  <el-select v-model="vipGradeDelay.vip_grade_id" style="width: 100%;">
                    <el-option v-for="item in vipGrade" :key="item.lv_type" :label="item.grade_name" :value="item.vip_grade_id"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="content-center" label="延期条件">
              <el-row>
                <el-col :span="8">
                  <el-select v-model="vipGradeDelay.filter" style="width: 100%;">
                    <el-option key="users" label="指定会员 或 指定条件" value="users"></el-option>
                    <el-option key="expired" label="付费会员已失效" value="expired"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="content-center" label="延期天数">
              <el-row>
                <el-col :span="8">
                  <el-input v-model="vipGradeDelay.add_day"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="handleVipGradeDelay(true)">确定延期</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-if="params.action_type == 'set_grade'">
          <el-form label-width="100px">
            <el-form-item class="content-center" label="会员等级">
              <el-row>
                <el-col :span="8">
                  <el-select v-model="gradeForm.grade_id" style="width: 100%;">
                    <el-option v-for="item in levelData" :key="item.grade_id" :label="item.grade_name" :value="item.grade_id"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="content-center" label="备注">
              <el-row>
                <el-col :span="20">
                  <el-input focus type="textarea" :rows="3"  v-model="gradeForm.remarks"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="gradeUpdateSubmit">确定修改</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-if="params.action_type == 'set_saleman'">
          <el-col :gutter="20">
            <el-input class="input-m" placeholder="导购手机号" v-model="salesmanPaging.mobile" clearable>
              <el-button slot="append" icon="el-icon-search" @click="salesmanSearch"></el-button>
            </el-input>
            <el-input class="input-m" placeholder="导购姓名" v-model="salesmanPaging.name" clearable>
              <el-button slot="append" icon="el-icon-search" @click="salesmanSearch"></el-button>
            </el-input>
          </el-col>
          <el-table :data="salesman" highlight-current-row @row-click="handleSalesmanChange" v-loading="loadingSalesman">
            <el-table-column label="ID" prop="salesperson_id">
            </el-table-column>
            <el-table-column label="导购员" prop="salesman_name">
            </el-table-column>
            <el-table-column label="手机号" prop="mobile">
            </el-table-column>
          </el-table>
          <el-pagination
            class="content-center content-padded"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="salesmanPaging.page"
            :page-sizes="[10, 30, 50]"
            :page-size='salesmanPaging.pageSize'
            layout="total, sizes, prev, pager, next, jumper"
            :total='salesmanPaging.total_count'>
          </el-pagination>
          <!-- <el-form label-width="100px">
            <el-form-item class="content-center" label="选择导购员">
              <el-row>
                <el-col :span="8">
                  <el-select v-model="salesman_id" style="width: 100%;">
                    <el-option v-for="item in salesman" :key="item.salesman_id" :label="item.salesman_name" :value="item.salesman_id"></el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item class="content-center">
            </el-form-item>
          </el-form> -->
          <div class="content-padded content-center">
            <el-button type="default" @click="dialogIsShow = false">取消</el-button>
            <el-button type="primary" @click="setSalesman(true)">确定</el-button>
          </div>
        </template>
        <template v-if="params.action_type == 'basic_info'">
          <el-form :model="membersSetting" label-width="100px">
            <el-form-item class="content-center" v-for="(item, key) in membersSetting" :key="key" :label="item.name">
              <template v-if="item.element_type == 'input'">
                <el-input v-model="basicInfo[key]"></el-input>
              </template>
              <template v-if="item.element_type == 'select'">
                <template v-if="key == 'birthday'">
                  <el-date-picker
                          style="width: 100%"
                          v-model="basicInfo[key]"
                          type="date"
                          placeholder="选择日期"
                          :clearable="false"
                          :editable="false">
                  </el-date-picker>
                </template>
                <template v-else-if="key == 'sex'">
                  <el-select v-model="basicInfo[key]" style="width: 100%;">
                    <el-option v-for="sex in sexoption" :key="sex.value" :label="sex.label" :value="sex.value"></el-option>
                  </el-select>
                </template>
                <template v-else>
                  <el-select v-model="basicInfo[key]" style="width: 100%;">
                    <el-option v-for="(op_item, op_key) in item.items" :key="op_key" :label="op_item" :value="op_key.toString()"></el-option>
                  </el-select>
                </template>
              </template>
              <template v-if="item.element_type == 'checkbox'">
                <template v-if="key == 'habbit'">
                  <el-select
                          style="width: 100%"
                          v-model="basicInfo[key]"
                          multiple
                          filterable
                          allow-create
                          default-first-option
                          placeholder="请选择爱好">
                    <el-option
                            v-for="(ha_item, op_key) in item.items"
                            :key="ha_item.name"
                            :label="ha_item.name"
                            :value="ha_item.name">
                    </el-option>
                  </el-select>
                </template>
              </template>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="infoUpdateSubmit">确定修改</el-button>
            </el-form-item>
          </el-form>
        </template>
      </el-dialog>
      <el-dialog title="修改会员手机号" class="right-dialog" :visible.sync="editMobileDialog" :before-close="handleCancelLabelsDialog">
        <template>
          <el-form>
            <el-form-item label-width="100px" label="会员名称">
              {{form.username}}
            </el-form-item>
            <el-form-item label-width="100px" label="原手机号">
              {{form.oldMobile}}
            </el-form-item>
            <el-form-item label-width="100px" label="新手机号">
              <el-input v-model="form.newMobile" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="onEditMobileSubmit">确定添加</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template>
          <el-table :data="operateLog" height="580" border v-loading="loading">
            <el-table-column prop="old_data" label="原手机号"></el-table-column>
            <el-table-column prop="new_data" label="修改后手机号"></el-table-column>
            <el-table-column prop="operater" label="操作员"></el-table-column>
            <el-table-column prop="created" label="操作时间"></el-table-column>
          </el-table>
        </template>
      </el-dialog>
      <el-dialog title="修改会员备注" class="right-dialog" :visible.sync="isEditRemarks" :before-close="handleCancelLabelsDialog">
        <template>
          <el-form>
            <el-form-item label-width="100px" label="备注">
              <el-input v-model="remarksForm.remarks" placeholder="输入备注"></el-input>
            </el-form-item>
            <el-form-item class="content-center">
              <el-button type="primary" @click="editRemarks()">确定</el-button>
            </el-form-item>
          </el-form>
        </template>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  import store from '@/store'
import {
  mapGetters
} from 'vuex'
import {
  getMembers,
  memberSmsSend,
  getTagList,
  usersRelTagsDel,
  tagSearchUser,
  updateMemberMobile,
  getMemberOperateLog,
  memberExport,
  updateMemberInfo,
  setSindusersalespersonrel,
  updateMemberGrade,
  batchupdateMemberGrade,
  batchOperating,
  getMemberRegisterSetting,
  updateMemberBasicInfo
} from '../../../api/member'
import {
  getGradeList
} from '../../../api/membercard'
import {
  getSalesmanList,
  setMemberSalesman,
  getDistributorEasyList
} from '../../../api/marketing'
import {
  getEffectiveCardList
} from '../../../api/cardticket'
import {
  giveCoupons
} from '../../../api/promotions'
import {
  listVipGrade,
  batchReceiveMemberCard
} from '../../../api/cardticket'
import {
  getWxShopsList
} from '../../../api/shop'
import shopSelect from '@/components/shopSelect'

export default {
  components: {
    shopSelect
  },
  data() {
    return {
      panel: {
        search: false
      },
      is_distributor: false,
      isEditRemarks: false,
      loading: false,
      mobile: '',
      total_count: 0,
      memberData: [],
      vipGradeDelay: {
        vip_grade_id: "",
        add_day: 0,
        filter: 'users'
      },
      levelData: [],
      user_id: [],
      dialogTitle: '',
      dialogIsShow: false,
      card_type: 'all',
      staffCoupons: {
        dialog: false,
        temp: [],
        list: [],
        checked: [],
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0
        }
      },
      remarksForm: {
        user_id: '',
        remarks: ''
      },
      gradeForm: {
        user_id: '',
        grade_id: '',
        old_grade_id: '',
        remarks: ''
      },
      smsUserData: [],
      sms_content: '',
      tag: {
        dialog: false,
        editUser: [],
        list: [],
        form: {
          tag_ids: [],
          user_ids: []
        },
        currentTags: [],
        tags: []
      },
      editMobileDialog: false,
      form: {
        oldMobile: '',
        user_id: '',
        newMobile: ''
      },
      params: {
        page: 1,
        pageSize: 10,
        vip_grade: '',
        tag_id: '',
        mobile: '',
        have_consume: '',

      },
      operateLog: [],
      currentShop: '',
      vipGrade: [],
      salesman: [],
      loadingSalesman: false,
      salesmanPaging:{
        name: '',
        mobile: '',
        page: 1,
        pageSize: 10,
        total_count:0
      },
      salesperson_id: '',
      salesperson_name:'',
      salesperson_mobile:'',
      is_batch: false,
      currentDistributor: '',
      downloadView: false,
      downloadUrl: '',
      downloadName: '',
      wxShopsList: [],
      distributorShopList: [],
      performInit: false,
      created: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      sexoption: [
        {
          value: '0',
          label: '未知'
        }, {
          value: '1',
          label: '男'
        }, {
          value: '2',
          label: '女'
        }
      ],
      membersSetting: [],
      basicInfo: {
        user_id: '',
        username: '',
        sex: '',
        birthday: '',
        address: '',
        email: '',
        industry: '',
        income: '',
        edu_background: '',
        habbit: [],
      }
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    gradeUpdate (row) {
      this.params.action_type = 'set_grade'
      this.dialogTitle = '修改指定会员等级'
      this.dialogIsShow = true
      this.gradeForm.user_id = row.user_id
      this.gradeForm.grade_id = row.grade_id
      this.gradeForm.old_grade_id = row.grade_id
      this.gradeForm.remarks = ''
      this.is_batch = false
    },
    infoUpdate (value) {
      this.params.action_type = 'basic_info'
      this.dialogTitle = '修改会员基础信息'
      this.dialogIsShow = true
      this.basicInfo.user_id = value.user_id
      this.basicInfo.username = value.username
      this.basicInfo.sex = value.sex
      this.basicInfo.birthday = value.birthday
      this.basicInfo.address = value.address
      this.basicInfo.email = value.email
      this.basicInfo.industry = value.industry
      this.basicInfo.income = value.income
      this.basicInfo.edu_background = value.edu_background
      let habbit = []
      value.habbit.forEach(data => {
        if (data) {
          if (data.ischecked == 'true') {
            habbit.push(data.name)
          }
        }
      })
      this.basicInfo.habbit = habbit
      for (let setting in this.membersSetting) {
        for (let row in value) {
          if (setting == row) {
            this.membersSetting[setting].value = value[row]
            if (setting == 'habbit') {
              this.membersSetting[setting].value = habbit
            }
          }
        }
      }
    },
    infoUpdateSubmit() {
      updateMemberBasicInfo(this.basicInfo).then(res => {
        this.$message({type: 'success', message: '修改成功'})
        this.getMembers(this.params)
        this.dialogIsShow = false
      })
    },
    gradeUpdateSubmit () {
      if (!this.gradeForm.grade_id) {
          this.$message({
            type: 'error',
            message: '没有指定等级'
          })
          return
      }
      if (this.is_batch === false) {
        updateMemberGrade(this.gradeForm).then(res => {
          this.$message({type: 'success', message: '修改成功'})
          this.getMembers(this.params)
          this.dialogIsShow = false
        })
      } else {
        this.params.grade_form = JSON.stringify(this.gradeForm)
        this.batchAction('是否给全部会员指定会员等级?')
      }
    },
    editRemarks () {
      updateMemberInfo({user_id: this.remarksForm.user_id, remarks: this.remarksForm.remarks}).then(res => {
        this.$message({ type: 'success', message: '更新成功'})
        this.isEditRemarks = false
        this.getMembers(this.params)
      })
    },
    isEdit (row) {
      this.isEditRemarks = true
      this.remarksForm.user_id = row.user_id
      this.remarksForm.remarks = row.remarks
    },
    getRowKeys(row) {
      return `${row.user_id}${row.shop_id}`
    },
    submitSelected() {
      this.staffCoupons.dialog = false
      this.staffCoupons.checked = this.staffCoupons.temp
      let cardIds = [];
      for (var i = 0; i < this.staffCoupons.checked.length; i++) {
        cardIds.push(this.staffCoupons.checked[i].card_id)
      }
      this.staffCoupons.temp = []
      this.params.couponsids = cardIds
      this.params.action_type = 'give_coupon'
      this.batchAction('是否给全部会员赠送优惠券?')
    },
    handleCancelLabelsDialog() {
      this.tag.dialog = false
      this.editMobileDialog = false
      this.isEditRemarks = false
      this.dialogIsShow = false
    },
    handleSalesmanChange(row) {
      this.salesperson_id = row.salesperson_id
    },
    handleSelectionChange(rows) {
      this.user_id = []
      if (rows) {
        rows.forEach(row => {
          if (row) {
            this.user_id.push(row.user_id)
          }
        })
      }
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getParams()
      this.getMembers(this.params)
    },
    // 调整每页显示条数
    handleSizeChange (val) {
      this.params.pageSize = val
      this.getParams()
      this.getMembers(this.params)
    },
    getParams() {
      if (this.currentShop) {
        this.params.shop_id = this.currentShop
      } else {
        this.params.shop_id = ''
      }

      if (this.currentDistributor) {
        this.params.distributor_id = this.currentDistributor
      } else {
        this.params.distributor_id = ''
      }
    },
    getMembers(filter) {
      this.loading = true
      getMembers(filter).then(response => {
        this.memberData = response.data.data.list
        console.log(' this.memberData', this.memberData)
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      })
    },
    getGradeList() {
      getGradeList().then(response => {
        if (response != undefined && response.data.data && response.data.data.length > 0) {
          this.levelData = response.data.data
        }
      })
    },
    getDetail(userid) {
      this.$router.push({
        path: this.matchHidePage('detail'),
        query: {
          user_id: userid,
          mobile: this.params.mobile,
          orderRecords: this.params.have_consume,
          grade_id: this.params.grade_id,
          currentPage: this.params.page
        }
      })
    },
    getCoupons() {
      this.staffCoupons.loading = true
      getEffectiveCardList({
        page_no: this.staffCoupons.page.currentPage,
        page_size: this.staffCoupons.page.pageSize,
        card_type: this.card_type
      }).then( res => {
        var data = res.data.data.list
        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < this.staffCoupons.temp.length; j++) {
            if (data[i].card_id === this.staffCoupons.temp[j].card_id) {
              data[i].checked = true
            }
          }
        }
        this.staffCoupons.list = data
        this.staffCoupons.page.total = res.data.data.total_count
        this.staffCoupons.loading = false
      })//addCouponsItems
    },
    memberSearch(){
      this.currentPage = this.params.page = 1
      this.getParams()
      this.getMembers(this.params)
      this.loading = false
    },
    vipGradeChange() {
      this.params.grade_id = ''
      this.currentPage = this.params.page = 1
      this.getParams()
      this.getMembers(this.params)
      this.loading = false
    },
    ifLevelData(val) {
      this.params.vip_grade = ''
      this.currentPage = this.params.page = 1
      this.getParams()
      this.getMembers(this.params)
      this.loading = false
    },
    salesmanSearch () {
      this.loadingSalesman = true
      getSalesmanList({
        salesman_name: this.salesmanPaging.name,
        mobile: this.salesmanPaging.mobile,
        page: this.salesmanPaging.page,
        pageSize: this.salesmanPaging.pageSize
      }).then(res => {
        this.salesman = res.data.data.list
        this.salesperson_id = ''
        this.salesmanPaging.total_count = res.data.data.total_count
        this.loadingSalesman = false
      })
    },
    setSalesman (isSubmit) {
      if (this.user_id.length < 1) {
        this.$message({
          type: 'error',
          message: '必须选择的会员'
        })
        return
      }
      this.params.action_type = 'set_saleman'
      this.dialogIsShow = true
      if (isSubmit) {
        let params = {
          'users': JSON.stringify(this.user_id),
          'salesperson_id': this.salesperson_id
        }
        setSindusersalespersonrel(params).then(res => {
          if (res.data.data.success) {
            this.$message({
              type: 'success',
              message: '设置成功'
            })
            this.dialogIsShow = false
            this.getMembers(this.params)
          }
        })
      } else {
        this.params.action_type = 'set_saleman'
        this.dialogTitle = "设置导购员"
        getSalesmanList({page: this.salesmanPaging.page, pageSize: this.salesmanPaging.pageSize}).then(res => {
          this.salesman = res.data.data.list
          this.salesperson_id = ''
          this.salesmanPaging.total_count = res.data.data.total_count
          this.loadingSalesman = false
        })
      }
    },
    handleVipGradeDelay(isSubmit = false) {
      if (isSubmit) {
        if (this.vipGradeDelay.add_day <= 0) {
          this.$message({
            type: 'error',
            message: '请输入正确的延期天数'
          })
          return
        }
        if (this.vipGradeDelay.filter == 'users') {
          this.params.user_id = this.user_id
        }
        this.params.vip_grade_form = JSON.stringify(this.vipGradeDelay)
        this.batchAction('是否给全部会员进行付费会员延期?')
      } else {
        this.params.action_type = 'vip_delay'
        this.dialogTitle = "付费会员延期"
        if (this.vipGrade.length <= 0) {
          this.$message({
            type: 'error',
            message: '无付费会员'
          })
          return
        }
        this.dialogIsShow = true
        this.vipGradeDelay.vip_grade_id = this.vipGrade[0].vip_grade_id
      }
    },
    checkNum() {
      if (this.sms_content.length > 67) {
        this.$alert('当前短信内容字数超出67，将会分成多条发送', '', {
          confirmButtonText: '确定'
        })
      }
    },
    onSmsSends() {
      if (!this.sms_content) {
        this.$message({
          type: 'error',
          message: '短信内容不能为空'
        })
        return
      }
      this.params.sms_content = this.sms_content

      if (this.user_id.length > 0) {
        this.$confirm('确定发送该条短信给会员?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.params.action_type = "send_sms"
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消发送'
          })
        })
      } else {
          this.params.action_type = "send_sms"
          this.batchAction('是否给全部会员发短信?')
      }
    },
    addMemberTag() {
      this.params.action_type = 'rel_tag'

      this.tag.dialog = true
    },
    tagRemove(index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
    },
    tagAdd(item, index) {
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
    },
    submitMemberTag() {
      this.params.tag_ids = []
      this.params.user_id = []
      this.tag.currentTags.forEach(item => {
        this.params.tag_ids.push(item.tag_id)
      })
      if (this.params.tag_ids.length <=0 ){
        this.$message({
          type: 'error',
          message: '没有选中标签'
        })
        return
      }
      this.dialogIsShow = false
      this.batchAction('是否给全部会员打标签?')
    },
    batchAction(message) {
      if (this.user_id.length > 0) {
        this.params.user_id = this.user_id
        let params = JSON.parse(JSON.stringify(this.params))
        batchOperating(params).then(res => {
          if (res.data.data.status) {
            this.dialogIsShow = false
            this.$message({
              type: 'success',
              message: res.data.data.msg
            })
            this.memberSearch()
            this.getGradeList()
          }
        })
      } else {
        this.$confirm(message, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = JSON.parse(JSON.stringify(this.params))
          batchOperating(params).then(res => {
            if (res.data.data.status) {
              this.dialogIsShow = false
              this.$message({
                type: 'success',
                message: res.data.data.msg
              })
              this.memberSearch()
              this.getGradeList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
      this.params.tag_ids = []
      this.params.couponsids = []
      this.params.couponsids = []
      this.params.user_id = []
    },
    getAllTagLists() {
      let params = {
        page: 1,
        page_size: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
      })
    },
    editMobile(row) {
      this.editMobileDialog = true
      this.form.oldMobile = row.mobile
      this.form.user_id = row.user_id
      this.form.username = row.username
      this.form.newMobile = ''
      this.getOperateLog(row.user_id)
    },
    onEditMobileSubmit() {
      if (!this.form.newMobile) {
        this.$message({
          type: 'error',
          message: '请填写手机号码'
        })
        return
      }
      this.$confirm('确定要修改该会员手机号？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            updateMemberMobile(this.form).then(res => {
              this.$message({
                type: 'success',
                message: '修改手机号成功'
              })
              this.getParams()
              this.getMembers(this.params)
              this.editMobileDialog = false
            })
          }
          done()
        }
      })
    },
    getOperateLog(userId) {
      var params = {
        user_id: userId
      }
      getMemberOperateLog(params).then(res => {
        this.operateLog = res.data.data.list
      })
    },
    storeHandle(param) {
      param && param.shop_id
      this.currentDistributor = param.shop_id
      this.currentShop = ''
      this.currentPage = 1
      this.getParams()
      this.getMembers(this.params)
    },
    getShopsList(filter) {
      this.loading = true
      filter.pageSize = 500
      getWxShopsList(filter).then(response => {
        this.wxShopsList = response.data.data.list
        this.loading = false
      })
    },
    init () {
    },
    shopHandle(val) {
      this.performInit = true
      this.currentStore = ''
      this.currentShop = val
      this.currentPage = 1
      this.getParams()
      this.getMembers(this.params)
    },
    getVipList() {
      listVipGrade().then(res => {
        this.vipGrade = res.data.data
      })
    },
    exportData() {
      this.currentPage = 1
      this.getParams()
      memberExport(this.params).then(response => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (response.data.data.url) {
          this.downloadUrl = response.data.data.url
          this.downloadName = response.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      })
    },
    dateChange(val) {
      if (val.length > 0) {
        this.params.time_start_begin = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
        this.params.time_start_end = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
      } else {
        this.params.time_start_begin = ''
        this.params.time_start_end = ''
      }
      this.params.page = 1
      this.getParams()
      this.getMembers(this.params)
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    panelCollapse(name) {
      this.panel[name] = !this.panel[name]
    },
    acitonDisabled(index, row) {
      if (row.is_disabled === true) {
        var msg = '此操作将会禁用该会员，是否继续?'
        this.$confirm( msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            'user_id': row.user_id,
            'disabled': row.disabled
          }
          updateMemberInfo(params).then(res => {
            this.getParams()
            this.getMembers(this.params)
          })
        })
      } else {
        let params = {
          'user_id': row.user_id,
          'disabled': row.disabled
        }
        updateMemberInfo(params).then(res => {
          this.getParams()
          this.getMembers(this.params)
        })
      }
    },
    relTagDelEvent(tagId, userId){
      let params = {
        tag_id: tagId,
        user_id: userId,
      }
      usersRelTagsDel(params).then(res => {
        this.$message({type: 'success', message: '修改成功'})
      })
      this.memberSearch()
    },
    changeStaffCouponsPage(currentPage) {
      this.staffCoupons.page.currentPage = currentPage
      this.getCoupons()
    },
    selectStaffItems(item) {
      if (item.checked) {
        for (var i = 0; i < this.staffCoupons.temp.length; i++) {
          if (this.staffCoupons.temp[i].card_id === item.card_id) {
            this.staffCoupons.temp.splice(i,1)
          }
        }
        for (var i = 0; i < this.staffCoupons.list.length; i++) {
          if (this.staffCoupons.list[i].card_id === item.card_id) {
            this.staffCoupons.list[i].checked = false
          }
        }
      } else {
        for (var i = 0; i < this.staffCoupons.list.length; i++) {
          if (this.staffCoupons.list[i].card_id === item.card_id) {
            this.staffCoupons.list[i].checked = true
          }
        }
        this.staffCoupons.temp.push(item)
      }
    },
    batchActionDialog (actiontype) {
      this.params.action_type = actiontype
      this.dialogIsShow = true
      if (actiontype =='rel_tag') {
        this.dialogTitle = "为会员打标签"
        this.tag.currentTags = []
        this.tag.tags = [...this.tag.list]
        this.tag.tags.forEach((item, index) => {
          let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
          if (isInArr != -1) this.tag.tags.splice(index, 1)
        })
        this.tag.dialog = true
      } else if (actiontype =='give_coupon') {
        this.getCoupons()
        this.dialogTitle = "赠送优惠券"
      } else if (actiontype =='send_sms') {
        this.sms_content = ''
        this.dialogTitle = "群发短信"
      } else if (actiontype =='vip_delay') {
        this.dialogTitle = "付费会员延期"
      } else if (actiontype =='set_grade') {
        this.gradeForm.user_id = ''
        this.gradeForm.grade_id = ''
        this.gradeForm.old_grade_id = ''
        this.gradeForm.remarks = ''
        this.is_batch = true
        this.dialogTitle = "修改会员等级"
      } else if (actiontype =='set_saleman') {
        this.dialogTitle = "设置导购员"
      }
    }
  },
  mounted () {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    if (this.$route.query.salesman_mobile) {
      this.params.salesman_mobile = this.$route.query.salesman_mobile
    }
    this.getParams()
    if (this.$route.query.mobile) {
      this.params.mobile = this.$route.query.mobile
    }
    if (this.$route.query.orderRecords) {
      this.params.have_consume = this.$route.query.orderRecords + ''
    }
    if (this.$route.query.grade_id) {
      this.params.grade_id = this.$route.query.grade_id
    }
    if (this.$route.query.currentPage) {
      this.params.page = Number(this.$route.query.currentPage)
    }
    this.getMembers(this.params)
    let param = {
      page: 1,
      pageSize: 100,
      is_disabled: false
    }
    this.getGradeList()
    this.getAllTagLists()
    this.getVipList()
    this.getShopsList(param)
    getMemberRegisterSetting().then(response => {
      delete response.data.data.content_agreement;
      this.membersSetting =  response.data.data.setting
    })
  }
}
</script>
