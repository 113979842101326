<template>
  <div class="container lotteryactivity">
    <el-card shadow="never" class="step-card">
      <div v-for="(item, index) in lottery_step_list" :key="index" class="step-container">
        <div :class="lottery_step_active >= index + 1 ? 'step-no active border-active' : 'step-no'">{{ lottery_step_active <= index + 1 ? index + 1 : '√' }}</div>
        <div class="step-content">
          <div :class="lottery_step_active >= index + 1 ? 'title active' : 'title'">{{ item.title }}</div>
          <div>{{ item.desc }}</div>
        </div>
        <div class="arrow-right"></div>
      </div>
    </el-card>
    <el-form ref="form" :rules="rules" :model="form" label-width="180px">
      <template v-if="lottery_step_active == 1">
        <div>
          <el-row v-if="!form.marketing_id" class="content-center content-bottom-padded">
            <el-radio-group v-model="form.marketing_type">
              <el-radio-button label="normal">常规礼品抽奖</el-radio-button>
              <el-radio-button label="limit">限定礼品抽奖</el-radio-button>
            </el-radio-group>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动标题" prop="marketing_title" >
                <el-input placeholder="请输入活动标题" v-model="form.marketing_title" maxlength="30"  @change="titleChange">
                  <template slot="append">{{ inputValue.title_length }}/{{inputValue.title_max}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="活动描述" prop="marketing_desc">
                <el-input v-model="form.marketing_desc" placeholder="请输入活动描述" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="活动有效期" prop="active_valid_times">
                <el-date-picker
                  v-model="form.active_valid_times"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  type="datetimerange"
                  range-separator="至"
                  value-format="timestamp"
                  :picker-options="pickerOptions"
                  style="width:100%"
                  :clearable="false"
                  :disabled="progressDisabled"
                  :default-time="['00:00:00', '23:59:59']"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.marketing_type === 'normal'">
              <el-form-item label="领取有效期" prop="validity_time">
                <el-input
                  v-model="form.validity_time"
                  placeholder="请输入内容"
                >
                  <template slot="prepend">中奖后</template>
                  <template slot="append">分钟内</template>
                </el-input>
                <span></span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="场次" required style="max-width: 700px;">
                <el-button :disabled="progressDisabled" type="primary" plain size="small" @click="onSessionAddChange">添加场次</el-button>
                <el-form-item
                  v-for="(items, index) in form.rel_round_list"
                  :label="'场次' + (index + 1)"
                  :key="index"
                  :prop="'rel_round_list.' + index + '.times'"
                  label-width="80px"
                  class="session-form-item"
                  :rules="{ required: true, message: '请选择时间', trigger: 'change' }"
                >
                  <el-date-picker
                    v-model="items.times"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    type="datetimerange"
                    range-separator="至"
                    value-format="timestamp"
                    :clearable="false"
                    :disabled="progressDisabled"
                    :default-time="['00:00:00', '23:59:59']"
                  />
                  <el-button v-if="!progressDisabled" @click="onSessionDelChange(index)" type="text">删除</el-button>
                </el-form-item>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="抽奖名额限额" v-if="form.marketing_type == 'limit'" prop="user_limit_num">
            <el-input v-model="form.user_limit_num" placeholder="请输入内容">
              <template slot="prepend">每人可获总名额</template>
            </el-input>
          </el-form-item>
          <el-form-item label="抽奖名额获取" v-if="form.marketing_type == 'limit'" required prop="use_item" class="useitem">
            <el-radio-group
              v-model="form.use_item"
              @change="useItemTypeChange"
              :disabled="progressDisabled"
            >
              <el-radio-button label="all">购买全部商品赠送</el-radio-button>
              <el-radio-button label="part">购买指定商品赠送</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="每购买1件赠送名额数" v-if="form.marketing_type == 'limit' && form.use_item == 'all'" prop="item_limit_num">
            <el-input v-model="form.item_limit_num" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="" v-if="form.marketing_type == 'limit' && form.use_item == 'part'" class="lottery_quota_rules">
            <el-card class="box-card">
              <div slot="header" style="display: flex;justify-content: space-between; align-items: center;">
                <span>设置指定商品</span>
                <el-button type="primary" plain size="small" @click="addRelItemChange(1)">添加商品规则</el-button>
              </div>
              <div>
                <el-table :data="relItemList">
                  <el-table-column label="SKU ID" prop="item_id"/>
                  <el-table-column label="商品名称" prop="item_name" />
                  <el-table-column label="参与规格">
                    <template slot-scope="scope">
                      <div v-if="!scope.row.nospec">
                        <span v-if="scope.row.sku_list.length == 0">全规格</span>
                        <span v-for="item in scope.row.sku_list" :key="item.item_id">{{ item.item_spec_desc }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="每购买1件赠送名额数">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.num" size="mini" @change="onRelItemNumChange(scope.$index, scope.row)" />
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="text" v-if="!scope.row.nospec" @click="handleSkuDialogShow(scope.$index)">选择规格</el-button>
                      <el-button type="text" :disabled="scope.row.cus_is_edit" @click="deleteRelItemNumRow(scope.$index, scope.row)">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-form-item>
          <el-form-item label="抽奖限额规则" prop="limit_rule_type" class="lottery_quota_rules">
            <el-card class="box-card box-card-one">
              <div slot="header">
                <span v-if="form.marketing_type == 'limit'">每个用户抽奖次数限额</span>
                <span v-else>按门店每个用户抽奖次数限额</span>
              </div>
              <el-radio-group v-model="form.limit_rule_type" :disabled="progressDisabled">
                <el-radio :label="items.use_rule" v-for="(items, index) in limitRuleList" :key="items.use_rule">
                  <el-input v-model="items[items.use_rule]" :disabled="items.cus_is_edit && items.use_rule != form.limit_rule_type" size="small" :placeholder="items.placeholder" @change="lotteryChange(index, items)">
                    <template slot="prepend">{{ items.label }}</template>
                  </el-input>
                </el-radio>
              </el-radio-group>
            </el-card>
          </el-form-item>
          <el-form-item label="奖品及中奖规则" class="lottery_quota_rules" required>
            <el-button type="primary" plain size="small" @click="addRelItemChange(2)">添加礼品</el-button>
            <el-table :data="relPriceList" border class="lottery_quota_rules_table">
              <el-table-column label="赠品ID" prop="id" />
              <el-table-column label="赠品名称" prop="gift_name" />
              <el-table-column label="中奖概率(%)">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.probability" @change="probabilityChange(scope.$index, scope.row)" size="mini" />
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" :disabled="scope.row.cus_is_edit" @click="deleteProbabilityRow(scope.$index, scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </div>
        <div v-if="form.marketing_type == 'limit'">
          <el-form-item label="适用门店" class="storecss" required>
            <el-radio-group
              v-model="form.use_shop"
              @change="shopTypeChange"
            >
              <el-radio-button label="all">全部门店</el-radio-button>
              <el-radio-button label="distributor">指定门店</el-radio-button>
              <el-radio-button label="dealer">指定经销商</el-radio-button>
            </el-radio-group>
            <el-button type="primary" class="btn" @click="addDistributorAction" v-if="form.use_shop !== 'all'">{{form.use_shop == 'distributor' ? '设置门店' : '设置经销商'}}</el-button>
          </el-form-item>
          <div style="width: 80%; margin-left: 180px;">
            <el-table v-if="form.use_shop !== 'all' && distributor_info && distributor_info.length > 0" :data="distributor_info" style="line-height: normal">
              <template v-if="form.use_shop === 'distributor'">
                <el-table-column
                  label="ID"
                  prop="distributor_id"
                  width="90"
                ></el-table-column>
                <el-table-column
                  label="AppleID"
                  prop="shop_code"
                  width="120"
                ></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column
                  prop="address"
                  label="地址"
                  show-overflow-tooltip
                ></el-table-column>
              </template>
              <template v-if="form.use_shop === 'dealer'">
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column
                  label="经销商HQID"
                  prop="dealer_hqid"
                ></el-table-column>
                <el-table-column
                  prop="distributor_hqid"
                  label="分销商HQID"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="分销商VAD"
                  prop="distributor_vad"
                ></el-table-column>
              </template>
              <el-table-column label="操作" width="50">
                <template slot-scope="scope">
                  <i
                    class="iconfont icon-trash-alt"
                    @click="deleteDistritutorRow(scope.$index, form.items)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="second-step">
          <el-row>
            <el-col :span="12">
              <el-form-item label="抽奖页" prop="logo_url">
                <div @click="handleImgPicker(1)" class="upload-box">
                  <img v-if="form.logo_url" :src="form.logo_url" class="avatar" />
                  <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                </div>
                <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
              </el-form-item>
              <el-form-item label="热区链接" required v-if="form.logo_url">
                <hotzone
                  class="hotzone"
                  :image="form.logo_url"
                  :zonesInit="form.img_hotzone_info"
                  @add="handleAddChange(1)"
                  @change="(zone) =>handleEditChange(zone, 1)"
                  @remove="(index) => handleRemoveChange(index, 1)"
                />
              </el-form-item>
              <div
                class="setting-item slider"
                v-for="(item, index) in form.img_hotzone_info"
                :key="index"
              >
                <el-form-item label="" prop="customize_id">
                  <el-radio-group v-model="item.adavter_type" @change="onAdavterTypeChange($event, index, 1)">
                    <el-radio label="pageindex">首页</el-radio>
                    <el-radio label="custom">自定义页</el-radio>
                    <el-radio label="lottery">抽奖交互</el-radio>
                  </el-radio-group>
                  <div class="goods-select"
                    style=" width: 400px;text-align:left;margin-left: 10px;"
                    @click="addCustomizeAction(index, item, 1)"
                    v-if="item.adavter_type == 'custom'"
                  >
                    <span v-if="item.customize.customize_id > 0">{{item.customize.customize_id}}  {{item.customize.customize_title}}</span>
                    <span v-else>+ 选择自定义页面</span>
                  </div>
                  <div class="goods-select"
                    style=" width: 400px;text-align:left;
                    margin-left: 10px;"
                    v-if="item.adavter_type == 'lottery'"
                  >
                    交互：抽奖
                  </div>
                  <div class="goods-select"
                    style=" width: 400px;text-align:left;
                    margin-left: 10px;"
                    v-if="item.adavter_type == 'pageindex'"
                  >
                    交互：首页
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <el-form-item label="无库存/活动结束" prop="logo_two_url">
                <div @click="handleImgPicker(2)" class="upload-box">
                  <img v-if="form.logo_two_url" :src="form.logo_two_url" class="avatar" />
                  <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                </div>
                <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
              </el-form-item>
              <el-form-item label="热区链接" required v-if="form.logo_two_url">
                <hotzone
                  class="hotzone"
                  :image="form.logo_two_url"
                  :zonesInit="form.img_hotzone_two_info"
                  @add="handleAddChange(2)"
                  @change="(zone) =>handleEditChange(zone, 2)"
                  @remove="(index) => handleRemoveChange(index, 2)"
                />
              </el-form-item>
              <div
                class="setting-item slider"
                v-for="(item, index) in form.img_hotzone_two_info"
                :key="index"
              >
                <el-form-item label="" prop="customize_id">
                  <el-radio-group v-model="item.adavter_type" @change="onAdavterTypeChange($event, index, 2)">
                    <el-radio label="pageindex">首页</el-radio>
                    <el-radio label="custom">自定义页</el-radio>
                    <el-radio label="lottery">抽奖交互</el-radio>
                  </el-radio-group>
                  <div class="goods-select"
                    style=" width: 400px;text-align:left;margin-left: 10px;"
                    @click="addCustomizeAction(index,item, 2)"
                    v-if="item.adavter_type == 'custom'"
                  >
                    <span v-if="item.customize.customize_id > 0">{{item.customize.customize_id}}  {{item.customize.customize_title}}</span>
                    <span v-else>+ 选择自定义页面</span>
                  </div>
                  <div class="goods-select"
                    style=" width: 400px;text-align:left;
                    margin-left: 10px;"
                    v-if="item.adavter_type == 'lottery'"
                  >
                    交互：抽奖
                  </div>
                  <div class="goods-select"
                    style=" width: 400px;text-align:left;
                    margin-left: 10px;"
                    v-if="item.adavter_type == 'pageindex'"
                  >
                    交互：首页
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
      <div class="global_footer section-footer with-border content-center">
        <el-button type="primary" @click="onNextStepClick('form')" plain v-if="lottery_step_active == 1" style="width: 180px">完成，进入下一步</el-button>
        <el-button type="primary" @click="() => this.lottery_step_active = 1" plain v-if="lottery_step_active == 2" style="width: 180px">返回上一步</el-button>
        <el-button type="primary" @click="onSubmitChange('form')" :loading="submitDisabled" v-if="lottery_step_active == 2 && !submitBtnHidden" style="margin-left: 20px;width: 180px">保存</el-button>
      </div>
    </el-form>
    <DistributorSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :distributor-valid="'true,false'"
      :relDataIds="distributor_info"
      :get-status="setDistributorStatus"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDistributorDialogAction"
      returnType="selectRow"
    />

    <DealerSelect
      :store-visible="dealerVisible"
      :is-valid="false"
      :maxSelect="1"
      :relDataIds="distributor_info"
      :get-status="dealerStatus"
      @chooseStore="handleDealerSelected"
      @closeStoreDialog="handleCloseDealer"
    />

    <GoodsSelect
      :items-visible="goodsDialogVisible"
      :get-status="setGoodsItemStatus"
      :rel-items-ids="relGoodsItemsIds"
      @chooseStore="chooseGoodsAction"
      @closeStoreDialog="closeGoodsDialogAction"
    />

    <GiftDialog
      :items-visible="giftDialogVisible"
      :type="form.marketing_type"
      :get-status="giftDialogStatus"
      :rel-items-ids="giftDialogRelPriceIds"
      @chooseAction="chooseGiftItemsAction"
      @closeStoreDialog="closeGiftItemDialogAction"
    />

    <imgPicker
      :dialog-visible="imgDialog"
      :sc-status="isGetImage"
      @chooseImg="pickImg"
      @closeImgDialog="closeImgDialog"
    />

    <CustomizeSelect
      :store-visible="customizeVisible"
      :is-valid="true"
      :is-clean-select="true"
      :rel-data-ids="customizeList"
      :get-status="customizeStatus"
      @chooseStore="onCustomizeChooseChange"
      @closeStoreDialog="onCloseDialogChange"
    />

    <el-dialog title="选择sku" :visible.sync="skuVisible" width="50%">
      <el-table :data="skusList" @selection-change="handleSkuChange" ref="skuTable">
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column label="规格名称">
          <template slot-scope="scope">
            {{scope.row.item_spec_desc}}
          </template>
        </el-table-column>
        <el-table-column label="货号">
          <template slot-scope="scope">
            <div>商品货号：{{scope.row.item_bn}}</div>
            <div v-if="scope.row.origin_bn">原始货号：{{scope.row.origin_bn}}</div>
          </template>
        </el-table-column>
        <el-table-column label="价格">
          <template slot-scope="scope">
            ¥{{scope.row.price/100}}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="skuVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSkuSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import hotzone from 'vue-hotzone'
import imgPicker from '@/components/imageselect'
import CustomizeSelect from '@/components/function/customizeSelect'
import Treeselect from '@riophae/vue-treeselect'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import SkuSelector from '@/components/function/skuSelector'
import { getItemsList } from '@/api/goods'
import { editLotteryActivity, getLotteryActivityDetail, addLotteryActivity } from '@/api/lottery'
import GoodsSelect from '@/components/goodsSelect'
import GiftDialog from './comp/giftDialog'

export default {
  inject: ['refresh'],
  components: {
    SkuSelector,
    DistributorSelect,
    Treeselect,
    DealerSelect,
    GoodsSelect,
    GiftDialog,
    imgPicker,
    hotzone,
    CustomizeSelect
  },

  data () {
    var validityTimeValid = (rule, value, callback) => {
      let reg = /^\d*$/
      if (!reg.test(value)) {
        callback(new Error('领取有效期必须为整数'))
      } else if (value == '') {
        callback(new Error('领取有效期不能为空'))
      } else {
        callback()
      }
    }
    var userLimitumValid = (rule, value, callback) => {
      let reg = /^\d*$/
      if (!reg.test(value)) {
        callback(new Error('抽奖名额限额必须为整数'))
      } else {
        callback()
      }
    }
    var itemLimitNumValid = (rule, value, callback) => {
      let reg = /^\d*$/
      if (!reg.test(value)) {
        callback(new Error('赠送名额必须为整数'))
      } else {
        callback()
      }
    }
    var marketingTitleValid = (rule, value, callback) => {
      if (value == '' || value.trim() == '') {
        callback(new Error('活动标题不能为空'))
      } else if (value.length > 30) {
        callback(new Error('活动标题长度不能超过30字'))
      } else {
        callback()
      }
    }
    var marketingDescValid = (rule, value, callback) => {
      if (value.length > 30) {
        callback(new Error('活动描述长度不能超过30字'))
      } else {
        callback()
      }
    }
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      distributorVisible: false,
      dealerVisible: false,
      dealerStatus: false,
      setDistributorStatus: false,
      distributor_info: [],
      relDistributorIds: [],
      submitDisabled: false,
      form: {
        marketing_id: '',
        marketing_type: 'normal',
        marketing_title: '',
        marketing_desc: '',
        active_valid_times: '',
        validity_time: 0,
        limit_rule: {},
        limit_rule_type: 'day',
        start_time: 0,
        end_time: 0,
        use_shop: 'all',
        distributor_id: [],
        logo_url: '',
        img_hotzone_info: [],
        logo_two_url: '',
        img_hotzone_two_info: [],
        rel_round_list: [],
        user_limit_num: '',
        use_item: 'all',
        item_limit_num: 0
      },
      rules: {
        marketing_title: [
          { required: true, validator: marketingTitleValid, trigger: 'blur' }
        ],
        marketing_desc: [
          { required: false, validator: marketingDescValid, trigger: 'blur' }
        ],
        validity_time: [
          { required: true, validator: validityTimeValid, trigger: 'blur' }
        ],
        user_limit_num: [
          { required: false, validator: userLimitumValid, trigger: 'blur' }
        ],
        use_item: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        item_limit_num: [
          { required: true, validator: itemLimitNumValid, trigger: 'blur' }
        ],
        limit_rule_type: [
          { required: true, message: '请选择抽奖限额规则', trigger: 'change' }
        ],
        active_valid_times: [
          { required: true, message: '请选择活动有效期', trigger: 'blur' }
        ],
        // rel_round_list: [
        //   { required: true, message: '请选择场次', trigger: 'blur' }
        // ],
        logo_url: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        logo_two_url: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      },
      bindStorePagination: { // 已绑定店铺&经销商分页数据
        page: 1,
        page_size: -1
      },
      lottery_step_list: [
        { title: '第1步', desc: '完善活动信息' },
        { title: '第2步', desc: '配置活动模板' },
      ],
      lottery_step_active: 1,
      checkList: [],
      radioValue: '',
      relGoodsItemsIds: [],
      relItemList: [], // 购买商品list
      goodsDialogVisible: false, // 购买商品弹框是否弹出
      setGoodsItemStatus: false,
      relPriceList: [], // 奖品list
      giftDialogVisible: false, // 奖品弹框是否弹出
      giftDialogStatus: false,
      giftDialogRelPriceIds: [],
      skusList: [], // sku弹框列表
      skuVisible: false, // sku弹框是否弹出
      checkedSkus: [],
      tableCurrent: '',
      limitRuleList: [
        { use_rule: 'day', label: '每日限', placeholder: '请输入内容', day: 0 },
        { use_rule: 'round', label: '每场次限', placeholder: '请输入内容', round: 0 },
      ],
      skuParams: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        approve_status: 'onsale',
        is_sku: true,
        item_id: ''
      },
      // selectOptions: [
      //   { value: '每日限', key: 1 },
      //   { value: '每月限', key: 2 },
      //   { value: '总限额', key: 3 },
      // ],

      imgDialog: false,
      isGetImage: false,

      customizeStatus: false,
      customizeVisible: false,
      customizeIndex: 0,
      customizeType: '',
      customize: '',
      isValid1: true,
      customizeList: [],
      img_hotzone_link: [], // 保存接口获取的img_hotzone_link_obj 信息
      skuParams: {
        page: 1,
        pageSize: -1,
        item_type: 'normal',
        approve_status: 'onsale',
        is_sku: true,
        item_id: ''
      },
      inputValue: {
        title_length: 0,
        title_max: 30,
      },
      progressDisabled: false,
      submitBtnHidden: false,
    }
  },
  methods: {
    handleCloseDealer () {
      this.dealerVisible = false
    },
    handleDealerSelected (dealerlist) {
      this.dealerVisible = false
      this.dealerStatus = false
      this.distributor_info = dealerlist
    },
    handleTypeChange (val) {
      console.log(val)
    },
    useItemTypeChange (val) {
      this.relItemList = []
    },
    shopTypeChange (val) {
      this.form.distributor_id = []
      this.distributor_info = []
      this.setDistributorStatus = false
      this.dealerStatus = false
    },
    addRelItemChange (val) {
      if (val == 1) {
        this.goodsDialogVisible = true
        this.setGoodsItemStatus = true
      } else if (val == 2) {
        this.giftDialogVisible = true
        this.giftDialogStatus = true
        this.giftDialogRelPriceIds = JSON.parse(JSON.stringify(this.relPriceList))
      }
    },
    addDistributorAction () {
      if (this.form.use_shop === 'dealer') {
        this.dealerVisible = true
        this.dealerStatus = true
      } else {
        this.distributorVisible = true
        this.setDistributorStatus = true
      }
      this.relDistributorIds = this.form.distributor_id
    },

    chooseDistributorAction (data) {
      this.distributorVisible = false
      this.setDistributorStatus = false
      if (!data || data === null || data.length <= 0) {
        this.distributor_info = []
        return
      }
      this.distributor_info = data
      this.form.distributor_id = []
      for (let i = 0; i < data.length; i++) {
        const id = data[i].distributor_id
        this.form.distributor_id.push(Number(id))
      }
    },
    closeDistributorDialogAction () {
      this.distributorVisible = false
    },
    deleteDistritutorRow (index) {
      this.distributor_info.splice(index, 1)
      let shopDatas = this.distributor_info
      this.form.distributor_id = []
      for (let i = 0; i < shopDatas.length; i++) {
        if (this.form.distributor_id.indexOf(Number(shopDatas[i].distributor_id)) < 0) {
          this.form.distributor_id.push(Number(shopDatas[i].distributor_id))
        }
      }
      this.relDistributorIds = this.form.distributor_id
    },
    async onNextStepClick (formName) {
      console.log(this[formName], this, 'onNextStepClick')
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.form.rel_round_list.length == 0) {
            return this.$message.error('请添加场次')
          } else {
            let isvalid = false
            const last =  this.form.rel_round_list.length
            const start = this.form.rel_round_list[0].times[0]
            const end = this.form.rel_round_list[last - 1].times[1]
            if (start != this.form.active_valid_times[0]) {
              this.$message.error(`场次1的开始时间必须等于有效期的开始时间`)
              return
            }
            if (end != this.form.active_valid_times[1]) {
              this.$message.error(`场次${last}的结束时间必须等于有效期的结束时间`)
              return
            }
            for (var i = 0; i < this.form.rel_round_list.length; i++) {
              const p_item = this.form.rel_round_list[i]
              const n_item = this.form.rel_round_list[i + 1]
              const curEnd = p_item.times[1]
              const nextStart = n_item ? n_item.times[0] : 0
              if (nextStart > 0) {
                if (nextStart <= curEnd) {
                  isvalid = true
                  this.$message.error(`场次${(i+2)}与场次${(i+1)}时间重叠`)
                  break
                }
                if (nextStart != (curEnd + 1000)) {
                  isvalid = true
                  this.$message.error(`场次${(i+2)}与场次${(i+1)}时间不连贯`)
                  break
                }
              }
            }
            if (isvalid) return
          }
          const isLimit = this.form.marketing_type == 'limit'
          if (this.relItemList.length == 0 && isLimit && this.form.use_item == 'part') { // 限定礼品才会设置抽奖名额规则
            return this.$message.error('请选择商品')
          }
          // if (this.form.item_limit_num === '' && isLimit && this.form.use_item == 'all') {
          //   return this.$message.error('请填写每购买1件赠送名额数')
          // }
          if (this.relPriceList.length == 0) { // 限定礼品才会设置抽奖名额规则
            return this.$message.error('请选择礼品')
          } else {
            let totalNum = 0
            this.relPriceList.forEach(item => {
              const probability = Number(item.probability)
              totalNum += probability
            })
            if (totalNum > 100) {
              return this.$message.error('中奖总概率不能超过100')
            }
          }
          // if (this.form.use_shop === 'distributor' || this.form.use_shop === 'dealer') {
          //   if (!this.distributor_info.length) {
          //     return this.$message.error('指定的店铺或经销商不能为空')
          //   }
          // }
          this.lottery_step_active = 2
        }
      })
    },
    onSubmitChange (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.form))
          // -----抽奖限额规则处理开始-----
          const limit_rule = this.limitRuleList.find(item => item.use_rule == params.limit_rule_type)
          params.limit_rule = { use_rule: limit_rule.use_rule, day: limit_rule.day || 0, round: limit_rule.round || 0 }
          delete params.limit_rule_type
          // -----抽奖限额规则处理结束-----
          // -----场次处理开始-----
          const mapList = params.rel_round_list.map(items => {
            return {
              start_time: items.times[0] / 1000,
              end_time: items.times[1] / 1000
            }
          })
          params.rel_round = mapList
          delete params.rel_round_list
          // -----场次处理结束-----
          // -----赠品处理开始-----
          params.rel_gift = this.relPriceList.map(items => {
            return {
              gid: items.id,
              probability: items.probability,
            }
          })
          // -----赠品处理结束-----
          // -----抽奖名额规则处理开始-----
          if (this.form.use_item == 'part') {
            params.rel_item = this.relItemList.map(items => {
              return {
                item_id: items.item_id,
                item_name: items.item_name,
                spec_items: items.spec_items.map(el => el.item_id),
                sku_list: items.sku_list.map(el => el.item_id),
                num: items.num,
                nospec: items.nospec
              }
            })
          }
          delete params.itemTreeLists
          // -----抽奖名额规则处理结束-----
          // -----活动有效期处理开始-----
          params.start_time = params.active_valid_times[0] / 1000
          params.end_time = params.active_valid_times[1] / 1000
          delete params.active_valid_times
          // -----活动有效期处理结束-----
          // -----热区json处理开始-----
          const list = [
            { logo: this.form.logo_url, data: this.form.img_hotzone_info },
            { logo: this.form.logo_two_url, data: this.form.img_hotzone_two_info },
          ]
          console.log(list, 'marketing_data')
          params.marketing_data = JSON.stringify(list)
          delete params.logo_url
          delete params.img_hotzone_info
          delete params.logo_two_url
          delete params.img_hotzone_two_info
          // -----热区json处理结束-----
          params.use_shop = this.form.use_shop // 关联店铺方式
          /** 抵扣券或者兑换券时新增参数： */
          if (this.form.use_shop == 'distributor' || this.form.use_shop == 'dealer') {
            console.log(this.distributor_info)
            params.rel_shop = this.distributor_info.length > 0 ? this.distributor_info.map(item => item[`${this.form.use_shop}_id`]) : []
          }
          delete params.rel_shop_dealers
          delete params.rel_shop_distributors
          console.log(params, 'params', this.form)
          debugger
          this.submitDisabled = true
          if (this.form.marketing_id) {
            await editLotteryActivity(params).then(res => {
            this.$message.success('保存成功！')
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          }).catch(err => {
            this.submitDisabled = false
          })
          } else {
            await addLotteryActivity(params).then(res => {
              this.$message.success('新增成功！')
              setTimeout(() => {
                this.$router.go(-1)
              }, 500)
            }).catch(err => {
              this.submitDisabled = false
            })
          }
        }
      })
    },
    deleteRelItemNumRow (index, rows) {
      const new_list = this.relItemList.filter(el => el.item_id != rows.item_id)
      this.relItemList = new_list
      this.setGoodsItemStatus = false
      console.log('deleteItemRow',this.relGoodsItemsIds)
      this.relGoodsItemsIds.splice(index, 1)
    },
    deleteProbabilityRow (index, rows) {
      const new_list = this.relPriceList.filter(el => el.id != rows.id)
      this.relPriceList = new_list
      this.giftDialogStatus = false
      console.log('deleteItemRow',this.giftDialogRelPriceIds)
      this.giftDialogRelPriceIds.splice(index, 1)
    },
    chooseGoodsAction (data) {
      this.goodsDialogVisible = false
      this.relGoodsItemsIds = data
      if (data === null || data.length <= 0) return
      let arr = []
      data.forEach((item, index) => {
        let newData = ''
        let isInArr = this.relItemList.findIndex((n) => n.item_id == item.item_id)
        if (isInArr == -1) {
          newData = {
            item_id: item.item_id,
            item_name: item.item_name,
            spec_items: [],
            sku_list: [],
            num: 0,
            nospec: item.nospec,
            default_item_id: item.default_item_id
          }
        } else {
          newData = this.relItemList[index]
        }
        arr.push(newData)
      })
      this.relItemList = arr
      this.generateSku()
    },
    chooseGiftItemsAction (data) {
      console.log(data)
      this.giftDialogVisible = false
      this.giftDialogStatus = false
      if (data === null || data.length <= 0) {
        this.relPriceList = []
        return
      }
      let arr = []
      data.forEach((item, index) => {
        let newData = ''
        let isInArr = this.relPriceList.findIndex((n) => n.id == item.id)
        if (isInArr == -1) {
          newData = {
            id: item.id,
            gift_name: item.name,
            probability: 0,
          }
        } else {
          newData = {
            ...this.relPriceList[isInArr],
            probability: this.relPriceList[isInArr].probability || 0,
          }
        }
        arr.push(newData)
      })
      this.relPriceList = arr
    },
    closeGoodsDialogAction () {
      this.goodsDialogVisible = false
    },
    closeGiftItemDialogAction () {
      this.giftDialogRelPriceIds = []
      this.giftDialogStatus = false
      this.giftDialogVisible = false
    },
    handleSkuChange(value) {
      console.log(value, 'checkedSkus')
      const items = value.map(item => {
        return {
          item_id: item.item_id,
          item_spec_desc: item.item_spec_desc,
        }
      })
      this.checkedSkus = items
    },
    handleSkuSubmit() {
      this.skuVisible = false
      this.relItemList[this.tableCurrent].sku_list = this.checkedSkus
      this.generateSku()
    },
    handleSkuDialogShow(index) {
      const that = this
      this.tableCurrent = index
      this.skuParams.item_id = this.relItemList[index].item_id
      this.skuVisible = true
      let checked = this.relItemList[index].sku_list || []
      console.log(this.relItemList, checked, index)
      getItemsList(this.skuParams).then(res => {
        this.skusList = res.data.data.list
        this.$nextTick(() => {
          that.skusList.forEach(item => {
            let index = checked.findIndex(n => item.item_id === n.item_id)
            if (index !== -1) {
              that.$refs.skuTable.toggleRowSelection(item)
            }
          })
        })
      })
    },
    handleLotteryRulesChange (select) {
      const giftValue = select.find(item => item == '赠送')
      if (giftValue) {
        this.radioValue = 3
      } else {
        this.radioValue = ''
      }
      console.log(select, giftValue)
    },
    handleImgPicker (type) {
      this.imgDialog = true
      this.isGetImage = true
      this.customizeType = type
    },
    pickImg (data) {
      console.log(data, this.customizeType)
      if (this.customizeType == 1) {
        this.form.logo_url = data.url
      } else if (this.customizeType == 2) {
        this.form.logo_two_url = data.url
      }
      this.imgDialog = false
    },
    closeImgDialog () {
      this.imgDialog = false
      this.isGetImage = false
    },
    handleAddChange (type) {
      this.customizeType = type
      let item = {
        customize: {
          customize_id: '',
          customize_title: '',
          type: ''
        },
        adavter_type: ''
      }
      console.log(this.customizeType, 'handleAddChange')
      if (type == 1) {
        this.form.img_hotzone_info.push(item)
      } else {
        this.form.img_hotzone_two_info.push(item)
      }
    },
    // 修改热区
    handleEditChange (zone, type) {
      this.customizeType = type
      setTimeout(() => {
        zone.forEach((item, index) => {
          if (item.leftPer) {
            let obj = {
              heightPer: item.heightPer,
              leftPer: item.leftPer,
              topPer: item.topPer,
              widthPer: item.widthPer
            }
            if (type == 1) {
              Object.assign(this.form.img_hotzone_info[index], obj)
            } else {
              Object.assign(this.form.img_hotzone_two_info[index], obj)
            }
          }
        })
      }, 500)
    },
    // 删除热区
    handleRemoveChange (index, type) {
      this.customizeType = type
      if (type == 1) {
        this.form.img_hotzone_info.splice(index, 1)
      } else {
        this.form.img_hotzone_two_info.splice(index, 1)
      }
    },
    onCustomizeChooseChange (data) {
      if (data === null || data.length <= 0) return
      console.log("onCustomizeChooseChange------------------data:", data, this.customizeType)
      if (this.customizeType == 1) {
        this.form.img_hotzone_info[this.customizeIndex].customize = {
          customize_id: data[0].id,
          customize_title: data[0].page_name,
          type: data[0].page_type
        }
      } else {
        this.form.img_hotzone_two_info[this.customizeIndex].customize = {
          customize_id: data[0].id,
          customize_title: data[0].page_name,
          type: data[0].page_type
        }
      }
      this.onCloseDialogChange()
    },
    onCloseDialogChange () {
      this.customizeStatus = false
      this.customizeVisible = false
      this.customizeIndex = 0
    },
    addCustomizeAction(index, item, type) {
      this.customizeStatus = true
      this.customizeVisible = true
      this.customizeIndex = index
      this.customizeType = type
      this.customizeList = [item.customize]
    },
    onAdavterTypeChange (value, index, type) {
      this.customizeType = type
      if (type == 1) {
        this.form.img_hotzone_info[index].customize = {
          customize_id: '',
          customize_title: '',
          type: ''
        }
      } else {
        this.form.img_hotzone_two_info[index].customize = {
          customize_id: '',
          customize_title: '',
          type: ''
        }
      }
    },
    lotteryChange (index, item) {
      let reg = /^\d*$/
      if (!reg.test(item[item.use_rule])) {
        this.limitRuleList[index][item.use_rule] = 0
        this.$message.error('请输入整数')
        return
      }
      this.form.limit_rule_type = item.use_rule
    },
    probabilityChange (index, item) {
      console.log(index, item)
      let reg = /^\d+(\.\d{1,2})?$/
      if (!reg.test(item.probability)) {
        this.relPriceList[index].probability = 0
        this.$message.error('请输入正确格式')
      } else if (item.probability > 100 || item.probability < 0) {
        this.relPriceList[index].probability = 0
        this.$message.error('请输入0-100的数字')
      }
    },
    onRelItemNumChange (index, item) {
      console.log(index, item)
      let reg = /^\d*$/
      if (!reg.test(item.num)) {
        this.relItemList[index].num = 0
        this.$message.error('请输入整数')
      } else if (item.num > 100 || item.num < 0) {
        this.relItemList[index].num = 0
        this.$message.error('请输入0-100的整数')
      }
    },
    onSessionAddChange () {
      if (this.form.active_valid_times.length == 0) {
        return this.$message.error('请先选择活动有效期')
      }
      const item = { times: '' }
      this.form.rel_round_list.push(item)
      console.log(this.form.rel_round_list)
    },
    onSessionDelChange (index) {
      this.form.rel_round_list.splice(index, 1)
    },
    generateSku() {
      let noSkuItem = []
      // let response = []
      let goodsList = JSON.parse(JSON.stringify(this.relItemList))
      goodsList.forEach(item => {
        if (!item.nospec && item.spec_items.length === 0) {
          noSkuItem.push(item.default_item_id)
        }
      })
      if (noSkuItem.length > 0) {
        let param = this.skuParams
        param.item_id = noSkuItem
        getItemsList(this.skuParams).then(res => {
          goodsList.forEach(item => {
            if (!item.nospec) {
              res.data.data.list.forEach(sku => {
                if (item.item_id === sku.default_item_id ) {
                  const obj = { item_id: sku.item_id, item_spec_desc: sku.item_spec_desc }
                  item.spec_items.push(obj)
                }
              })
            }
          })
        })
      }
      this.relItemList = goodsList
    },
    titleChange (value) {
      this.inputValue.title_length = this.getValueLength(value)
    },
    getValueLength (value) {
      let realLength = 0, charCode = -1, count = 0
      for (var i = 0; i < value.length; i++) {
        charCode = value.charCodeAt(i)
        if (charCode >= 0 && charCode <= 128) {
          count++
        } else {
          realLength++
        }
      }
      if (count > 0) {
        realLength = realLength + parseInt(count / 2)
        if (count % 2 > 0) {
          realLength += 1
        }
      }
      return realLength
    }
  },
  async mounted () {
    if (this.$route.query.marketing_id) {
      this.form.marketing_id = this.$route.query.marketing_id
      const res = await getLotteryActivityDetail({
        marketing_id: this.form.marketing_id
      })
      this.form = { ...this.form, ...res.data.data }
      this.form.active_valid_times = [this.form.start_time * 1000, this.form.end_time * 1000]
      this.form.start_time = this.form.start_time <= 0 ? '' : this.form.start_time * 1000
      this.form.end_time = this.form.end_time <= 0 ? '' : this.form.end_time * 1000
      this.form.distributor_id = []
      this.form.limit_rule_type = this.form.limit_rule.use_rule
      this.limitRuleList = this.limitRuleList.map(items => {
        return {
          use_rule: items.use_rule,
          label: items.use_rule == 'day' ? '每日限' : '每场次限',
          placeholder: '请输入内容',
          [items.use_rule]: this.form.limit_rule[items.use_rule] || 0,
          cus_is_edit: this.form.start_time < Date.now() && Date.now() < this.form.end_time // 进行中
        }
      })
      this.relPriceList = this.form.rel_gift.map(item => {
        return {
          id: item.gid,
          gift_name: item.gift_name,
          probability: item.probability,
          cus_is_edit: true
        }
      })
      this.relItemList = this.form.itemTreeLists.map(item => {
        let arr = []
        if (item.spec_items && item.spec_items.length > 0) {
          arr = item.spec_items.map(sku => {
            return {
              item_id: sku.item_id,
              item_spec_desc: sku.item_spec_desc
            }
          })
        }
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          sku_list: item.is_all_spec_items ? [] : arr,
          spec_items: [],
          num: item.num,
          nospec: item.nospec,
          default_item_id: item.default_item_id,
          cus_is_edit: true
        }
      })
      this.relGoodsItemsIds = this.relItemList // 商品回显
      this.form.rel_round_list = this.form.rel_round.map(items => {
        return {
          times: [Date.parse(items.start_time), Date.parse(items.end_time)]
        }
      })
      if (this.form.status == 'open') {
        if (this.form.start_time < Date.now() && Date.now() < this.form.end_time) { // 活动进行中
          this.progressDisabled = true
        }
      }
      if (this.form.status == 'close') {
        this.submitBtnHidden = true
      }
      this.distributor_info = this.form.use_shop == 'dealer' ? this.form.rel_shop_dealers : this.form.use_shop == 'distributor' ? this.form.rel_shop_distributors : []
      if (!this.distributor_info) {
        this.distributor_info = []
      }
      this.generateSku()
      const parseList = JSON.parse(this.form.marketing_data)
      if (parseList.length > 0) {
        this.form.logo_url = parseList[0].logo
        this.form.img_hotzone_info = parseList[0].data
        this.form.logo_two_url = parseList[1].logo
        this.form.img_hotzone_two_info = parseList[1].data
      }
      console.log(this.form, 'edit------form')
    }
  }
}

</script>
<style lang="scss">
.lotteryactivity {
  .session-form-item {
    width: 100%;
    margin-top:10px;
    display: flex;
    margin-bottom: 22px;
    .el-form-item__content {
      width: 89%;
      margin-left: 0px !important;
      .el-date-editor {
        // .el-range-input {
        //   width: 38%;
        // }
        .el-range-separator {
          width: 7%;
        }
      }
    }
  }
  .storecss, .useitem {
    display: flex;
    max-width: 1000px !important;
    .el-button--primary {
      background: #409EFF;
      color: #fff;
    }
    .el-form-item__content {
      width: 100%;
      display: flex;
      margin-left: 35px !important;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .useitem {
    .el-form-item__content {
      width: auto;
      margin-left: 0px !important;
    }
  }
  .step-card {
    .el-card__body {
      display: flex;
      width: 100%;
      padding: 0px;
    }
  }
  .step-container {
    display: flex;
    align-items: center;
    margin-left: 50px;
    padding: 20px;
    padding-right: 80px;
    position: relative;
    color: #AEAEAE;
    &:first-child {
      margin-left: 1%;
    }
  }
  .step-no {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 2px solid #797979;
    color: #797979;
  }
  .step-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #797979;
    }
  }
  .active {
    color: #5B8FF9 !important;
  }
  .border-active {
    border: 2px solid #5B8FF9 !important;
  }
  .arrow-right {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-top: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
    transform: rotate(45deg);//控制箭头的旋转方向，为顺时针旋转
    position: absolute;
    right: 0;
  }
  .lottery_quota_rules {
    width: 90% !important;
    max-width: 90% !important;
    .el-card__header {
      height: 40px;
      padding: 0px 20px;
    }
    .el-radio__label {
      width: 350px;
    }
    .el-radio {
      display: flex;
      align-items: center;
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .el-radio-group {
      display: flex;
      flex-direction: column;
    }
    .box-card-one {
      // width: 450px;
      max-width: 450px;
      min-width: 430px;
      // max-width: 700px;
    }
  }
  .lottery_quota_rules_table {
    .el-table_2_column_8 {
      .cell {
        display: flex;
      }
      .el-input {
        input {
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
  .hotzone {
    max-width: 800px;
    margin-bottom: 10px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .second-step {
    .el-form-item {
      width: 1000px !important;
    }
  }
}
</style>
<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  padding-bottom: 90px;
  .el-form {
    .el-form-item {
      max-width: 600px;
      // max-width: 670px;
    }
  }
  .vue-html5-editor {
    vertical-align: top;
    display: inline-block;
  }
  .show-goods {
    display: flex;
    justify-content: space-between;
    .item_show_quantity {
      .el-input {
        width: 200px;
      }
      .el-icon-warning-outline {
        color: #409EFF;
        margin: 0px 5px 0px 15px;
        font-size: 16px;
      }
    }
  }
}
.fallback-class {
  width: 118px;
  height: 118px;
}
.chosen-class {
  position: relative;
  height: 118px;
  text-align: center;
  .svg-icon {
    width: 26px;
    height: 26px;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 118px;
    right: 118px;
    bottom: 0;
    background: #ffb28b;
    box-shadow: inset 0 0 0 2px #ff5000;
    content: "";
  }
}
.sku-value {
  margin-right: 10px;
}
.sku-img {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
.sku-img-pick {
  color: #999;
  cursor: pointer;
}
.avatar-uploader-icon {
  font-size: 48px;
}
.sku-select__item {
  display: flex;
  position: relative;
}
.sku-select__checkgroup {
  padding-left: 20px;
  flex: 1;
}
.sku-select__checkitem {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}
.sku-remove__icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -20px;
  right: -20px;
  background: #ff5000;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}
.sku-tip {
  padding-left: 10px;
  font-size: 12px;
  color: #999;
}

.pics-box {
  overflow: hidden;
  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;
    .goodspic {
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .goodspic-mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        cursor: pointer;
        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}

.tpl_item {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fff;
  text-align: left;
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-radius: 3px;
  .iconfont {
    margin-right: 5px;
  }
}

.tpl_item .fa {
  width: 12px;
  height: 22px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  font-size: 20px;
  text-align: center;
}

.vue-html5-editor {
  vertical-align: top;
  display: inline-block;
}

.demo-ruleForm {
  .vue-html5-editor {
    width: 80%;
  }
}

</style>

