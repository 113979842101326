<style scoped lang="scss">
  .goods-title {
    padding-bottom: 5px;
  }
  .goods-code {
    color: #888;
    font-size: 13px;
    i {
      cursor: pointer;
    }
  }
</style>
<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <el-row class="filter-header" :gutter="20">
        <el-col>
          <el-input class="input-m" placeholder="优惠券名称" v-model="params.title">
            <el-button slot="append" icon="el-icon-search" @click="couponSearch"></el-button>
          </el-input>
          <el-select v-model="params.card_type" clearable placeholder="优惠券类型" @change="couponSearch">
            <el-option  v-for="item in card_status" :key="item.value"
                       :label="item.title" size="mini" :value="item.value"></el-option>
          </el-select>
          <el-date-picker v-model="get_date" type="daterange" start-placeholder="领取日期" ange-separator="至" end-placeholder="结束日期" value-format="yyyy/MM/dd" placeholder="选择日期范围" @change="dateChange" ></el-date-picker>
          <el-date-picker v-model="used_time" type="daterange" start-placeholder="使用日期" ange-separator="至" end-placeholder="结束日期" value-format="yyyy/MM/dd" placeholder="选择日期范围" @change="dateChange1" ></el-date-picker>
          <el-input class="input-m" placeholder="核销门店" v-model="params.shop_name">
            <el-button slot="append" icon="el-icon-search" @click="couponSearch"></el-button>
          </el-input>
            <el-button type="primary" @click="exportData" v-loading="exportloading">导出</el-button>
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
        </el-col>
      </el-row>
      <el-table :data="cardList" style="width: 100%" border v-loading="loading">
         <el-table-column prop="title" label="优惠券名称" width="150"></el-table-column>
        <el-table-column prop="card_type" label="类型" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.card_type === 'cash'" type="danger">{{scope.row.card_type_desc}}</el-tag>
            <el-tag v-if="scope.row.card_type === 'gift'" type="warning">{{scope.row.card_type_desc}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="get_date" label="领取日期" width="100"></el-table-column>
        <el-table-column prop="used_time" label="使用日期" width="100"></el-table-column>
        <el-table-column prop="item_name" label="使用商品" width="200"></el-table-column>
        <el-table-column prop="distributor_name" label="门店" >
          <template slot-scope="scope">
            <div>{{scope.row.distributor_name}}</div>
            <div>AppleId:{{scope.row.shop_code}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column prop="shop_code" label="门店AppleId" ></el-table-column>-->
        <el-table-column prop="dealer_name" label="经销商" >
          <template slot-scope="scope">
            <div>{{scope.row.dealer_name}}</div>
            <div>HQID:{{scope.row.dealer_hqid}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column prop="dealer_hqid" label="经销商HQID" ></el-table-column>-->
        <el-table-column prop="distributor_vad" label="分销商VAD"></el-table-column>
        <el-table-column prop="channel_rtm" label="渠道RTM" width="80"></el-table-column>
        <el-table-column prop="use_scan_status" label="状态" >
          <template slot-scope="scope">
            <span v-if="scope.row.used_order">线上订单使用，订单号：{{scope.row.used_order}}</span>
            <span v-else>{{scope.row.use_scan_status}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 20px 0"
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10,20,50]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
  import {getCouponUserList} from '../../../api/cardticket'

  export default {
    props: ['getStatus'],
    data() {
      return {
        loading: false,
        exportloading :false,
        total_count: 0,
        cardList: [],
        used_time:'',
        get_date:'',
        params: {
          page: 1,
          pageSize: 20
        },
        card_status: [
          { title: '抵扣券', value: 'cash' },
           { title: '兑换券', value: 'gift' },
          // { title: '折扣券', value: 'discount' },
          // { title: '福利券', value: 'service' },
        ],
      }
    },
    methods: {
      exportData () {
        this.exportloading = true
        // this.params.export = 1
        let param = {...this.params, export: 1}
        // param.export = 1
        getCouponUserList(param).then(res => {
          this.exportloading = false
          if (res.data.data.status) {
            this.$message({
              type: 'success',
              message: '已加入执行队列，请在设置-导出列表中下载'
            })
            return
          } else if (res.data.data.url) {
            this.downloadUrl = res.data.data.url
            this.downloadName = res.data.data.filename
            this.downloadView = true
          } else {
            this.$message({
              type: 'error',
              message: '无内容可导出 或 执行失败，请检查重试'
            })
            return
          }
        }).catch( error => {
          this.exportloading = false
        })
      },
      handleCurrentChange(val) {
        this.params.page = val
        this.getCardUserList()
      },
      dateChange (val) {
        this.params.status = ''
        if (val && val.length > 0) {
          this.params.start_time = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.params.end_time = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        } else {
          this.params.start_time = ''
          this.params.end_time = ''
        }
        this.params.page = 1
        this.getCardUserList()
      },
      dateChange1 (val) {
        this.params.status = ''
        if (val && val.length > 0) {
          this.params.start_time1 = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.params.end_time1 = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        } else {
          this.params.start_time1 = ''
          this.params.end_time1 = ''
        }
        this.params.page = 1
        this.getCardUserList()
      },
      couponSearch (val) {
        this.params.page = 1
        val && val.shop_id
        this.params.distributor_id = val.shop_id
        this.getCardUserList()
      },
      dateStrToTimeStamp (str) {
        return Date.parse(new Date(str))/1000
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getCardUserList()
      },
      getCardUserList() {
        this.loading = true
        getCouponUserList(this.params).then(res => {
          if (res.data.data.list.length > 0) {
            this.cardList = res.data.data.list
            this.total_count = res.data.data.total_count
            this.loading = false
          } else {
            this.cardList = []
            this.total_count = 0
            this.loading = false
          }
        })
          .catch(error => {
            this.loading = false
          })
      }
    },
    watch: {
      getStatus(val) {
        if (val) {
          this.getCardUserList()
        }
      }
    },
    mounted() {
      if (this.$route.query.cardId) {
        this.params.card_id = this.$route.query.cardId
      }
      this.getCardUserList()
    }
  }
</script>

<style scoped lang="scss">
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 90%;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 10px;
    img {
      width: 90%;
    }
  }
  .row-bg {
    padding: 10px 20px;
    background-color: #f9fafc;
  }
  .service-label .el-checkbox:first-child{
    margin-left: 15px;
  }
  .service-label .el-input:first-child{
    margin-left: 15px;
  }
  .grid-detail {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .el-carousel {
    width: 375px;
  }
  .tag-users {
    padding-bottom: 10px;
    line-height: 1.2;
    .icon-user-circle1 {
      margin-right: 10px;
      font-size: 24px;
      color: #ccc;
    }
  }
  .selected-tags {
    padding-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    .label {
      margin-bottom: 15px;
    }
    .el-tag {
      margin-right: 10px;
      margin-bottom: 15px;
    }
  }
  .tag-item {
    cursor: pointer;
    color: #666;
    border-color: #dadadd;
    margin-right: 10px;
    margin-top: 10px;
  }
  .copy-btn {
    position: relative;
    [class^="copy-link"] {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
    }
  }
</style>
<style lang="scss">
  .grid-detail {
    table, .detail-content-wrap, .detail-content-item {
      width: 100%!important;
    }
    img {
      width: 100%;
    }
  }
  .grid-attribute {
    table {
      width: 100%!important;
    }
  }
</style>
