<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <div class="content-bottom-padded">
        <el-input class="input-m" @change="onChange" v-model="params.marketing_title" placeholder="活动标题" />
        <el-select
          clearable
          placeholder="请选择活动类型"
          v-model="params.marketing_type"
          @change="onChange"
        >
          <el-option key="normal" label="常规礼品" value="normal" />
          <el-option key="limit" label="限定礼品" value="limit" />
        </el-select>
        <el-date-picker
          v-model="marketing_time"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          @change="onChange"
        />
        <el-button type="primary" v-if="login_type == 'disti'" @click="addLotteryActivity">添加抽奖活动</el-button>
        <el-button type="primary" v-if="login_type == 'distributor'" @click="onVisibleChange(true)">领奖核销</el-button>
      </div>
      <el-tabs v-model="params.status" @tab-click="handleClickChange">
        <el-tab-pane label="全部" name=""></el-tab-pane>
        <el-tab-pane label="未开始" name="wait"></el-tab-pane>
        <el-tab-pane label="生效中" name="open"></el-tab-pane>
        <el-tab-pane label="已失效" name="close"></el-tab-pane>
        <el-table :data="tableList" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="marketing_id" width="80" label="活动ID"></el-table-column>
          <el-table-column prop="" width="300" label="操作">
            <template slot-scope="scope">
              <router-link v-if="login_type == 'disti'" :to="{ path: matchHidePage('editor'), query: { marketing_id: scope.row.marketing_id}}">编辑</router-link>
              <el-button type="text" v-if="login_type == 'disti' && scope.row.status == 'open'" @click="onStopClickChange(scope.row)">中止</el-button>
              <el-button type="text" v-if="login_type == 'disti'" @click="onReffeClickChange(scope.row, 'lottery')">抽奖记录</el-button>
              <el-button type="text" @click="onReffeClickChange(scope.row, 'winner')">中奖名单</el-button>
              <el-button type="text" v-if="login_type == 'disti'" @click="onLimitClickChange(scope.row)">中奖限额</el-button>
              <ImportDistributor
                v-if="scope.row.use_shop === 'distributor'"
                buttonName="上传适用店铺"
                buttonType="text"
                fileName="上传适用店铺模版"
                fileType="lottery_upload_distributor"
                :relId="scope.row.marketing_id"
              ></ImportDistributor>
              <ImportDistributor
                v-if="scope.row.use_shop === 'dealer'"
                buttonName="上传适用经销商"
                buttonType="text"
                fileName="上传适用经销商模版"
                fileType="lottery_upload_dealer"
                :relId="scope.row.marketing_id"
              ></ImportDistributor>
            </template>
          </el-table-column>
          <el-table-column prop="marketing_title" min-width="110" label="活动标题" />
          <el-table-column prop="marketing_type" min-width="110" label="活动类型">
            <template slot-scope="scope">
              <span>{{ scope.row.marketing_type == 'normal' ? '常规礼品' : '限定礼品' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="overdue_time" min-width="180" label="有效时间">
            <template slot-scope="scope">
              {{ scope.row.start_time | datetime('yyyy-MM-dd hh:mm:ss') }} ~ {{ scope.row.end_time | datetime('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column v-if="login_type != 'distributor' && login_type != 'dealer'" prop="limit_num" min-width="80" label="发放名额" />
          <el-table-column prop="record_num" min-width="80" label="抽奖数" />
          <el-table-column prop="winning_num" min-width="80" label="中奖数" />
          <el-table-column prop="receive_num" min-width="80" label="核销数" />
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count'
            :page-size="params.pageSize"
          >
          </el-pagination>
        </div>
      </el-tabs>
      <CompRecordDrawer :dialogVisible="dialogVisible" :dialogType="dialogType" :dialogRowData="dialogRowData"  @onCloseChange="onCloseChange" />
      <CompLimitDrawer :limitVisible="limitVisible" :limitType="limitType" :limitRowData="limitRowData"  @onCloseChange="onLimitCloseChange" />
      <ReceiveDialog :visible="receiveVisible" @closeDialog="onVisibleChange" />
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLotteryActivityList, stopLotteryActivity } from '@/api/lottery'
import CompRecordDrawer from './comp/recordDrawer.vue'
import CompLimitDrawer from './comp/limitDrawer.vue'
import ReceiveDialog from './comp/receiveDialog.vue'
import ImportDistributor from '@/components/importDistributor'

export default {
  components: { CompRecordDrawer, CompLimitDrawer, ReceiveDialog, ImportDistributor },
  provide () {
    return {
      refresh: this.getLotterysList
    }
  },
  data () {
    return {
      loading: false,
      total_count: 0,
      marketing_time: [],
      params: {
        marketing_title: '',
        marketing_type: '',
        status: '',
        page: 1,
        pageSize: 10
      },
      tableList: [],
      dialogType: '',
      dialogVisible: false,
      dialogRowData: {},
      limitType: '',
      limitVisible: false,
      limitRowData: {},
      receiveVisible: false
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type'])
  },
  methods: {
    getLotterysList () {
      this.loading = true
      let params = {
        ...this.params
      }
      if (this.marketing_time && this.marketing_time.length > 0) {
        params.start_time = this.marketing_time[0] / 1000
        params.end_time = this.marketing_time[1] / 1000
      }
      getLotteryActivityList(params).then((response) => {
        if (response.data.data.list) {
          this.tableList = response.data.data.list
          this.total_count = response.data.data.total_count
        }
        this.loading = false
      })
    },
    handleClickChange (tab, event) {
      this.params.page = 1
      this.getLotterysList()
    },
    onStopClickChange (row) {
      this.loading = true
      stopLotteryActivity({ marketing_id: row.marketing_id }).then((response) => {
        this.$message.success('中止成功')
        this.getLotterysList()
      }).catch(error => {
        this.getLotterysList()
      })
      console.log(row)
    },
    onReffeClickChange (row, type) {
      this.dialogVisible = true
      this.dialogRowData = row
      this.dialogType = type

    },
    onLimitClickChange (row) {
      this.limitVisible = true
      this.limitRowData = row
      this.limitType = row.marketing_type
    },
    onChange () {
      this.params.page = 1
      this.getLotterysList()
    },
    addLotteryActivity () {
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getLotterysList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getLotterysList()
    },
    onCloseChange () {
      this.dialogVisible = false
      this.dialogRowData = {}
      this.dialogType = ''
    },
    onLimitCloseChange () {
      this.limitVisible = false
      this.limitRowData = {}
      this.limitType = ''
    },
    onVisibleChange (visible) {
      this.receiveVisible = visible
    },
  },
  mounted () {
    this.getLotterysList()
  }
}
</script>
<style lang="scss" scoped>
</style>
