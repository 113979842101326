<template>
  <div>
    <div style="margin: 15px 0">
      <span style="font-weight: bold"></span>
      <el-switch
        v-model="auto_confirm_user_close"
        active-text="系统自动审批"
        inactive-text="人工审批"
        active-color="#13ce66"
        inactive-color="#ff4949"
        @change="setAutoConfirmCloseUser">
      </el-switch>
    </div>

    <el-table :data="whitelistList" :height="wheight-160" v-loading="loading">
      <el-table-column prop="user_id" label="用户ID" width="120px"></el-table-column>
      <el-table-column prop="order_num_payed" label="未完订单数" width="120px"></el-table-column>
      <el-table-column prop="kaquan_num_canuse" label="未用券数" width="120px"></el-table-column>
      <el-table-column prop="created" label="申请时间" width="180px"></el-table-column>
      <el-table-column prop="remarks" label="备注"></el-table-column>
      <el-table-column label="操作" width="260px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.new_data !== 'REFUSED' && scope.row.new_data !== 'CONFIRMED'" size="medium"  type="danger" @click="closeOKAction(scope.$index, scope.row)">注销审核通过</el-button>
          <el-button v-if="scope.row.new_data !== 'REFUSED' && scope.row.new_data !== 'CONFIRMED'" size="medium" type="warning" @click="closeNGAction(scope.$index, scope.row)">拒绝关闭</el-button>
          <el-tag type="warning" v-if="scope.row.new_data == 'REFUSED'">已拒绝关闭</el-tag>
          <el-tag type="danger" v-if="scope.row.new_data == 'CONFIRMED'">已注销</el-tag>
          <!-- {{scope.row.new_data}} -->
        </template>
      </el-table-column>
   </el-table>
   <div class="content-center content-top-padded">
     <el-pagination
     layout="total, prev, pager, next"
     @current-change="handleCurrentChange"
     :current-page.sync="params.page"
     :total="total_count" :page-size="params.pageSize">
     </el-pagination>
   </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Message } from 'element-ui'
  import {   getMemberOperateLog, confirmCloseUser,refuseCloseUser, setAutoConfirmCloseUser, getAutoConfirmCloseUser } from '@/api/member'
  import {getWhitelistSetting, setWhitelistSetting} from '@/api/company'

  export default {
    props: {
      status: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isValid: true,
        isEdit: false,
        editVisible: false,
        editTitle: '',
        editTipsVisible: false,
        form: {
          mobile: '',
          name: '',
          tips: '',
        },
        mobile: '',
        editMobile: '',
        editName: '',
        whitelistList: [],
        detailData: {},
        loading: false,
        total_count: 0,
        params: {
          page: 1,
          pageSize: 20,
        },
        whitelist_id: 0,
        auto_confirm_user_close: 0,
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      handleClose(index) {
          this.relDistributors.splice(index, 1);
          this.form.distributor_ids.splice(index, 1);
      },
      handleCancel () {
        this.editVisible = false
        this.whitelist_id = ''
        this.editMobile = ''
        this.form.mobile = ''
        this.form.name = ''
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getListData()
      },
      addData () {
        this.handleCancel()
        this.editTitle = '添加白名单'
        this.editVisible = true
        this.isEdit = false
        this.form.mobile = ''
        this.form.name = ''
        this.whitelist_id = ''
        this.editMobile = ''
      },
      editAction (index, row) { // 编辑物料弹框
        this.handleCancel()
        this.editTitle = '编辑白名单信息'
        this.editVisible = true
        this.isEdit = true
        this.editMobile = row.mobile
        this.form.mobile = row.mobile
        this.form.name = row.name
        this.whitelist_id = row.whitelist_id
      },

      closeOKAction(index, row){
        // console.log("closeOKAction:params:",index, row)
        // console.log("closeOKAction:user_id:",row.user_id)

        this.$confirm('此操作将删除注销用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {id: row.id, user_id: row.user_id}
          confirmCloseUser(params).then(response => {
            this.$message({
              message: '注销用户成功',
              type: 'success',
              duration: 5 * 1000
            })
            this.getListData()
          }).catch(() => {
            this.$message({
              type: 'error',
              message: '注销失败'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
        console.log("closeOKAction:user_id:",row.user_id)
      },

      closeNGAction(index, row){
        console.log("closeNGAction:params:",index, row)
        console.log("closeNGAction:user_id:",row.user_id)

        this.$confirm('此操作将拒绝关闭申请, 是否继续撤销?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refuseCloseUser(row).then(response => {
            this.$message({
              message: '已拒绝',
              type: 'success',
              duration: 5 * 1000
            })
            this.getListData()
          }).catch(() => {
            this.$message({
              type: 'error',
              message: '注销失败'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },

      getListData () {
        this.loading = true
        this.params.operate_type = "CLOSE_APPLY"

        getMemberOperateLog(this.params).then(response => {
          this.whitelistList = response.data.data.list
          console.log(this.whitelistList)
          this.total_count = response.data.data.total_count
          this.loading = false
        })
      },

      setAutoConfirmCloseUser (config) {
        let params = {config: config};
        setAutoConfirmCloseUser(params).then(response => {
          if (response.data.data.success == true) {
            this.$message.success('修改成功')
          } else {
            this.$message.success('修改失败')
          }
        })
      },

      getAutoConfirmCloseUser () {
        getAutoConfirmCloseUser().then(response => {
          if (response.data.data.config !== undefined) {
            this.auto_confirm_user_close = response.data.data.config
          } else {
            this.auto_confirm_user_close = 0
          }
        })
      }

    },
    mounted () {
      this.getListData()
      this.getAutoConfirmCloseUser();
    },
    watch: {
      status (val) {
        if (val) {
          this.getListData()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .el-tag + .el-tag {
    margin-left: 5px;
  }
  .new-tag {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .button-new-tag {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    height: 40px;
    line-height: 40px;
    width:138px;
    vertical-align: bottom;
  }

  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 10px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
