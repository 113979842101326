<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>设置</span>
    </div>
    <el-form label-width="150px">
      <el-form-item label="是否开启">
        <el-switch v-model="form.open_status" @change="secureCheckTestAppSettingSet()" active-color="#13ce66" inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item label="测试文字说明图片">
        <div>
          <div>
            <div @click="handleHomeImgChange" class="upload-box">
              <img v-if="form.image_url" :src="form.image_url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </div>
          <imgPicker :dialog-visible="homeimgDialog" :sc-status="isGetImage" @chooseImg="pickImg"
            @closeImgDialog="closeHomeImgDialog"></imgPicker>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import imgPicker from '@/components/imageselect'
import { getSecureCheckTestAppSetting, getSecureCheckTestAppSettingSet } from '@/api/trade'

export default {
  components: {
    imgPicker
  },
  data() {
    return {
      homeimgDialog: false,
      isGetImage: false,
      form: {
        image_url: '',
        open_status: false
      },
      imgUrl: ''
    }
  },
  methods: {
    handleHomeImgChange() {
      this.homeimgDialog = true
      this.isGetImage = true
    },
    pickImg(data) {
      this.form.image_url = data.url
      this.homeimgDialog = false
      this.secureCheckTestAppSettingSet()
    },
    closeHomeImgDialog() {
      this.homeimgDialog = false
    },
    secureCheckTestAppSetting() {
      getSecureCheckTestAppSetting().then(res => {
        this.form = res.data.data
      })
    },
    secureCheckTestAppSettingSet() {
      getSecureCheckTestAppSettingSet(this.form).then(res => {
        this.$message({
          message: '设置成功',
          type: 'success'
        });
      })
    }
  },
  mounted() {
    this.secureCheckTestAppSetting()
  }
}
</script>

<style scoped lang="scss"></style>
