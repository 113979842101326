<template>
  <div ref="reference" class="sp-store-dialog">
    <el-dialog :title="title" :visible.sync="visible" width="70%" :close-on-click-modal="false" :before-close="cancelAction">
      <SpFilterForm :model="formData" @onSearch="onSearch" @onReset="onReset">
        <SpFilterFormItem prop="status" label="门店名称">
          <el-input v-model="formData.distributor_name" placeholder="请输入门店名称" />
        </SpFilterFormItem>
        <SpFilterFormItem prop="login_name" label="门店编号">
          <el-input v-model="formData.shop_code" placeholder="请输入门店编号" />
        </SpFilterFormItem>
      </SpFilterForm>
      <SpFinder
        ref="finder"
        :setting="setting"
        row-key="id"
        row-actions-width="200"
        :row-actions-align="'left'"
        :fixed-row-action="true"
        reserve-selection
        :url="url"
        :hooks="{
          beforeSearch: beforeSearch,
          afterSearch: afterSearch
        }"
        @selection-change="onSelectionChange"
      >
        <template slot="tableTop" v-if="!isdetail && this.shopTotal > 0">
          <el-button type="primary" style="margin-bottom: 20px;" @click="onDeleteChange(selectData)">批量删除</el-button>
        </template>
      </SpFinder>
    </el-dialog>
  </div>
</template>

<script>
import { createSetting } from '@shopex/finder'
export default {
  name: 'SpStoreDialog',
  props: {
    title: String,
    visible: Boolean,
    url: String,
    params: Object,
    isdetail: Boolean
  },
  data() {
    return {
      formData: {
        distributor_name: '',
        shop_code: ''
      },
      selectData: [],
      shopTotal: 0,
      setting: createSetting({
        columns: [
          { name: '门店名称', key: 'distributor_name' },
          { name: '门店编号', key: 'shop_code' },
        ],
        actions: [{
          name: '删除',
          key: 'apply',
          type: 'button',
          buttonType: 'text',
          visible: () => {
            return !this.isdetail
          },
          action: {
            handler: async ([row]) => {
              this.onDeleteChange([row])
            }
          }
        }]
      })
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.$refs.finder) {
          this.$refs.finder.refresh(true)
        }
      }
    }
  },
  methods: {
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    beforeSearch(params) {
      if (this.formData.distributor_name) {
        params.distributor_name = this.formData.distributor_name
      }
      if (this.formData.shop_code) {
        params.shop_code = this.formData.shop_code
      }
      if (this.params.temp_id) {
        params.temp_id = this.params.temp_id
      }
      if (this.params.activity_id) {
        params.activity_id = this.params.activity_id
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      this.shopTotal = data.total_count
      return data
    },
    cancelAction () {
      this.$emit('onCloseDialog', false, this.shopTotal)
    },
    onDeleteChange (rows) {
      const rowids = rows.map(row => row.id)
      this.$confirm(`确认删除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$emit('onDelete', rowids, () => {
          this.$refs.finder.refresh()
        })
      })
    },
    onSelectionChange(selection) {
      this.selectData = selection
    },
    onReset () {
      this.formData = {
        distributor_name: '',
        shop_code: ''
      }
      this.$refs.finder.refresh(true)
    }
  }
}
</script>
<style lang="scss">
</style>