<template>
  <el-dialog
    class="gift-dialog"
    width="50%"
    title="选择赠品"
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    :before-close="cancelAction"
    append-to-body
  >
    <div v-if="params.item_type === 'normal'" style="margin-bottom: 15px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input
            placeholder="赠品名称/赠品ID"
            v-model="params.search"
            clearable
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchByKey"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="multipleTable"
      :data="itemsData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectChange"
      v-loading="loading"
      :row-key="getRowKeys"
      :select-on-indeterminate="false"
    >
      <el-table-column
        type="selection"
        :reserve-selection="true"
        width="50"
      />
      <el-table-column prop="id" label="赠品ID" width="120" />
      <el-table-column prop="name" label="赠品名称" />
      <el-table-column
        prop="store"
        label="可用库存"
        width="100"
        show-overflow-tooltip
      />
    </el-table>
    <div v-if="total_count > params.pages_size" class="pager">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10, 20, 30, 50]"
        :total="total_count"
        :page-size="params.pages_size"
      >
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelAction">取 消</el-button>
      <el-button type="primary" @click="saveStoreAction">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLotteryGiftList } from '@/api/lotteryGift'

export default {
  props: {
    itemsVisible: {
      type: Boolean,
      default: false
    },
    relItemsIds: {
      type: Array,
      default: function () {
        return []
      }
    },
    getStatus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      loading: false,
      itemsData: [],
      selectRows: [],
      total_count: '',
      multipleSelection: [],
      params: {
        page: 1,
        pages_size: 30,
        search: ''
      },
    }
  },
  computed: {
    ...mapGetters(['wheight'])
  },
  methods: {
    getRowKeys (row) {
      return row.id
    },
    handleCurrentChange (page_num) {
      this.$refs.multipleTable.clearSelection()
      this.params.page = page_num
      this.getNewsList()
    },
    handleSizeChange (pages_size) {
      this.$refs.multipleTable.clearSelection()
      this.params.page = 1
      this.params.pages_size = pages_size
      this.getNewsList()
    },
    searchByKey () {
      this.params.page = 1
      this.getNewsList()
    },
    // handleSelectAll (val) {
    //   console.log(this.selectRows, 'start')
    //   if (val.length > 0) {
    //     this.multipleSelection = val
    //     val.forEach(item => {
    //       let inChecked = this.selectRows.findIndex(n => item.id == n.id)
    //       if (inChecked == -1) {
    //         this.selectRows.push(item)
    //       }
    //     })
    //   }
    //   console.log(this.selectRows, 'end')
    // },
    handleSelectChange (val) {
      console.log(val, '---')
      this.multipleSelection = val
      if (val.length > 0) {
        val.forEach(item => {
          let isInArr = this.selectRows.findIndex((n) => n.id == item.id)
          if (isInArr === -1) {
            this.selectRows.push(item)
          }
        })
      }
    },
    cancelAction () {
      this.$emit('closeStoreDialog')
      this.selectRows = []
      this.multipleSelection = []
      this.itemsData = []
      this.params = {
        page: 1,
        pages_size: 30,
        search: ''
      }
    },
    saveStoreAction () {
      console.log(this.selectRows, this.multipleSelection)
      this.$emit('chooseAction', this.multipleSelection)
      this.selectRows = []
      this.multipleSelection = []
    },
    getNewsList () {
      this.loading = true
      getLotteryGiftList(this.params).then(response => {
        this.itemsData = response.data.data.list
        this.total_count = parseInt(response.data.data.total_count)
        this.multipleSelection = []
        this.$refs.multipleTable.clearSelection()
        if (this.selectRows.length > 0) {
          this.selectRows.forEach(item => {
            this.$refs.multipleTable.toggleRowSelection(item)
          })
        }
        this.loading = false
      })
    }
  },
  mounted () {
  },
  computed: {
    showDialog () {
      return this.itemsVisible
    }
  },
  watch: {
    // itemsVisible (newVla) {
    //   if (newVla) {
    //   }
    // },
    getStatus (newVal, oldVal) {
      if (newVal) {
        if (this.type == 'normal') {
          this.params.type = 1
        } else if (this.type == 'limit') {
          this.params.type = 2
        }
        this.getNewsList()
      }
    },
    itemsData (val) {
      // if (this.relItemsIds.length > 0) {
      //   this.itemsData.forEach(item => {
      //     let checked = this.relItemsIds.find(n => n.id == item.id)
      //     if (checked) {
      //       this.$refs.multipleTable.toggleRowSelection(item)
      //     }
      //   })
      // }
    },
    relItemsIds (newVal, oldVal) {
      this.selectRows = newVal
    },
  }
}
</script>

<style scoped lang="scss">
.store {
  margin-bottom: 10px;
}
.gift-dialog .el-checkbox {
  display: inline;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-select .el-input {
  width: 130px;
}
.pager {
  margin-top: 20px;
}
.tab-group {
  .tab-btn {
    &.on {
      border-color: #e6a23c;
      background-color: #e6a23c;
      color: #fff;
    }
  }
}
</style>
