<template>
  <div class="sp-discount-sale">
    <el-form ref=form :model=form class=box-set label-width='150px' v-loading.fullscreen.lock="pageLoading" :rules="rules">
      <el-card header=活动编辑>
        <el-row :gutter="20">
          <el-col :span="13">
            <el-form-item label="活动名称" prop="activity_title">
              <el-input :disabled="isdetail" v-model="form.activity_title" placeholder="请输入活动名称" />
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="活动时间" required>
              <el-date-picker
                :clearable=true
                v-model="activity_times"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
                :disabled="isdetail"
              />
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="活动描述"  prop="activity_desc">
              <el-input :disabled="isdetail" type="textarea" :rows="2" v-model="form.activity_desc" placeholder="请输入内容" />
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="活动参与条件"  prop="condition_type" required style="margin-bottom: 0px;">
              <el-radio-group :disabled="isdetail || isedit" v-model="form.condition_type">
                <el-radio :label="1">按购买参与商品满数量</el-radio>
                <el-radio :label="2">按购买参与商品满金额</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label=""  prop="condition_value">
              <el-input :disabled="isdetail" v-model="form.condition_value" :placeholder="form.condition_type == 1 ? '请输入需要购买的指定件数' : '请输入需要购买的指定金额'" />
            </el-form-item>
          </el-col>
          <el-col :span="13" class="lineheght">
            <el-form-item label="单笔订单可购买优惠搭售品总件数"  prop="item_num_limit" style="margin-bottom: 10px;">
              <el-input v-model="form.item_num_limit" :disabled="isdetail || item_num_limit_checked" placeholder="请输入参与次数" />
            </el-form-item>
            <el-form-item label="">
              <el-checkbox :disabled="isdetail" v-model="item_num_limit_checked" @change="itemLimitCheckedChange" label="不限件数" />
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="活动可参与订单数"  prop="join_limit" style="margin-bottom: 10px;">
              <el-input v-model="form.join_limit" :disabled="isdetail || join_limit_checked" placeholder="请输入参与次数" />
            </el-form-item>
            <el-form-item label=""  prop="join_limit_checked">
              <el-checkbox :disabled="isdetail" v-model="join_limit_checked" @change="joinLimitCheckedChange" label="不限订单数" />
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="活动参与商品"  required>
              <SkuSelector :canEdit="!isdetail" :selectSku="!isdetail" :showFooterBtn="!isdetail" @change="getItems" :data="relItems"></SkuSelector>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="优惠搭售商品"  required>
              <div v-if="!isdetail" class="upload-btns" style="margin-bottom: 10px;">
                <el-upload class="upload-demo" action=" " :show-file-list="false" :auto-upLoad="true" :http-request='onUploadGoodChange'>
                  <el-button type="primary" plain>导入优惠搭售品</el-button>
                </el-upload>
                <el-button type='primary' plain @click="() => onLoadShopTemplate('upload_bundle_discount_items')">下载模版</el-button>
              </div>
              <div>
                <el-alert
                  title="搭售品导入为新增逻辑"
                  type="warning"
                  show-icon
                  :closable="false"
                  style="height: 30px;width:22%;"
                />
              </div>
              <SpFinder
                no-selection
                ref="finder"
                :show-pager="false"
                :setting="setting"
                row-actions-width="100"
                row-actions-align="left"
                :fixed-row-action="true"
                :data="dicountGoodsList"
                url=""
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- 选择门店 -->
            <el-form-item label="选择门店" prop="distributor_type" required>
              <el-radio-group :disabled="isdetail" v-model="form.distributor_type">
                <el-radio label="all">全部门店</el-radio>
                <el-radio label="distributor">按指定门店</el-radio>
              </el-radio-group>
              <div v-if="form.distributor_type == 'distributor'">
                <div class="shop-list">
                  <div>已添加{{ shopTotal }}家门店</div>
                  <div>
                    <el-button type="text" v-if="(!form.activity_id || distributorTempId) && shopTotal > 0" @click="onShopDialogChange(true, shopTotal)">查看</el-button>
                    <el-button v-if="!isdetail && shopTotal > 0" type="text" @click="onDeleShopChange">清除</el-button>
                  </div>
                </div>
                <div class="upload-btns" v-if="!isdetail">
                  <el-upload class="upload-demo" action=" " :show-file-list="false" :auto-upLoad="true" :http-request='onUploadShopChange'>
                    <el-button type="primary" plain>导入门店</el-button>
                  </el-upload>
                  <el-button @click="() => onLoadShopTemplate('upload_bundle_discount_distributor')" plain type="primary">下载模版</el-button>
                </div>
              </div>
              <div v-if="form.distributor_type == 'distributor'">
                <el-alert
                  title="门店导入为覆盖逻辑"
                  type="warning"
                  show-icon
                  :closable="false"
                  style="height: 30px;width:22%;margin-top:10px;"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>

    <div class=content-center style="margin: 20px 0">
      <el-button type='primary' @click="onSubmitChange('form')" :loading="submitBtnLoading">保存</el-button>
      <el-button @click.native=handleCancel>返回</el-button>
    </div>

    <SpStoreDialog
      title="查看参与门店"
      :visible.sync="shopDialog"
      :isdetail="isdetail"
      :params="{ temp_id: distributorTempId, activity_id: iscopy ? $route.query.activity_id : ''}"
      url="/promotions/bundleDiscount/distributors"
      @onDelete="onDeleteShopChange"
      @onCloseDialog="onShopDialogChange"
    />
  </div>
</template>

<script>
import { createSetting } from '@shopex/finder'
import SkuSelector from '@/components/function/skuSelector'
import SpStoreDialog from './comps/sp-store-dialog'
import { postDistributorUpload, getDiscountDistributorList, deleteBundleDistributorList, postDiscountGoodUpload, deleteDiscountGoods, getDiscountGoodsList, addBundleDiscount, getBundleDiscountDetail, updateBundleDiscount } from '@/api/discount'
import { exportUploadTemplate } from "@/api/common";
import { isArray } from '@/utils';

export default {
  components: {
    SkuSelector,
    SpStoreDialog
  },
  data () {
    const conditionValueValid = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/
      if (!value) {
       callback(new Error(`请输入需要购买的指定${this.form.condition_type == 1 ? '件数' : '金额'}`))
      } else if (this.form.condition_type == 1 && !reg.test(value)) {
        callback(new Error('请输入大于0的正整数'))
      } else if (this.form.condition_type == 2 && value && value <= 0) {
        callback(new Error('指定金额需大于0'))
      }
      callback()
    }
    const itemMinLimitValid = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/
      if (this.item_num_limit_checked) {
        callback()
      } else if (!value) {
       callback(new Error('请输入参与次数'))
      } else if (!reg.test(value)) {
       callback(new Error('请输入大于0的正整数'))
      }
      callback()
    }
    const joinMinLimitValid = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/
      if (this.join_limit_checked) {
        callback()
      } else if (!value) {
       callback(new Error('请输入参与次数'))
      } else if (!reg.test(value)) {
       callback(new Error('请输入大于0的正整数'))
      }
      callback()
    }
    return {
      submitBtnLoading: false,
      pageLoading: false,
      status: '',
      form: {
        activity_id: '',
        activity_title: '',
        activity_desc: '',
        start_time: '',
        end_time: '',
        condition_type: 1,
        condition_value: '',
        item_num_limit: '',
        join_limit: '',
        bind_items: [],
        distributor_type: 'all'
      },
      activity_times: [],
      item_num_limit_checked: false,
      join_limit_checked: false,
      rules: {
        activity_title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          // { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        condition_value: [
          { required: true, validator: conditionValueValid, trigger: 'change' }
        ],
        item_num_limit: [
          { required: true, validator: itemMinLimitValid, trigger: 'change' }
        ],
        join_limit: [
          { required: true, validator: joinMinLimitValid, trigger: 'change' }
        ],
      },
      relItems: [],
      dicountGoodsList: [],
      shopTotal: 0,
      shopDialog: false,
      dicountItemsTempId: '', // 优惠搭售商品tempid
      distributorTempId: '', // 参与门店tempid
      setting: createSetting({
        columns: [
          { name: '商品ID', key: 'item_id' },
          { name: '商品编号', key: 'goods_id' },
          { name: '商品名称', key: 'item_name' },
          { name: '规格', key: 'spec_list_str' },
          { name: '货号', key: 'item_bn' },
          { name: '销售价', key: 'price', render: (h, { row }) => h('span', [row.price / 100])},
          {
            name: '',
            key: 'sort' ,
            headRender: (h) => {
              return (
                <div>
                  展示排序
                  <el-popover
                    placement="top-start"
                    width="250"
                    trigger="hover"
                    content="排序数值越大，小程序展示的位置越靠前"
                  >
                    <span slot="reference"><i class="el-icon-info"></i></span>
                  </el-popover>
                </div>
              )
            },
            render: (h, scope) => {
              return <el-input disabled={this.isdetail} v-model={scope.row.sort} on-change={(value) => this.onGoodInputChange(scope.$index, scope.row, 'sort', value)} />
            }
          },
          {
            name: '可购买次数',
            key: 'limit_num' ,
            render: (h, scope) => {
              return <el-input disabled={this.isdetail} v-model={scope.row.limit_num} on-change={(value) => this.onGoodInputChange(scope.$index, scope.row, 'limit_num', value)} />
            }
          },
          {
            name: '优惠金额（元）',
            key: 'discount_amount' ,
            width: 150,
            render: (h, scope) => {
              return <el-input disabled={this.isdetail} v-model={scope.row.discount_amount} on-change={(value) => this.onGoodInputChange(scope.$index, scope.row, 'discount_amount', value)} />
            }
          },
        ],
        actions: [
          {
            name: '删除',
            key: 'delete',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return !this.isdetail
            },
            action: {
              handler: async (row) => {
                this.onDeleteGoodsItem(row[0])
              }
            }
          }
        ]
      }),
      pickerOptions: {},
      isdetail: false,
      isedit: false,
      iscopy: false,
      deleted_discount_items: [],
      clean_all_distributor: 0
    }
  },
  methods: {
    getItems (data) {
      let ids = []
      data.forEach(item => {
        ids.push(item.itemId)
      })
      this.form.bind_items = ids
    },
    getBundleDetail (activity_id) {
      this.pageLoading = true
      getBundleDiscountDetail(activity_id).then(response => {
        const { activity_id, activity_title, activity_desc, start_time, end_time, items, bind_items, status, join_limit, item_num_limit, distributor_type, distributor_count, condition_type, condition_value, itemTreeLists } = response.data.data
        const data = {
          activity_title,
          activity_desc,
          distributor_type,
          condition_type,
          condition_value,
          item_num_limit,
          join_limit
        }
        if (status == 1) {
          this.pickerOptions = {
            disabledDate(time) {
              return time.getTime() < Date.now() - 3600 * 1000 * 24
            }
          }
        }
        this.status = status
        this.form = data
        if (this.isedit || this.isdetail) {
          this.form.activity_id = activity_id
          this.form.start_time = start_time
          this.form.end_time = end_time
          if (start_time && end_time) {
            this.activity_times = [start_time * 1000, end_time * 1000]
          }
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
          this.activity_times = []
        }
        if (join_limit == 0) {
          this.join_limit_checked = true
          this.form.join_limit = ''
        }
        if (item_num_limit == 0) {
          this.item_num_limit_checked = true
          this.form.item_num_limit = ''
        }
        this.shopTotal = distributor_count
        if (itemTreeLists.length > 0) {
          this.relItems = itemTreeLists
        }
        this.form.bind_items = bind_items
        let filtee_list = []
        if (items.length > 0) {
          filtee_list = items.map(items => { // 缓存旧值
            return {
              ...items,
              cus_old_sort: items.sort || 0,
              cus_old_limit_num: items.limit_num || 0,
              cus_old_discount_amount: items.discount_amount || 0
            }
          })
        }
        console.log(this.form)
        this.dicountGoodsList = filtee_list
        this.pageLoading = false
      }).catch(() => {
        this.$message.error('获取活动详情失败')
        this.pageLoading = false
      })
    },
    getShopList () {
      this.pageLoading = true
      getShopList(this.form.activity_id).then(response => {
        this.shopTotal = response.data.data.total
        this.pageLoading = false
      }).catch(() => {
        this.pageLoading = false
      })
    },
    itemLimitCheckedChange () {
      this.form.item_num_limit = ''
    },
    joinLimitCheckedChange () {
      this.form.join_limit = ''
    },
    handleCancel() {
      this.$router.go(-1)
    },
    onSubmitChange (formName) {
      console.log('onSubmitChange', this.form)
      if (this.form.bind_items.length == 0) {
        return this.$message.error('请选择活动参与商品')
      }
      if (this.dicountGoodsList.length == 0) {
        return this.$message.error('请选择优惠搭售商品')
      }
      if (this.shopTotal == 0 && this.form.distributor_type != 'all') {
        return this.$message.error('请选择活动参与门店')
      }
      let formData = JSON.parse(JSON.stringify(this.form))
      console.log(formData, 'formData')
      if (this.activity_times.length > 0) {
        formData['start_time'] = this.activity_times[0] / 1000
        formData['end_time'] = this.activity_times[1] / 1000
      } else {
        return this.$message.error('请选择活动时间')
      }
      if (this.join_limit_checked) {
        formData['join_limit'] = 0
      }
      if (this.item_num_limit_checked) {
        formData['item_num_limit'] = 0
      }
      if (this.distributorTempId && this.form.distributor_type != 'all') {
        formData['distributor_temp_id'] = this.distributorTempId
      }
      // if (this.dicountItemsTempId) {
      //   formData['discount_items_temp_id'] = this.dicountItemsTempId
      // }
      if (this.form.bind_items.length > 0) {
        formData['bind_items'] = this.form.bind_items.join(',')
      }
      if (this.deleted_discount_items.length > 0) {
        formData['deleted_discount_items'] = this.deleted_discount_items.join(',')
      }
      const items = this.dicountGoodsList.map(el => {
        return {
          item_id: el.item_id,
          goods_id: el.goods_id,
          id: el.id,
          sort: el.sort,
          limit_num: el.limit_num,
          discount_amount: el.discount_amount
        }
      })
      if (items.length > 0) {
        formData['discount_items'] = items
      }
      if (this.iscopy) {
        formData['copy_activity_id'] = this.$route.query.activity_id
      }
      console.log(formData, 'formData')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addBundleDiscount(formData).then(res => {
            if (res.data.data) {
              this.$message({
                message: this.form.activity_id ? '更新成功' : '保存成功',
                type: 'success',
                duration: 2 * 1000
              })
              this.handleCancel()
            } else {
              this.$message.error(this.form.activity_id ? '更新失败！' : '保存失败！')
              return false
            }
          }).catch((e) => {
            console.log(e)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getDiscountGoodData () {
      let params = {}
      if (this.form.activity_id) {
        params['activity_id'] = this.form.activity_id
      }
      if (this.dicountItemsTempId) {
        params['temp_id'] = this.dicountItemsTempId
      }
      console.log(params)
      getDiscountGoodsList(params).then(res => {
        const list = res.data.data.list
        console.log(res, 'getDiscountGoodsList')
        const filterList = list.map(items => {
          return {
            ...items,
            cus_old_sort: items.sort || 0,
            cus_old_limit_num: items.limit_num || 0,
            cus_old_discount_amount: items.discount_amount || 0
          }
        })
        console.log(filterList, 'filterList')
        const self = this
        this.dicountGoodsList.forEach(function (val, index) {
          if (val.activity_id == 0) {
            self.dicountGoodsList.splice(index)
          }
        })
        if (filterList) {
          filterList.forEach(function (val, index) {
            self.dicountGoodsList.push(val)
          })
        }
      })
    },
    onUploadGoodChange (request) {
      let params = {
        deleted_discount_items: this.deleted_discount_items,
        file: request.file,
        isUploadFile: true
      }
      if (this.form.activity_id && this.isedit) {
        params['activity_id'] = this.form.activity_id
      }
      if (this.dicountItemsTempId) {
        params.temp_id = this.dicountItemsTempId
      }
      console.log(request, params)
      postDiscountGoodUpload(params).then(res => {
        console.log(res)
        let data = res.data.data
        this.$message.success('上传成功！')
        const self = this
        if (data.update_data) {
          this.dicountGoodsList.forEach(function (value, index) {
            data.update_data.forEach(function (val, ind) {
              if (value.id == val.id) {
                value.sort = val.sort
                value.limit_num = val.limit_num
                value.discount_amount = val.discount_amount
              }
            })
          })
        }
        if (data.temp_id) {
          this.dicountItemsTempId = data.temp_id
          this.getDiscountGoodData()
        }
      })
    },
    async onDeleteGoodsItem (row) {
      if (row.temp_id) {
        const params = {
          temp_id: this.dicountItemsTempId,
          id: row.id
        }
        await deleteDiscountGoods(params).then(res => {
          this.$message.success('删除成功！')
          this.getDiscountGoodData()
        }).catch(err => {
          this.$message.error('删除失败！')
        })
      } else {
        const index = this.dicountGoodsList.findIndex(item => item.id == row.id)
        this.dicountGoodsList.splice(index, 1)
        this.deleted_discount_items.push(row.id)
      }
    },
    async onGoodInputChange (index, row, key, value) {
      const cus_keys = `cus_old_${key}`
      if (key == 'sort') { // 排序
        if (value == 0) {
          this.$message.error('排序不能为0！')
          this.dicountGoodsList[index][key] = row[cus_keys]
          this.dicountGoodsList.forEach(items => { // 修改sort时同goodsid值都要改掉
            if (items.goods_id == row.goods_id) {
              items[key] = row[cus_keys]
            }
          })
          return
        }
        let filter = ''
        filter = this.dicountGoodsList.find(item => item[cus_keys] == value)
        if (filter && filter.id != row.id) {
          this.$message.error('排序不能重复！')
          this.dicountGoodsList[index][key] = row[cus_keys]
          this.dicountGoodsList.forEach(items => { // 修改sort时同goodsid值都要改掉
            if (items.goods_id == row.goods_id) {
              items[key] = row[cus_keys]
            }
          })
          return
        }
        this.dicountGoodsList.forEach(items => { // 修改sort时同goodsid值都要改掉
          if (items.goods_id == row.goods_id) {
            items[key] = value
            items[cus_keys] = value
          }
        })
      }
      if (key == 'discount_amount') { // 优惠金额
        if (Number(value) >= Number(row.price) / 100) {
          this.$message.error('优惠金额需小于销售价！')
          this.dicountGoodsList[index][key] = row[cus_keys]
          return
        }
      }
      this.dicountGoodsList[index][key] = value
      this.dicountGoodsList[index][cus_keys] = value
    },
    onUploadShopChange (request) {
      let params = {
        file: request.file,
        isUploadFile: true
      }
      if (this.clean_all_distributor) {
        params['clean_all_distributor'] = this.clean_all_distributor
      }
      if (this.form.activity_id && this.isedit) {
        params['activity_id'] = this.form.activity_id
      }
      if (this.distributorTempId) {
        params.temp_id = this.distributorTempId
      }
      postDistributorUpload(params).then(res => {
        this.$message.success('上传成功！')
        let data = res.data.data
        if (data.temp_id) {
          this.distributorTempId = data.temp_id
          this.getDistributorList()
        }
      })
    },
    onLoadShopTemplate (file_type) {
      let params = {
        file_type,
        file_name: '导出模版'
      };
      exportUploadTemplate(params).then((response) => {
        if (response.data.data.file) {
          var a = document.createElement("a");
          a.href = response.data.data.file;
          a.download = response.data.data.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          this.$message({
            type: "error",
            message: "没有相关数据可导出",
          });
        }
      });
    },
    onShopDialogChange (bool, num) {
      this.shopDialog = bool
      this.shopTotal = num
    },
    onDeleShopChange () {
      this.$confirm(`确认删除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.distributorTempId = ''
        this.shopTotal = 0
        if (this.isedit && this.form.activity_id) { // 编辑时，点击清除clean_all_distributor设置成1，在导入的时候需要传到接口里
          this.clean_all_distributor = 1
        }
      })
    },
    async onDeleteShopChange (value, callback) {
      console.log(value, '----onDeleteShopChange---')
      if (value.length == 0) return
      const params = {
        temp_id: this.distributorTempId || '',
        id: isArray(value) ? value.join(',') : value
      }
      deleteBundleDistributorList(params).then(res => {
        this.$message.success('删除成功!')
        callback()
      })
    },
    // 店铺列表
    getDistributorList() {
      const params = {
        page: 1,
        pageSize: 10,
        temp_id: this.distributorTempId
      }
      getDiscountDistributorList(params).then(res => {
        this.shopTotal = res.data.data.total_count
      })
    },
  },
  mounted () {
    console.log(this.$route.query)
    const activity_id = this.$route.query.activity_id
    const isedit = this.$route.query.edit || false
    const isdetail = this.$route.query.detail
    const iscopy = this.$route.query.copy || false
    this.isdetail = isdetail
    this.isedit = isedit
    this.iscopy = iscopy
    if (activity_id) {
      this.getBundleDetail(activity_id)
    }
  },
}
</script>

<style lang="scss">
.sp-discount-sale {
  .lineheght {
    .el-form-item__label {
      line-height: 20px;
    }
  }
  .shop-list {
    width: 400px;
    height: 50px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .upload-demo {
    height: 40px;
    margin-right: 3px;
  }
  .btnupload {
    height: 40px;
  }
  .upload-btns{
    display: flex;
    .el-upload--text {
      width: auto;
    }
  }
}
</style>
