<template>
  <el-dialog title="领取核销" :visible.sync="visible" width="500px" @close="onCancelChange" append-to-body>
    <el-form ref="form" :rules="rules" :model="form" label-width="100px">
      <el-form-item prop="receive_mobile" label="领取人">
        <el-input v-model="form.receive_mobile" placeholder="请输入领取手机号" class="input-b" />
      </el-form-item>
      <el-form-item prop="code" label="核销码">
        <el-input v-model="form.code" placeholder="请输入6位领取核销码" class="input-b" />
      </el-form-item>
      <el-form-item label="中奖编号" v-if="!isInit">
        {{ resultObj.winning_code }}
      </el-form-item>
      <el-form-item label="中奖活动" v-if="!isInit">
        {{ resultObj.marketing_title }}
      </el-form-item>
      <el-form-item label="中奖礼品" v-if="!isInit">
        {{ resultObj.gift_name }}
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancelChange">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="handleSubmit('form')">查 询</el-button>
      <el-button v-if="!isInit" type="primary" :loading="loading" @click="activityVerifyChange">确认核销</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { activityVerify, getWinnerDetail } from '@/api/lottery'
import { isMobile } from '@/utils/validate'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    const validateMobile = (rule, value, callback) => {
      if (value && !isMobile(value)) {
        callback(new Error('请输入正确的合法手机号'))
      } else {
        callback()
      }
    }
    return {
      form: {
        code: '',
        receive_mobile: ''
      },
      rules: {
        receive_mobile: [
          { required: false, trigger: 'blur', validator: validateMobile }
        ],
        code: [
          { required: true, message: '请输入核销码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入6位核销码', trigger: 'blur' }
        ]
      },
      loading: false,
      isInit: true,
      resultObj: {}
    }
  },
  methods: {
    onCancelChange () {
      this.$emit('closeDialog', false)
      this.form = {}
      this.loading = false
      this.isInit = true
      this.resultObj = {}
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          getWinnerDetail(this.form).then(res => {
            this.resultObj = res.data.data
            this.isInit = false
            this.loading = false
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    activityVerifyChange () {
      this.loading = true
      activityVerify({
        id: this.resultObj.id,
        ...this.form
      }).then(res => {
        this.loading = false
        this.$message.success('核销成功')
        setTimeout(() => {
          this.onCancelChange()
        }, 2000)
      }).catch(err => {
        this.loading = false
      })
    }
  },
  computed: {
    showDialog () {
      return this.visible
    }
  }
}
</script>

<style scoped lang="scss">
.store {
  margin-bottom: 10px;
}
.gift-dialog .el-checkbox {
  display: inline;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-select .el-input {
  width: 130px;
}
.pager {
  margin-top: 20px;
}
.tab-group {
  .tab-btn {
    &.on {
      border-color: #e6a23c;
      background-color: #e6a23c;
      color: #fff;
    }
  }
}
</style>
