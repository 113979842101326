<template>
  <div>
    <el-row :gutter="30">
      <el-col :span="7">
        <el-input  class="input-b" placeholder="请输入订单ID" clearable v-model="params.bind_order">
          <template slot="prepend">订单ID</template>
        </el-input>
      </el-col>
      <el-col :span="12">
        领取时间
        <el-date-picker
          v-model="params.get_date"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="开始时间"
          align="right" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-col>
      <el-col :span="5">
        <el-button type="primary" icon="el-icon-search" size="medium" @click="getCardUserList">查询</el-button>
        <el-button type="primary" size="medium" @click="exportData" v-loading="exportloading">导出</el-button>
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
      </el-col>
    </el-row>
    <el-table :data="cardList" style="width: 100%" border height="600" v-loading="loading">
      <el-table-column prop="amp_dummy_id" label="Dummy ID"  width="100"></el-table-column>
<!--      <el-table-column prop="mobile" label="手机号"></el-table-column>-->
      <el-table-column label="领取时间"  width="160">
        <template slot-scope="scope">
          <span v-if="'0' != scope.row.get_date">{{scope.row.get_date|datetime('yyyy-MM-dd hh:mm:ss')}}</span>
          <span v-else>老数据</span>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="卡券code"  width="120"></el-table-column>
      <el-table-column prop="password_code" label="口令卡code"  width="120"></el-table-column>
      <el-table-column prop="receive_user" label="领取人群"  width="120"></el-table-column>
      <el-table-column prop="source_type" label="来源类型"  width="80"></el-table-column>
      <el-table-column prop="scan_time" label="核销信息" width="200">
        <template slot-scope="scope" v-if="scope.row.scan_time !='' ">
          <div>{{scope.row.scan_time}}</div>
          <div>{{scope.row.scan_shop_info.name}}</div>
<!--            <el-descriptions-item label="核销时间">{{scope.row.scan_time}}</el-descriptions-item>-->
<!--            <el-descriptions-item label="核销门店">{{scope.row.scan_shop_info.name}}</el-descriptions-item>-->
<!--            <el-descriptions-item label="shop_code">{{scope.row.scan_shop_info.shop_code}}</el-descriptions-item>-->
<!--            <el-descriptions-item label="联系地址">{{scope.row.scan_shop_info.store_address}}</el-descriptions-item>-->
        </template>
      </el-table-column>
      <el-table-column prop="source" label="领取渠道"  width="100" >
        <template slot-scope="scope">
          <span  v-if="checkTag('jd',scope.row)" >京东到家</span>
          <span   v-if="checkTag('tb',scope.row)" >淘宝</span>
          <span  v-if="checkTag('mt',scope.row)" >美团闪购</span>
          <span  v-if="checkTag('wxapp',scope.row)" >微信小程序</span>
          <span  v-if="checkTag('dy',scope.row)" >抖音</span>
          <span  v-if="checkTag('jdzy',scope.row)" >京东自营</span>
          <span  v-if="checkTag('eleme',scope.row)" >饿了么</span>
          <span  v-if="checkTag('amap',scope.row)" >高德</span>
          <span  v-if="checkTag('qyg',scope.row)" >企业购小程序</span>
          <span  v-if="checkTag('pos',scope.row)" >线下单</span>
          <span  v-if="!scope.row.get_outer_str || scope.row.get_outer_str === ''" >微信小程序</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_id" label="订单相关信息">
        <template slot-scope="scope">
          <div>
            <span v-if="! scope.row.get_outer_str || scope.row.get_outer_str ===''">领取订单：<router-link :to="{ path: matchInternalRoute('/order/entitytrade/tradenormalorders/tradenormalorders_detail'), query: { orderId: scope.row.bind_order }}">{{scope.row.bind_order}}</router-link></span>
            <span v-else>领取订单：
              <el-popover
              placement="left"
              width="500"
              trigger="click">
                <el-card class="box-card" shadow="always" style="margin: -15px">
                <div slot="header" class="clearfix">
                  <span>查看渠道订单</span>
                </div>
                <div>
                    <el-form ref="form" :model="form" label-width="120px" size="mini" label-position="left" >
                      <el-form-item  class="order_line" label="订单号：">
                        <b>{{ orderData.order_id }}</b>
                      </el-form-item>
                    <el-form-item class="order_line" label="购买渠道：">
                        <b>{{ orderData.source }}</b>
                      </el-form-item>
                    <el-form-item  class="order_line" label="订单金额：">
                        <span>¥{{ orderData.amount }}</span>
                      </el-form-item>
                    <el-form-item  class="order_line" label="下单时间：">
                        <span>{{ orderData.order_create_time }}</span>
                      </el-form-item>
                    <el-form-item  class="order_line" label="出库时间：">
                        <span>{{ orderData.issue_time }}</span>
                      </el-form-item>
<!--                  <el-form-item  class="order_line" label="物流方式：">-->
<!--                        <span>{{ orderData.logistics_mode }}</span>-->
<!--                      </el-form-item>-->
                    <el-form-item  class="order_line" label="门店AppleID：">
                        <b>{{ orderData.appleid }}</b>
                      </el-form-item>
                  <el-form-item  class="order_line" label="商品信息：">
                      <div v-for="(row,key) in orderData.item " >
                          <el-row>
                            <el-col :span="18" style="font-size: 12px">{{ row.item_name }}</el-col>
                            <el-col :span="6"><i  style="margin-left: 10px" class="el-icon-close"></i>{{ row.num }}</el-col>
                          </el-row>
                                </div>
                      </el-form-item>

                </el-form>
                </div>
              </el-card>
                <span slot="reference" @click="setOrderData(scope.row)">   <el-link type="success">{{ scope.row.bind_order }}</el-link></span>
            </el-popover>
            </span>
          </div>
          <div>
            使用订单：<router-link  :to="{ path: matchInternalRoute('/order/entitytrade/tradenormalorders/tradenormalorders_detail'), query: { orderId: scope.row.use_order }}">  <el-link type="warning">{{scope.row.use_order}}</el-link></router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="卡券所属经销商" >
        <template slot-scope="scope">
          <span>{{ scope.row.dealer_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="经销商HQID" >
        <template slot-scope="scope">
          <span>{{ scope.row.dealer_hqid }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="卡券所属分销商VAD" >
        <template slot-scope="scope">
          <span>{{ scope.row.distributor_vad }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="source" label="分销商HQID" >
        <template slot-scope="scope">
          <span>{{ scope.row.distributor_hqid }}</span>
        </template>
      </el-table-column>


    </el-table>
    <el-pagination
      background
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page.sync="params.page"
      :page-sizes="[10,20,50]"
      :total="total_count"
      :page-size="params.pageSize">
    </el-pagination>
  </div>
</template>

<script>
import {getCardUserList } from '../../../../api/cardticket'
import { mapGetters } from 'vuex'

export default {
  props: ['getStatus'],
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start,end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start,end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start,end]);
          }
        }]
      },
      prefix: '',
      loading: false,
      exportloading: false,
      total_count: 0,
      form: {},
      orderData: {
        'order_id': '',
        'source': '',
        'amount': '',
        'order_create_time': '',
        'issue_time': '',
        // 'logistics_mode': '',
        'appleid': '',
        'item': {}
      },
      cardList: [],
      params: {
        card_id: 0,
        page: 1,
        pageSize: 20,
        bind_order: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'login_type'
    ])
  },
  methods: {
    setOrderData(data) {
      let row = data.custom_order_info.response.order[0]
      this.orderData.order_id = row.bsOrderCode
      this.orderData.source = this.getSource(data.get_outer_str)
      this.orderData.amount = row.totalActual
      this.orderData.order_create_time = row.platformCreateTime
      this.orderData.issue_time = row.lastOutboundTime
      // this.orderData.logistics_mode = '同城速递'
      let extProps = JSON.parse(row.extProps)
      this.orderData.appleid = extProps.appleId
      this.orderData.item = []
      row.orderLineCommand.forEach((item, index) => {
        let newData = []
        newData = {
          item_name: item.skuName,
          num: item.requestedQty
        }
        this.orderData.item.push(newData)
      })
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getCardUserList()
    },
    exportData () {
      this.exportloading = true
      this.params.export = 1
      getCardUserList(this.params).then(res => {
        this.exportloading = false
        this.resetParams()
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (res.data.data.url) {
          this.downloadUrl = res.data.data.url
          this.downloadName = res.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch( error => {
        this.exportloading = false
        this.resetParams()
      })
    },
    resetParams() {
      this.params = {
        card_id: this.$route.query.cardId,
        page: 1,
        pageSize: 20,
        bind_order: ''
      }
    },
    checkTag(source,row){
      if (row.get_outer_str && row.get_outer_str === source) {
        return true
      }
      return false
    },
    getSource(source) {
      let source_msg = ''
      switch (source) {
        case 'jd':
          source_msg = '京东到家'
          break
        case 'tb':
          source_msg = '淘宝'
          break
        case 'mt':
          source_msg = '美团闪购'
          break
        case 'wxapp':
          source_msg = '微信小程序'
          break
        case 'dy':
          source_msg = '抖音'
          break
        case 'jdzy':
          source_msg = '京东自营'
          break
        case 'eleme':
          source_msg = '饿了么'
          break
        case 'amap':
          source_msg = '高德'
          break
        case 'qyg':
          source_msg = '企业购小程序'
          break
        case 'pos':
          source_msg = '线下单'
          break

      }
      return source_msg
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getCardUserList()
    },
    getCardUserList() {
      this.loading = true
      getCardUserList(this.params).then(res => {
        if (res.data.data.list.length > 0) {
          this.cardList = res.data.data.list
          this.total_count = res.data.data.count
          this.loading = false
        } else {
          this.cardList = []
          this.loading = false
        }
      })
      .catch(error => {
        this.loading = false
      })
    }
  },
  watch: {
    getStatus(val) {
      if (val) {
        this.getCardUserList()
      }
    }
  },
  mounted() {
    switch (this.login_type) {
      case 'disti': // 代理端
        this.prefix = '/distiadmin'
        break;
      case 'dealer': // 经销商端
        this.prefix = '/dealeradmin'
        break;
      case 'distributor': // 店铺端
        this.prefix = '/shopadmin';
        break;
      default: // 平台  包括admin  staff
        this.prefix = '';
    }
    if (this.$route.query.cardId) {
      this.params.card_id = this.$route.query.cardId
    }
    this.getCardUserList()
  }
}
</script>
<style scoped lang="scss">
.order_line{
  margin-bottom: 10px;
}
</style>
