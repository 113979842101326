<template>
  <div>
    <el-row class="filter-header" :gutter="20">
      <el-col>
        <el-switch
          v-model="isAutoAudit"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="系统自动审批"
          inactive-text="人工审批"
          @change="isAutoAuditChange"
        />
        <el-select v-model="params.applyForm" style="padding-left:10px;" placeholder="申请来源" clearable @change="searchChange">
          <el-option
            v-for="item in optionsApplyForm"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
        </el-select>
        <el-select v-model="params.userStatus" style="padding-left:10px;" placeholder="注销状态" clearable @change="searchChange">
          <el-option
            v-for="item in optionsUserStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value" />
        </el-select>
        <el-input class="el_input" v-model="params.user_id" @change="searchChange" size="medium" clearable placeholder="请输入用户ID" ></el-input>
        <el-date-picker
          v-model="create_time"
          type="datetimerange"
          start-placeholder="开始日期时间"
          ange-separator="至"
          end-placeholder="结束日期时间"
          value-format="timestamp"
          align="left"
          @change="dateChange"
          placeholder="选择日期时间范围"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
        <el-button type="primary" size="medium" style="margin-left:5px;" @click="applyBatchAgree">批量通过</el-button>
        <el-button type="primary" size="medium" style="margin-left:5px;" @click="applyBatchRefuse">批量拒绝</el-button>
      </el-col>
      <el-col>
        <el-popover
          placement="bottom-start"
          trigger="hover"
          style="padding-right: 10px"
          content="*步骤：1.点击导入，上传用户信息——>2点击查询导入信息，查看导入数据——>3点击注销导入用户信息，注销完成">
          <i class="el-icon-question" slot="reference"/>
        </el-popover>
        <ImportDialog
          button-name="导入"
          button-type="primary"
          icon="el-icon-circle-plus"
          file-name="导入用户注销文件模版"
          file-type="upload_member_apply_delete"
          @handleClose="uploadClose"
        />
        <el-button type="primary" size="medium" icon="el-icon-search" style="margin-left:5px;" plain @click="searchUploadData">查询导入信息</el-button>
        <el-button type="danger" size="medium" icon="el-icon-delete" style="margin-left:5px;" @click="batchRefuseUploadApply">注销导入用户信息</el-button>
      </el-col>
    </el-row>
    <el-tabs v-model="params.applyStatus" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="all" />
      <el-tab-pane label="未审批" name="wait" />
      <el-tab-pane label="已审批" name="over" />
      <div>
        <el-table v-loading="loading" :data="data" row-key="record_id" @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center" label="全选" />
          <el-table-column prop="user_id" label="用户ID" />
          <el-table-column prop="apply_date" label="申请时间" />
          <el-table-column prop="user_status" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.user_status === 'wait'" type="warning">用户存在</el-tag>
              <el-tag v-else-if="scope.row.user_status === 'success'" type="success">注销成功</el-tag>
              <el-tag v-else-if="scope.row.user_status === 'error'" type="danger">注销失败</el-tag>
              <el-tag v-else>未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="apply_from" label="来源">
            <template slot-scope="scope">
              <span v-if="scope.row.apply_from === 'user_apply'">小程序</span>
              <span v-else-if="scope.row.apply_from === 'admin_upload'">后台导入</span>
              <span v-else>未知</span>
            </template>
          </el-table-column>
          <el-table-column prop="audit_date" label="操作时间">
            <template slot-scope="scope">
              <span v-if="scope.row.audit_status !== 'wait' || scope.row.user_status !== 'wait'">{{ scope.row.audit_date }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="audit_operator_account" label="操作账户">
            <template slot-scope="scope">
              <span v-if="scope.row.audit_status === 'agree' && scope.row.audit_user === '-1'">自动审批</span>
              <span v-else-if="scope.row.audit_status !== 'wait'">{{ scope.row.audit_operator_account }}</span>
              <span v-else-if="scope.row.record_status === 'cancel'">用户</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注">
            <template slot-scope="scope">
              <span v-if="scope.row.audit_status === 'refuse'">{{ scope.row.remark }}</span>
              <span v-else-if="scope.row.record_status === 'cancel'">用户取消</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button v-if="scope.row.audit_status !== 'wait' || scope.row.user_status !== 'wait'" type="text" @click="handleRowDelete(scope.row.record_id)">删除</el-button>
              <el-button v-if="scope.row.apply_from === 'user_apply' && scope.row.audit_status === 'wait' && scope.row.user_status === 'wait'" type="text" @click="handleRowApplyAgree(scope.row.record_id)">审核通过</el-button>
              <el-button v-if="scope.row.apply_from === 'user_apply' && scope.row.audit_status === 'wait' && scope.row.user_status === 'wait'" type="text" @click="handleRowApplyRefuse(scope.row.record_id)">审核拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="content-center content-padded"
          background
          :current-page="params.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-tabs>
  </div>
</template>

<script>
import ImportDialog from '@/components/importDialog'
import { getDeleteApplyRecord, getIsAutoAudit, setIsAutoAudit, agreeApply, refuseApply, batchAgreeApply, batchRefuseApply, deleteApplyRecord, batchAgreeUploadApply } from "@/api/member"

export default {
  name: "MemberApplyDelete",
  components: {
    ImportDialog
  },
  data() {
    return {
      isAutoAudit: false,
      params: {
        page: 1,
        pageSize: 20,
        applyForm: '',
        applyStatus: 'all',
        userStatus: '',
        user_id: '',
        start_apply_time: '',
        end_apply_time: ''
      },
      optionsApplyForm: [
        { label: '小程序', value: 'user_apply' },
        { label: '后台导入', value: 'admin_upload' }
      ],
      optionsUserStatus: [
        { label: '用户存在', value: 'wait' },
        { label: '注销成功', value: 'success' },
        { label: '注销失败', value: 'error' }
      ],
      loading: false,
      data: [],
      total_count: 0,
      record_ids: [],
      create_time: ''
    }
  },
  mounted() {
    this.getIsAutoAudit()
    this.getDeleteApplyRecord()
  },
  methods: {
    getIsAutoAudit() {
      getIsAutoAudit().then(res => {
        this.isAutoAudit = res.data.data.status
      })
    },
    getDeleteApplyRecord(isSearchAdminUpload = false) {
      this.loading = true
      const data = {...this.params}
      if (isSearchAdminUpload) {
        data.lastSearchAdminUpload = 1
      }
      getDeleteApplyRecord(data).then(res => {
        this.data = res.data.data.list
        this.total_count = res.data.data.total_count
        if (res.data.data.lastAdminUploadNum !== undefined) {
          this.$message.success('已为您导入' + res.data.data.lastAdminUploadNum + '条数据')
        }
        this.loading = false
      })
    },
    searchChange() {
      this.params.page = 1
      this.params.pageSize = 20
      this.getDeleteApplyRecord()
    },
    isAutoAuditChange(val) {
      this.isAutoAudit = !val
      let message = '此操作将开启人工审核，是否继续？'
      if (val) {
        message = '此操作将开启系统自动审核，是否继续？'
      }
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setIsAutoAudit({
          isAutoAudit: val
        }).then(res => {
          this.isAutoAudit = res.data.data.status
        })
      })
    },
    applyBatchAgree() {
      if (this.record_ids.length <= 0) {
        this.$message.error('未选中申请记录')
        return
      }
      this.loading = true
      this.$confirm('此操作将批量通过已选中用户的注销申请，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchAgreeApply({ record_id: this.record_ids }).then(res => {
          this.$message.success('批量注销用户成功')
          this.getDeleteApplyRecord()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },
    applyBatchRefuse() {
      if (this.record_ids.length <= 0) {
        this.$message.error('未选中申请记录')
        return
      }
      this.loading = true
      this.$prompt('请统一填写拒绝已选中用户注销的原因。', '提示', {
        showInput: true,
        inputType: 'textarea',
        inputValidator: value => {
          return !!value;
        },
        inputErrorMessage: '该原因将会同步至用户，请合理填写。',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        batchRefuseApply({ record_id: this.record_ids, remark: res.value }).then(res => {
          this.$message.success('已批量拒绝用户注销')
          this.getDeleteApplyRecord()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },
    batchRefuseUploadApply() {
      this.loading = true
      this.$confirm('此操作将批量注销导入的用户，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchAgreeUploadApply().then(res => {
          this.$message.success('批量注销用户成功')
          this.getDeleteApplyRecord()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },
    handleClick(tab, event) {
      this.params.page = 1
      this.params.pageSize = 20
      this.getDeleteApplyRecord()
    },
    searchUploadData() {
      this.params.page = 1
      this.params.pageSize = 20
      this.params.applyForm = 'admin_upload'
      this.getDeleteApplyRecord(true)
    },
    uploadClose() {
      console.log('uploadClose')
    },
    handleSelectionChange(rows) {
      this.record_ids = []
      if (rows) {
        rows.forEach(row => {
          if (row) {
            this.record_ids.push(row.record_id)
          }
        })
      }
    },
    handleRowDelete(record_id) {
      this.loading = true
      this.$confirm('此操作将会删除用户注销记录，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteApplyRecord({ record_id: record_id }).then(res => {
          this.$message.success('删除成功')
          this.getDeleteApplyRecord()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },
    handleRowApplyAgree(record_id) {
      this.loading = true
      this.$confirm('此操作将通过用户注销申请，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        agreeApply({record_id: record_id}).then(res => {
          this.$message.success('注销用户成功')
          this.getDeleteApplyRecord()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },
    handleRowApplyRefuse(record_id) {
      this.loading = true
      this.$prompt('请填写拒绝用户注销的原因。', '提示', {
        showInput: true,
        inputType: 'textarea',
        inputValidator: value => {
          return !!value;
        },
        inputErrorMessage: '该原因将会同步至用户，请合理填写。',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        refuseApply({record_id: record_id, remark: res.value}).then(res => {
          this.$message.success('已拒绝用户注销')
          this.getDeleteApplyRecord()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.getDeleteApplyRecord()
    },
    handleSizeChange (val) {
      this.params.pageSize = val
      this.getDeleteApplyRecord()
    },
    dateChange(val) {
      if (val && val.length > 0) {
        this.params.start_apply_time = val[0] / 1000;
        this.params.end_apply_time = val[1] / 1000;
      } else {
        this.params.time_start_begin = ''
        this.params.time_start_end = ''
      }
      this.searchChange()
    },
  }
}
</script>

<style scoped lang="scss">
.el_input{
  width: 200px;
}

</style>
