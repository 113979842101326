<template>
  <div>
    <div v-if="
        $route.path.indexOf('_detail') === -1 &&
        $route.path.indexOf('_editor') === -1
      ">

      <div class="content-bottom-padded">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          @click="addCoupon"
          v-if="login_type === 'disti'"
          size="mini"
        >创建优惠券</el-button>
        <el-select
          clearable
          v-model="fetchParams.card_type"
          placeholder="请选择卡券类型"
          @change="searchCard"
          size="mini"
        >
          <el-option
            label="抵扣券"
            value="cash"
          ></el-option>
          <el-option
            label="兑换券"
            value="gift"
          ></el-option>
          <el-option
            label="门店券"
            value="distributor"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-model="use_scan"
          placeholder="请选择兑换券核销方式"
          @change="searchCard"
          size="mini"
        >
          <el-option
            label="线下核销"
            value="external_offline"
          ></el-option>
          <el-option
            label="本地系统核销"
            value="local_system"
          ></el-option>
          <el-option
            label="对接第三方核销"
            value="third_system"
          ></el-option>
          <el-option
            label="第三方平台兑换"
            value="third_change"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-model="receive"
          placeholder="请选择领取方式"
          @change="searchCard"
          size="mini">
          <el-option
            label="前台直接领取"
            value="true"
          ></el-option>
          <el-option
            label="口令卡领取"
            value="password"
          ></el-option>
          <el-option
            label="条件领取"
            value="condition"
          ></el-option>
          <el-option
            label="导购领取"
            value="share"
          ></el-option>
        </el-select>
        <!--        <el-button slot="append" icon="el-icon-search" @click=""></el-button>-->
        <el-button type="primary" @click="exportData" size="mini">导出</el-button>
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
        <el-button type="primary" @click="showDealerCard" size="mini">导出经销商优惠券</el-button>

      </div>

      <el-card>
        <el-table
          :data="cardList"
          @filter-change="filterTag"
          v-loading="loading"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            prop="card_id"
            label="卡券ID"
            width="80px"
          ></el-table-column>
          <el-table-column
            prop="card_type"
            column-key="type"
            label="卡券类型"
            width="140"
          >
            <template slot-scope="scope">
              <el-tag
                :type="
                  scope.row.card_type === 'discount'
                    ? 'primary'
                    : scope.row.card_type === 'cash'
                    ? 'danger'
                    : 'warning'
                "
                size="mini"
              >{{ scope.row.card_type | formatCardStr }}</el-tag>

              <div v-if="scope.row.card_type === 'gift'">
                <el-tag
                  size="mini"
                  type="warning"
                  v-if="scope.row.use_scan === 'external_offline'"
                >线下核销</el-tag>
                <el-tag
                  size="mini"
                  v-if="scope.row.use_scan === 'local_system'"
                >本地系统核销</el-tag>
                <el-tag
                  size="mini"
                  type="danger"
                  v-if="scope.row.use_scan === 'third_system'"
                >对接第三方核销</el-tag>
                <el-tag
                  size="mini"
                  type="success"
                  v-if="scope.row.use_scan === 'third_change'"
                >第三方平台兑换</el-tag>
              </div>

              <div v-if="scope.row.receive === 'password'">
                <el-tag size="mini" type="info">口令卡领取</el-tag>
              </div>
              <div v-if="scope.row.card_type === 'distributor'">
                <el-tag v-if="scope.row.shop_card_type === 'service'" size="mini" type="info">虚拟礼品</el-tag>
                <el-tag v-if="scope.row.shop_card_type === 'normal'" size="mini" type="info">实体礼品</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="title"
            label="卡券标题"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.title }}</div>
              <div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="副标题"
                  placement="right"
                >
                  <span class="subtitle">{{ scope.row.subtitle }}</span>
                </el-tooltip>
              </div>
              <div v-if="scope.row.card_type == 'gift'">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="兑换商品"
                  placement="right"
                >
                  <span class="subtitle">{{ scope.row.gift }}</span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <!--          <el-table-column prop="subtitle" label="卡券副标题"></el-table-column>-->
          <el-table-column
            width="280"
            label="卡券有效期"
          >
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <template v-if="scope.row.takeEffect">
                {{ scope.row.takeEffect }}
                <div v-if="scope.row.end_date > 0">
                  统一过期时间：{{ scope.row.end_date | datetime }}
                  <span style="color: red">{{
                      Date.parse(new Date()) > scope.row.end_date * 1000
                        ? "已过期"
                        : ""
                    }}</span>
                </div>
              </template>
              <template v-else>
                {{ scope.row.begin_time | datetime }}
                <template v-if="scope.row.end_time">~</template>
                {{ scope.row.end_time | datetime }}
                <span style="color: red">{{
                    Date.parse(new Date()) > scope.row.end_time * 1000
                      ? "已过期"
                      : ""
                  }}</span>
              </template>
            </template>
          </el-table-column>
          <!-- <el-table-column label="推送至微信">
            <template slot-scope="scope">
              <template v-if="scope.row.if_push_wechat">
                已推微信
              </template>
            </template>

          </el-table-column> -->
          <!-- <el-table-column prop="status" column-key="status" label="投放状态" :filters="statusFilters" filter-placement="bottom-end">
            <template slot-scope="scope">
              {{ scope.row.status | formatStatusStr }}
            </template>
          </el-table-column> -->
          <el-table-column
            width="120"
            prop="card_priority"
            label="优先级"
          ></el-table-column>
          <el-table-column
            width="120"
            label="可领取库存"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.quantity > scope.row.get_num">{{
                  scope.row.quantity - scope.row.get_num -  scope.row.lock_num
                }}</span>
              <span v-else>0</span>
              <span v-if="
                  login_type === 'disti' &&
                  scope.row.card_type !== 'gift' &&
                  scope.row.card_type !== 'distributor' &&
                  scope.row.use_scan !== 'external_offline'
                ">

                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="click"
                  v-model="scope.row.storePop"
                >
                  <div
                    ref="store"
                    class="store-pop"
                  >
                    <div class="store-content">
                      <el-radio-group
                        v-model="scope.row.operationType"
                        class="content-bottom-padded"
                      >
                        <el-radio label="increase">增加</el-radio>
                        <el-radio label="reduce">减少</el-radio>
                      </el-radio-group>
                      <el-row>
                        <el-col :span="22">
                          <el-input
                            placeholder="库存不能少于1"
                            v-model="scope.row.storeValue"
                          ></el-input>
                        </el-col>
                        <el-col
                          :span="2"
                          style="line-height: 36px"
                          class="content-center"
                        >份</el-col>
                      </el-row>
                    </div>
                    <el-row :gutter="10">
                      <el-col :span="12">
                        <el-button
                          type="primary"
                          style="width: 100%"
                          @click="
                            saveStore(scope.$index, scope.row.operationType)
                          "
                        >确定</el-button>
                      </el-col>
                      <el-col :span="12">
                        <el-button
                          style="width: 100%"
                          @click="scope.row.storePop = false"
                        >取消</el-button>
                      </el-col>
                    </el-row>
                  </div>
                  <el-button
                    slot="reference"
                    type="text"
                  ><i class="el-icon-edit"></i></el-button>
                </el-popover>
              </span>
              <!--              <span v-if="scope.row.card_type === 'gift' && scope.row.use_scan === 'external_offline'">-->
              <!--                <el-button @click="dialogCardCode(scope.row.card_id)" type="text"><i class="el-icon-search"></i></el-button>-->
              <!--              </span>-->

            </template>
          </el-table-column>
          <el-table-column
            width="80"
            prop="get_num"
            label="领取量"
          >
            <!-- <template v-if="scope.row.get_num">{{scope.row.get_num}}</template> -->
            <!-- <template>0</template> -->
          </el-table-column>
          <el-table-column width="80" prop="lock_num" label="锁定量">
            <!--             <template v-if="scope.row.lock_num">{{scope.row.lock_num}}</template>-->
            <!--             <template>0</template>-->

          </el-table-column>
          <el-table-column width="80" prop="use_num" label="使用量">

            <!-- <template v-if="scope.row.use_num">{{scope.row.use_num}}</template> -->
            <!-- <template>0</template> -->
          </el-table-column>
          <!--          <el-table-column width="80" prop="use_scan" label="核销方式">
            <template slot-scope="scope" v-if="scope.row.card_type === 'gift'">
              <span v-if="scope.row.use_scan == 'external_offline'">线下核销</span>
              <span v-if="scope.row.use_scan == 'local_system'">本地系统核销</span>
              <span v-if="scope.row.use_scan == 'third_system'">第三方系统核销</span>
            </template>
          </el-table-column>-->

          <el-table-column
            width="130"
            label="操作"
          >
            <template slot-scope="scope">
              <div class="operating-icons">
                <div>
                  <span>
                    <router-link :to="{
                        path: matchHidePage('detail1'),
                        query: {
                          chooseCardtype: scope.row.card_type,
                          cardId: scope.row.card_id,
                        },
                      }">查看</router-link>
                  </span>

                  <span v-if="login_type == 'disti'">
                    <router-link :to="{
                        path: matchHidePage('editor'),
                        query: {
                          chooseCardtype: scope.row.card_type,
                          cardId: scope.row.card_id,
                        },
                      }">编辑</router-link>
                  </span>

                  <el-button
                    type="text"
                    v-if="
                      scope.row.status != 'CARD_STATUS_DISPATCH' &&
                      ($store.getters.login_type == 'admin' ||
                        $store.getters.login_type == 'staff' ||
                        $store.getters.login_type == 'disti')
                    "
                    @click="deleteCard(scope.row.card_id, scope.$index)"
                  >删除</el-button>
                </div>
                <div>
                  <router-link :to="{
                      path: matchHidePage('detail'),
                      query: {
                        chooseCardtype: scope.row.card_type,
                        cardId: scope.row.card_id,
                        title: scope.row.title,
                      },
                    }">领取记录</router-link>
                </div>
                <div v-if="
                    scope.row.card_type === 'gift' &&
                   ( scope.row.use_scan === 'external_offline' || ( scope.row.use_scan === 'third_change' && scope.row.code_option==='admin'))
                  ">
                  <ImportDialog
                    buttonName="上传券码"
                    buttonType="text"
                    fileName="上传券码模版"
                    fileType="coupon_code_pool"
                    :relId="scope.row.card_id"
                  ></ImportDialog>

                  <el-button type="text" @click="exportDistributorCodePoolData(scope.row.card_id)">导出券码使用情况</el-button>
                </div>
                <div v-if="scope.row.card_type === 'distributor' && scope.row.shop_card_type === 'service'">
                  <ImportDialog
                    buttonName="上传经销商券码"
                    buttonType="text"
                    fileName="上传经销商券码模版"
                    fileType="distributor_coupon_code_pool"
                    :relId="scope.row.card_id"
                  ></ImportDialog>
                </div>
                <div v-if="scope.row.card_type === 'distributor' && scope.row.shop_card_type === 'service'">
                  <ImportDialog
                    buttonName="上传分销商券码"
                    buttonType="text"
                    fileName="上传分销商券码模版"
                    fileType="distributor_coupon_distributor_code_pool"
                    :relId="scope.row.card_id"
                  ></ImportDialog>
                </div>
                <div v-if="scope.row.card_type === 'distributor' && scope.row.shop_card_type === 'normal'">
                  <ImportDialog
                    buttonName="上传门店及库存"
                    buttonType="text"
                    fileName="上传门店及库存模版"
                    fileType="distributor_coupon_distributor_store"
                    :relId="scope.row.card_id"
                  ></ImportDialog>
                </div>
                <div v-if="scope.row.card_type === 'distributor' && scope.row.shop_card_type === 'service'">
                  <el-button type="text" @click="exportDistributorCodePoolData(scope.row.card_id)">导出券码使用情况</el-button>
                  <el-button type="text" @click="exportDistributorCodeStaticsData(scope.row.card_id)">导出券码统计</el-button>
                </div>
<!--                <div v-if="scope.row.card_type === 'distributor'">-->
<!--                  <ImportDialog-->
<!--                    buttonName="上传经销商店铺"-->
<!--                    buttonType="text"-->
<!--                    fileName="上传经销商店铺模版"-->
<!--                    fileType="distributor_coupon_dealer_pool"-->
<!--                    :relId="scope.row.card_id"-->
<!--                  ></ImportDialog>-->
<!--                </div>-->
                <div>

                  <div v-if="scope.row.receive === 'password'">
                    <ImportDistributor 
                      v-if="scope.row.password_create_type == 'manual' && scope.row.password_manual_type =='upload'" 
                      buttonName="导入口令卡" 
                      buttonType="text" 
                      fileName="导入口令卡"
                      fileType="upload_password_card_pool" 
                      :relId="scope.row.card_id" 
                      @handleClose="importDialogClose">
                    </ImportDistributor>

                    <el-button
                        type="text"
                        v-if="scope.row.password_create_type== 'system'"
                        @click="handlePasswordCardPoolCreate(scope.row.card_id)"
                    >生成口令卡</el-button>
                  </div>
                  
                  <el-button
                    type="text"
                    v-if="
                      scope.row.receive === 'password'
                    "
                    @click="handlePasswordCard(scope.row)"
                  >查看口令卡</el-button>
                </div>
              </div>
              <div v-if="
                    scope.row.rel_distributor_by === 'distributor'
                  ">
                <ImportDistributor
                  v-if=""
                  buttonName="上传适用店铺"
                  buttonType="text"
                  fileName="上传适用店铺模版"
                  fileType="coupon_distributor_pool"
                  :relId="scope.row.card_id"
                ></ImportDistributor>
              </div>

              <div v-if="(scope.row.card_type === 'distributor' && scope.row.shop_card_type === 'service') ||
                  (scope.row.card_type === 'gift' && (scope.row.use_scan === 'external_offline' ||
                  (scope.row.use_scan === 'third_change' && scope.row.code_option === 'admin')))"
                >
                <ImportDistributor
                  buttonName="上传作废券码"
                  buttonType="text"
                  fileName="上传作废券码"
                  fileType="coupon_code_pool_del"
                  :relId="scope.row.card_id"
                ></ImportDistributor>

                <el-button
                    v-if="(scope.row.takeEffect && Date.parse(new Date()) > scope.row.end_date * 1000) || Date.parse(new Date()) > scope.row.end_time * 1000"
                    type="text" 
                    @click="handleCancellation(scope.row.card_id)">
                  一键作废卡券
                </el-button>
              </div>

              <!-- <a v-if="!scope.row.ifpass" href="#" @click="sendoutShowAction(scope.row.card_id, scope.$index)">投放</a> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            :current-page.sync="fetchParams.currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="pagers.total"
            :page-size="pageSize"
          >
          </el-pagination>
        </div>
      </el-card>
      <el-dialog :title="Title" :visible.sync="dealerCard"  :before-close="handleDealerCancel">
        <template>
          <el-input :span="10" v-model="search_dealer_card"  placeholder="请输入卡券标题" style="width: 200px"/>
          <template>
            <el-select v-model="search_status" placeholder="请选择">
              <el-option
                v-for="item in card_status"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>
          <el-button type="primary" @click="doSearche()">搜索</el-button>
          <el-button type="primary" style="margin-left: 100px" @click="searchList(true)">确认导出</el-button>
          <el-table ref="singleTable"  @selection-change="handleSelectionCard" :data="dealerCardList"
                    style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="card_id" label="卡券ID"></el-table-column>
            <el-table-column prop="title" label="卡券标题"></el-table-column>
            <el-table-column prop="title" label="状态">
              <template slot-scope="scope">
                <template v-if="scope.row.takeEffect">
                  <div v-if="scope.row.end_date > 0">
                    <span style="color: red" v-if=" Date.parse(new Date()) > scope.row.end_date * 1000"> 已过期</span>
                    <span style="color: #333" v-else> 未过期</span>
                  </div>
                </template>
                <template v-else>
                  <span style="color: red" v-if="Date.parse(new Date()) > scope.row.end_time * 1000"> 已过期</span>
                  <span style="color: #333" v-else> 未过期</span>
                </template>
              </template>
            </el-table-column>

          </el-table>
          <div class="content-padded content-center">
            <el-pagination
              :current-page.sync="fetchDealerParams.currentPage"
              layout="total,sizes, prev, pager, next, jumper"
              @size-change="handleDealerSizeChange"
              @current-change="handleDealerChange"
              :total="dealer_pagers.total"
            >
            </el-pagination>
          </div>
        </template>

      </el-dialog>

      <el-dialog
        title="您可以通过以下方式投放"
        :visible.sync="sendoutVisible"
      >
        <div
          class="sendout-item"
          v-for="(item, index) in sedoutList"
          :class="{ checked: currSendout === index }"
          @click="chooseSendout(index)"
        >
          <div>{{ item.name }}</div>
          <div class="icon-checked">
            <i class="el-icon-circle-check"></i>
          </div>
        </div>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click.native="sendoutVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click.native="sendoutAction"
          >确定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="生成口令卡" :visible.sync="passwordCardPoolVisible" width="500px">
        <el-form label-width="100px" v-loading="surplusNumberLoading">
          <el-form-item label="生成数量">
            <el-input-number v-model="createPasswordCardPoolParams.number" :min="0" :max="5000"></el-input-number>
            <span style="font-size: 12px; color: #8c939d">剩余可生成数量：{{cardSurplusCreatePasswordNumber}}</span>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelCreatePasswordPool">取 消</el-button>
          <el-button type="primary" @click="passwordCardPoolCreate" :loading="createPasswordPoolLoading">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="查看口令卡" :visible.sync="passwordCardVisible" width="80%">
        <el-tabs v-model="passwordCardTab" type="border-card" @tab-click="handleClickPasswordTab">
          <el-tab-pane label="生成明细" name="getLog">
            <el-button type="primary" @click="exportPasswordLog" size="mini">导出</el-button>
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-table
              v-loading="passwordCardGetLogListLoading"
              :data="passwordCardGetLog"
              style="width: 100%">
              <el-table-column
                prop="password_code"
                label="口令卡code">
              </el-table-column>
              <el-table-column
                prop="password"
                :label="passwordCustomTitle || '口令卡密码'">
              </el-table-column>
              <el-table-column
                prop="receive_user"
                label="领取人群">
              </el-table-column>
              <el-table-column
                prop="is_used"
                label="领取状态">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.is_used == 1">已领取</el-tag>
                  <el-tag v-else type="warning">未领取</el-tag>
                </template>
              </el-table-column>
              <el-table-column v-for="(item,index) in customTitleList"
                :label="item.label"
                :key="index+'index'"
                >
                <template slot-scope="scope">
                 {{ filtersCustomList(item, scope.row) }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.is_used != 1 &&  selectedPasswordItem.password_manual_type == 'upload'" @click="passwordcardDellog(scope.row)" type="text">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="content-padded content-center">
              <el-pagination
                layout="total, prev, pager, next, jumper"
                @current-change="handlePasswordCardGetLogCurrentChange"
                :total="passwordGetLogTotal"
                :page-size="passwordGetLogParams.page_size"
              >
              </el-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane label="生成日志" name="createLog">
            <el-table
              v-loading="passwordCardOperatorLogListLoading"
              :data="passwordCardCreateLog"
              style="width: 100%">
              <el-table-column
                prop="id"
                label="ID"
                width="100px">
              </el-table-column>
              <el-table-column
                prop="created"
                label="生成时间">
                <template slot-scope="scope">
                  {{scope.row.created | datetime("yyyy-MM-dd hh:mm:ss")}}
                </template>
              </el-table-column>
              <el-table-column
                prop="operator_account"
                label="生成账号">
              </el-table-column>
              <el-table-column
                prop="number"
                label="生成数量">
              </el-table-column>
              <el-table-column
                prop=""
                label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="passwordCardCreateLogExport(scope.row.id)">下载</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="content-padded content-center">
              <el-pagination
                layout="total, prev, pager, next, jumper"
                @current-change="handlePasswordCardCreateLogCurrentChange"
                :total="passwordCreateLogTotal"
                :page-size="passwordGetLogParams.page_size"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div slot="footer" class="dialog-footer">
          <el-button @click="closePasswordCard">关闭</el-button>
        </div>
      </el-dialog>


      <!--      <el-dialog
        title="提示"
        :visible.sync="uploadedCardCodeVisible"
        width="30%">
        <span>这是一段信息</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploadedCardCodeVisible = false">关 闭</el-button>
        </span>
      </el-dialog>-->
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import {
  getCardList,
  getQRcode,
  removeCard,
  updateStore,
  pushToWechat,
  createPasswordCardPool,
  passwordCardLog,
  passwordCardOperatorCreateLog,
  passwordCardOperatorCreateLogExport,
  getSurplusCreatePasswordNumber,
  exportDistributorCodePool,
  exportDistributorCodeStatics,
  discountcardPasswordcardDellog,
  discountcardCodepoolFail
} from '../../../api/cardticket'
import util from '../../../common/js/util'
import ImportDialog from '@/components/importDialog'
import ImportDistributor from '@/components/importDistributor'

export default {
  provide () {
    return {
      refresh: this.getCardList
    }
  },
  data () {
    return {
      card_status: [
        {
          label: '全部',
          value: '2'
        },
        {
          label: '已过期',
          value: '0'
        },
        {
          label: '未过期',
          value: '1'
        }

      ],
      search_dealer_card: '',
      search_status: '',
      Title: '',
      dealerCard: false,
      dealerCardList: [],
      exportCard: [],
      createPasswordPoolLoading: false,
      surplusNumberLoading: false,
      cardSurplusCreatePasswordNumber: 0,
      passwordCardGetLogListLoading: false,
      passwordCardOperatorLogListLoading: false,
      passwordCardVisible: false,
      passwordCardPoolVisible: false,
      passwordCardGetLog: [],
      customTitleList: [],
      passwordCustomTitle: '',
      passwordCardCreateLog: [],
      passwordCardTab: 'getLog',
      passwordGetLogTotal: 0,
      passwordCreateLogTotal: 0,
      passwordGetLogParams: {
        page: 1,
        page_size: 20,
      },
      passwordCreateLogParams: {
        page: 1,
        page_size: 20,
      },
      createPasswordCardPoolParams: {
        card_id: 0,
        number: 0,
      },
      selectedPasswordItem: {},
      selectedPasswordCardId: 0,
      loading: false,
      sendoutVisible: false,
      uploadedCardCodeVisible: false,
      currSendout: 1,
      checkedType: {},
      cardList: [],
      operator_type: '',
      pagers: {
        total: 0
      },
      dealer_pagers: {
        total: 0
      },
      use_scan: '',
      receive: '',
      typeId: -1,
      pageSize: 10,
      currentPage: 1,
      sedoutList: [
        // { name: '直接群发', id: 1 },
        // { name: '嵌入图文消息', id: 2 },
        { name: '下载二维码', id: 3 }
      ],
      couponList: [
        // { type: 'discount', name: '折扣券', desc: '可为用户提供消费折扣支持优惠抵扣快速买单' },
        {
          type: 'cash',
          name: '抵扣券',
          desc: '可为用户提供抵扣现金服务。可设置成为“满*元，减*元”，支持优惠抵扣快速买单'
        },
        { type: 'gift', name: '兑换券', desc: '可为用户提供消费送赠品服务' }
        // { type: 'service', name: '福利券', desc: '支付金额优惠之外的服务' },
        // { type: 'money', name: '现金券', desc: '现金券' },
      ],
      params: {},
      typeFilters: [
        // {
        //  text: '折扣券',
        //  value: 'discount'
        // },
        {
          text: '抵扣券',
          value: 'cash'
        },
        // {
        //   text: '福利券',
        //   value: 'service'
        // },
        {
          text: '兑换券',
          value: 'gift'
        }
        // {
        //   text: '现金券',
        //   value: 'money'
        // }
      ],
      statusFilters: [
        {
          text: '待审核',
          value: 'CARD_STATUS_NOT_VERIFY'
        },
        {
          text: '待投放',
          value: 'CARD_STATUS_VERIFY_OK'
        },
        {
          text: '已投放',
          value: 'CARD_STATUS_DISPATCH'
        },
        {
          text: '审核失败',
          value: 'CARD_STATUS_VERIFY_FAIL'
        }
      ],
      fetchParams: {
        currentPage: 1,
        card_type: '',
        status: '',
        pageSize: 10
      },
      fetchDealerParams: {
        currentPage: 1,
        card_type: '',
        status: '',
        pageSize: 10
      },
      multipleSelection: []
    }
  },
  filters: {
    formatStatusStr (str) {
      switch (str) {
        case 'CARD_STATUS_NOT_VERIFY':
          str = '待审核'
          break
        case 'CARD_STATUS_VERIFY_OK':
          str = '待投放'
          break
        case 'CARD_STATUS_DISPATCH':
          str = '已投放'
          break
        case 'CARD_STATUS_VERIFY_FAIL':
          str = '审核失败'
          break
      }
      return str
    }
  },
  components: { ImportDialog, ImportDistributor },
  computed: {
    ...mapGetters(['wheight', 'login_type'])
  },
  methods: {
    doSearche(){
      this.fetchDealerParams.currentPage = 1
      this.searchList(false)
    },
    searchList(is_export) {
      let params = {
        rel_distributor_by: 'dealer',
        card_type: 'cash',
        use_bound: [1, 2, 3, 4],
        status: this.fetchDealerParams.status,
        page_no: this.fetchDealerParams.currentPage,
        page_size: this.fetchDealerParams.pageSize,
        store_self: this.fetchDealerParams.store_self,
        use_scan: this.use_scan,
        receive: this.receive,
      }
      if (is_export) {
        params.is_export ='dealer'
        if (this.exportCard.length === 0) {
          let export_card_push = []
          this.dealerCardList.forEach(coupon => {
            export_card_push.push(coupon.card_id)
          })
          params.export_card = export_card_push
        } else {
          params.export_card = this.exportCard
        }
      }
      if (this.search_status && this.search_status !== '2') {
        params.not_exp = this.search_status
      }
      if(this.search_dealer_card){
        params.title= this.search_dealer_card
      }
      getCardList(params)
        .then((res) => {
          if (res.data.data.list.length > 0) {
            this.dealerCardList = res.data.data.list
            this.dealer_pagers.total = res.data.data.pagers.total
            this.loading = false
          } else {
            this.dealerCardList = []
            this.dealer_pagers.total = 0
            this.loading = false
          }
        })
        .catch((error) => {
          this.loading = false
        })
      console.log(is_export);
      if(is_export) {
        this.$message({
          type: 'success',
          message: '已加入执行队列，请在设置-导出列表中下载'
        })
        this.dealerCard = false
      }
    },
    showDealerCard(){
      this.Title = '经销商优惠券'
      this.search_dealer_card =''
      this.dealerCard = true
      this.loading = true
      var params = {
        card_type: 'cash',
        rel_distributor_by: 'dealer',
        use_bound: [1, 2, 3, 4],
        status: this.fetchDealerParams.status,
        page_no: this.fetchDealerParams.currentPage,
        page_size: this.fetchDealerParams.pageSize,
        store_self: this.fetchDealerParams.store_self,
        use_scan: this.use_scan,
        receive: this.receive
      }

      getCardList(params)
        .then((res) => {
          if (res.data.data.list.length > 0) {
            this.dealerCardList = res.data.data.list
            this.dealer_pagers.total = res.data.data.pagers.total
            this.loading = false
          } else {
            this.dealerCardList = []
            this.dealer_pagers.total = 0
            this.loading = false
          }
        })
        .catch((error) => {
          this.loading = false
        })
    },
    handleDealerCancel() {
      this.dealerCard = false
    },
    handleSelectionCard(val){
      val.forEach(coupon => {
        this.exportCard.push(coupon.card_id)
      })
      console.log(this.exportCard);

    },
    exportPasswordLog () {
      this.passwordCardGetLogListLoading = true
      let params = {
        card_id: this.selectedPasswordCardId,
        ...this.passwordGetLogParams,
        is_export: true
      }
      passwordCardLog(params).then(response => {
        this.passwordCardGetLogListLoading = false
        if (response.data.data.success == true) {
          this.$message.success('导出成功')
        } else {
          this.$message.error('导出失败')
        }

      }).catch(error => {
        this.passwordCardGetLogListLoading = false
      })
    },
    passwordCardCreateLogExport (id) {
      let params = {
        id: id
       }
      passwordCardOperatorCreateLogExport(params).then(response => {
        if (response.data.data.success == true) {
          this.$message.success('导出成功')
        } else {
          this.$message.error('导出失败')
        }
      })
    },
    closePasswordCard () {
      this.passwordCardVisible = false
    },
    handlePasswordCard (item) {
      this.passwordCardVisible = true
      this.selectedPasswordItem = item
      this.selectedPasswordCardId = item.card_id
      this.passwordCardTab = 'getLog'
      this.passwordGetLogParams = {page: 1,page_size: 20}
      this.passwordCardLog()
    },
    passwordCardLog () {
      this.passwordCardGetLogListLoading = true
      let params = {
        card_id: this.selectedPasswordCardId,
        ...this.passwordGetLogParams
      }
      passwordCardLog(params).then(response => {
        this.passwordCardGetLog = response.data.data.list
        this.passwordGetLogTotal = response.data.data.total_count
        this.passwordCardGetLogListLoading = false
        this.passwordCustomTitle = response.data.data.password_custom_title
        this.customTitleList = response.data.data.custom_title
      }).catch(error => {
        this.passwordCardGetLogListLoading = false
      })
    },
    passwordOperatorCreateLog () {
      this.passwordCardGetLogListLoading = true
      let params = {
        card_id: this.selectedPasswordCardId,
        ...this.passwordCreateLogParams
      }
      passwordCardOperatorCreateLog(params).then(response => {
        this.passwordCardCreateLog = response.data.data.list
        this.passwordCreateLogTotal = response.data.data.total_count
        this.passwordCardGetLogListLoading = false
      }).catch(error => {
        this.passwordCardGetLogListLoading = false
      })
    },
    handleClickPasswordTab () {
      if (this.passwordCardTab === 'getLog') {
        this.passwordCardLog()
      } else {
        this.passwordOperatorCreateLog();
      }
    },
    handlePasswordCardGetLogCurrentChange (val) {
      this.passwordGetLogParams.page = val
      this.passwordCardLog()
    },
    handlePasswordCardCreateLogCurrentChange (val) {
      this.passwordGetLogParams.page = val
      this.passwordOperatorCreateLog()
    },
    handlePasswordCardPoolCreate (card_id) {
      this.surplusNumberLoading = true
      this.passwordCardPoolVisible = true
      this.createPasswordCardPoolParams.card_id = card_id
      let params = {
        card_id: card_id
      }
      getSurplusCreatePasswordNumber(params).then(response => {
        this.surplusNumberLoading = false
        this.cardSurplusCreatePasswordNumber = response.data.data.surplus
      }).catch(error => {
        this.surplusNumberLoading = false
      })
    },
    passwordCardPoolCreate () {
      if (!this.createPasswordCardPoolParams.card_id) {
        this.$message.error('缺少卡券信息')
        return false
      }
      if (this.createPasswordCardPoolParams.number <= 0) {
        this.$message.error('生成数量必须大于0')
        return false
      }
      this.createPasswordPoolLoading = true
      let params = JSON.parse(JSON.stringify(this.createPasswordCardPoolParams))
      createPasswordCardPool(params).then(response => {
        if (response.data.data.success == true) {
          this.createPasswordPoolLoading = false
          this.$message.success('生成成功')
          this.cancelCreatePasswordPool()
        }
      }).catch(error => {
        this.createPasswordPoolLoading = false
      })
    },
    cancelCreatePasswordPool () {
      this.createPasswordCardPoolParams.card_id
        = this.createPasswordCardPoolParams.number
        = this.cardSurplusCreatePasswordNumber
        = 0;
      this.passwordCardPoolVisible = false
    },
    handleCurrentChange (val) {
      this.fetchParams.currentPage = val
      this.getCardList()
    },
    handleSizeChange (val) {
      this.fetchParams.pageSize = val
      this.getCardList()
    },
    handleDealerChange (val) {
      this.fetchDealerParams.currentPage = val
      this.searchList(false)
    },
    handleDealerSizeChange (val) {
      this.fetchDealerParams.pageSize = val
      this.searchList(false)
    },
    handleClick (tab, event) {},
    addCoupon () {
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    sendoutShowAction (id) {
      this.sendoutVisible = true
      this.typeId = id
      this.currSendout = 0
    },
    searchCard () {
      this.fetchParams.currentPage = 1
      this.getCardList()
    },
    sendoutAction () {
      if (this.currSendout == 0) {
        if (this.typeId) {
          getQRcode(this.typeId).then((res) => {
            var a = document.createElement('a')
            a.href = res.data.data.show_qrcode_url
            a.download = true
            a.click()
          })
        }
      }
      this.sendoutVisible = false
    },
    exportData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.getCardList(true)
      this.$message({
        type: 'success',
        message: '已加入执行队列，请在设置-导出列表中下载'
      })
      loading.close()
    },
    exportDistributorCodePoolData (id) {
      console.log(id)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      exportDistributorCodePool({id: id}).then(() => {
        this.$message({
          type: 'success',
          message: '已加入执行队列，请在设置-导出列表中下载'
        })
        loading.close()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '导出失败'
        })
        loading.close()
      })
    },
    exportDistributorCodeStaticsData (id) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      exportDistributorCodeStatics({id: id}).then(() => {
        this.$message({
          type: 'success',
          message: '已加入执行队列，请在设置-导出列表中下载'
        })
        loading.close()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '导出失败'
        })
        loading.close()
      })
    },
    chooseType (item, index) {
      this.i = index
      this.checkedType = item
    },
    deleteCard (id, index) {
      this.$confirm('确定要删除该卡券？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            removeCard({ card_id: id }).then((res) => {
              this.cardList.splice(index, 1)
            })
          }
          done()
        }
      })
    },
    chooseSendout (index) {
      this.currSendout = index
    },
    pullWechatCard () {
      this.$confirm('确定同步微信优惠券到本系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          pullWechatCard().then((response) => {
            this.$message({
              message: '同步成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    getCardList (isExport) {
      this.loading = true
      var params = {
        status: this.fetchParams.status,
        card_type: this.fetchParams.card_type,
        page_no: this.fetchParams.currentPage,
        page_size: this.fetchParams.pageSize,
        store_self: this.fetchParams.store_self,
        use_scan: this.use_scan,
        receive: this.receive,
      }
      if (isExport) {
        params.is_export = true
      }
      getCardList(params)
        .then((res) => {
          if (res.data.data.list.length > 0) {
            this.cardList = res.data.data.list
            this.pagers.total = res.data.data.pagers.total
            this.loading = false
          } else {
            this.cardList = []
            this.pagers.total = 0
            this.loading = false
          }
        })
        .catch((error) => {
          this.loading = false
        })
    },
    filterTag (value) {
      if (value.type) {
        this.fetchParams.card_type = value.type[0]
        this.getCardList()
      }
      if (value.status) {
        this.fetchParams.status = value.status
        this.getCardList()
      }
    },
    saveStore (index, operationType) {
      let reg = /^[1-9]\d*$/
      if (!reg.test(this.cardList[index].storeValue)) {
        this.$message({
          message: '请输入大于0的正整数',
          type: 'error'
        })
        return
      }
      if (operationType == 'reduce') {
        var quantity =
          Number(this.cardList[index].quantity) > this.cardList[index].get_num
            ? Number(this.cardList[index].quantity) -
            this.cardList[index].get_num
            : 0
        quantity =
          quantity > this.cardList[index].lock_num
            ? quantity - this.cardList[index].lock_num
            : 0
        if (this.cardList[index].storeValue > quantity) {
          this.$message({
            message: '减少数量不能大于可领取库存',
            type: 'error'
          })
          return
        }
      }
      var param = {
        card_id: this.cardList[index].card_id,
        type: this.cardList[index].operationType,
        quantity: this.cardList[index].storeValue
      }
      updateStore(param).then((response) => {
        this.getCardList()
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    pushWechatCard () {
      var selectCardId = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        selectCardId.push(this.multipleSelection[i].card_id)
      }
      var param = { card_ids: selectCardId }
      this.$confirm('确定同步选中的优惠券至微信么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        pushToWechat(param).then((res) => {
          if (res.data.data.status) {
            this.$message({
              message: '已经加入上传队列，请稍后查看状态',
              type: 'success',
              duration: 5 * 1000
            })
            // this.$refs.multipleTable.clearSelection();
          } else {
            this.$message({
              message: '推送出错',
              type: 'error'
            })
            return
          }
        })
      })
    },
    dialogCardCode (card_id) {
      this.uploadedCardCodeVisible = true
    },
    importDialogClose() {
      // this.recommendGoodsList()
    },
    passwordcardDellog(item) {
      let params = {
        id: item.id
      }
      discountcardPasswordcardDellog(params).then((res) => {
        this.passwordCardLog()
      })
    },
    filtersCustomList(item, row) {      
      if (row[item.column]) {
        return row[item.column]
      }
    },
    handleCancellation(card_id) {
      discountcardCodepoolFail({card_id}).then((res) => {
        this.getCardList()
      })
    }
  },
  mounted () {
    this.fetchParams.store_self = false
    if (
      this.system_mode === 'platform' &&
      store.getters.login_type !== 'distributor'
    ) {
      this.fetchParams.store_self = true
    }
    this.operator_type = store.getters.login_type
    this.getCardList()
  }
}
</script>
<style scoped lang="scss">
.el-icon-edit {
  cursor: pointer;
}
.coupon-list {
  margin-top: 10px;
}
.coupon-item {
  position: relative;
  height: 130px;
  padding: 25px 20px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #dadadd;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 19%;
  float: left;
  margin: 0.5%;
  &.coupon-add {
    padding-top: 20px;
    cursor: pointer;
    i {
      font-size: 40px;
    }
    :last-child {
      margin-top: 10px;
    }
  }
  .price {
    font-size: 24px;
  }
  .color-gray {
    color: #99a9bf;
  }
  .color-gray-deep {
    color: #8492a6;
  }
  .item-code {
    display: inline-block;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span.icon-triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 34px solid #20a0ff;
    border-left: 34px solid transparent;
    color: #fff;
  }
  label {
    position: absolute;
    top: -34px;
    right: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}
.item-bg {
  position: absolute;
  left: 0;
  bottom: -101%;
  width: 100%;
  height: 100%;
  background: rgba(241, 244, 246, 0.9);
  text-align: left;
  border-radius: 5px;
  padding-top: 8px;
  transition: all 0.4s ease;
  p {
    padding: 0 10px;
  }
  .item-operate {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    padding: 4px 0;
    text-align: center;
    cursor: pointer;
    a {
      color: #fff;
    }
  }
}
.coupon-item:hover {
  .item-bg {
    bottom: 0;
  }
}
.coupon-type-item {
  position: relative;
  padding: 30px 10px;
  border: 1px solid #ff5000;
  color: #ff5000;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  &:first-child {
    font-size: 18px;
  }
  .coupon-type-desc {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    padding: 10px 0;
    background: #ff5000;
    color: #fff;
    font-size: 18px;
    transition: all 0.4s ease;
    div:last-child {
      width: 70%;
      margin: 8px auto 0;
      font-size: 12px;
    }
  }
  &:hover {
    .coupon-type-desc {
      bottom: 0;
    }
  }
}

.coupon-type-item,
.sendout-item {
  overflow: hidden;
  .icon-checked {
    display: none;
    width: 100px;
    height: 25px;
    transform: rotate(35deg);
    position: absolute;
    top: 5px;
    right: -25px;
    font-size: 20px;
    color: #fff;
    background: #ff5000;
    i {
      transform: rotate(-35deg);
    }
  }
  &.checked {
    .icon-checked {
      display: block;
    }
  }

  &:hover .icon-checked {
    background: #fff;
    i {
      color: #ff5000;
    }
  }
}
.store-pop {
  padding: 20px 15px;
  .store-content {
    margin-bottom: 15px;
  }
}
.subtitle {
  color: #8c939d;
  margin-top: 3px;
  font-size: 10px;
}
</style>