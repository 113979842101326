<template>
  <div class="container">
    <el-form ref="form" :rules="rules" :model="form" label-width="110px">
      <div v-if="!form.card_id" class="content-center content-bottom-padded">
        <el-radio-group v-model="form.card_type" @change="handleTypeChange">
          <!-- <el-radio-button disabled label="service">福利券</el-radio-button>
          <el-radio-button disabled label="discount">折扣券</el-radio-button> -->
          <el-radio-button label="cash">抵扣券</el-radio-button>
          <el-radio-button label="gift">兑换券</el-radio-button>
          <el-radio-button label="distributor">门店券</el-radio-button>
        </el-radio-group>
      </div>
      <template v-if="form.card_type === 'cash'">
        <el-card shadow="never" header="基础信息">
          <el-form-item
            label="兑换商品名称"
            prop="gift"
            v-if="form.card_type === 'gift'"
          >
            <el-input
              :maxlength="inputValue.gift_max"
              placeholder="兑换商品名称"
              show-word-limit
              v-model="form.gift"
              @change="giftChange"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="折扣额度"
            prop="discount"
            v-if="form.card_type === 'discount'"
          >
            <el-input
              :disabled="!!form.card_id"
              v-model="form.discount"
              placeholder="只能是大于等于1,小于10的数字"
              max="10.0"
              min="1"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="减免金额"
            prop="reduce_cost"
            v-if="form.card_type === 'cash'"
          >
            <el-input
              type="number"
              :disabled="!!form.card_id"
              v-model="form.reduce_cost"
              placeholder="只能是大于0的数字"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="核销方式" v-if="form.card_type === 'gift'">
            <el-select v-model="form.use_scan" style="width: 100%" @change="useScanChange" :disabled="!!form.card_id">
              <el-option value="third_system" label="对接第三方核销"></el-option>
              <el-option
                value="external_offline"
                label="线下第三方核销"
              ></el-option>
              <el-option value="local_system" label="本地系统核销"></el-option>
              <el-option value="third_change" label="第三方平台兑换"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="兑换连接"
            v-if="form.use_scan === 'third_change'"
          >
            <el-input
              placeholder="请输入第三方兑换连接"
              show-word-limit
              :disabled="!!form.card_id"
              v-model="form.promotion_url"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="券码维护方式"
            v-if="form.use_scan === 'third_change'"
          >
            <el-radio-group v-model="form.code_option" :disabled="!!form.card_id">
              <el-radio-button label="admin"
              >上传券码</el-radio-button
              >
              <el-radio-button label="self">
                不上传券码
              </el-radio-button>
            </el-radio-group>
            <p class="frm-tips">*此选项将决定当前优惠券的券码是否可以在后台进行上传维护</p>
          </el-form-item>
          <el-form-item label="发放数量" prop="quantity">
            <el-input
              min="1"
              :disabled="!!form.card_id || (form.use_scan === 'external_offline' || (form.use_scan === 'third_change' && form.code_option==='admin'))"
              type="number"
              v-model="form.quantity"
              placeholder="只能是大于0的数字"
            ><template slot="append">份</template></el-input
            >
          </el-form-item>
          <el-form-item label="卡券标题" prop="title">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.title"
              placeholder="字数上限为12个汉字"
              @change="titleChange"
            ><template slot="append"
            >{{ inputValue.title_length }}/{{
                inputValue.title_max
              }}</template
            ></el-input
            >
            <p class="frm-tips">
              建议填写抵扣券“减免金额”及自定义内容，描述卡券提供的具体优惠
            </p>
          </el-form-item>
          <el-form-item label="卡券副标题" prop="subtitle">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.subtitle"
              type="textarea"
              :rows="5"
              show-word-limit
              maxlength="50"
              placeholder="字数上限为50个汉字"
              @change="subtitleChange"
            ></el-input>
          </el-form-item>

          <!--        <el-form-item label="优惠券标签" prop="card_label">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.card_label"
              placeholder="字数上限为9个汉字"
              show-word-limit
              maxlength="9"
              @change="cardLabelChange"
            ></el-input>
            <p class="frm-tips">建议填写“优惠活动”及自定义内容</p>
          </el-form-item>-->
          <el-row>
            <el-col :span="8">
              <el-form-item label="券LOGO">
                <div @click="handleImgPicker('logo_url')" class="upload-box">
                  <img v-if="form.logo_url" :src="form.logo_url" class="avatar" />
                  <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                </div>
                <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="使用条件" prop="useCondition">
            <el-radio-group
              :disabled="!!form.card_id"
              v-model="form.useCondition"
              @change="conditionChange"
            >
              <!-- <el-radio-button :label="1">不限制</el-radio-button> -->
              <el-radio-button :label="2" v-if="form.card_type !== 'gift'"
              >限制</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.card_type !== 'gift' && form.useCondition === 2"
          >
            <el-row>
              <el-col :span="11">
                <el-input
                  type="number"
                  min="0"
                  :disabled="!!(form.card_id || form.useCondition == 1)"
                  v-model="form.least_cost"
                >
                  <template slot="prepend">满</template>
                  <template slot="append">元可用</template>
                </el-input>
              </el-col>
              <el-col
                :span="1"
                v-if="form.card_type === 'discount'"
                style="text-align: center"
              >-</el-col
              >
              <el-col :span="11">
                <el-input
                  v-if="form.card_type === 'discount'"
                  :disabled="!!(form.card_id || form.useCondition == 1)"
                  v-model="form.most_cost"
                ><template slot="prepend">最高限额</template
                ><template slot="append">元</template></el-input
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="有效期" prop="date_type">
            <el-row>
              <el-radio-group v-model="form.date_type" @change="changeDatetime" :disabled="timeDisabled">
                <el-radio-button label="DATE_TYPE_FIX_TIME_RANGE"
                >按日期</el-radio-button
                >
                <el-radio-button label="DATE_TYPE_FIX_TERM">
                  按领取
                </el-radio-button>
              </el-radio-group>
            </el-row>
            <el-row v-if="form.date_type === 'DATE_TYPE_FIX_TIME_RANGE'">
              <el-date-picker
                @change="changeDatePicker"
                v-model="date_range"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                type="datetimerange"
                range-separator="至"
                value-format="timestamp"
                :picker-options="form.card_id ? '' : pickerOptions"
                :disabled="form.date_type == 'DATE_TYPE_FIX_TERM' || timeDisabled"
              ></el-date-picker>
            </el-row>
            <el-row v-else>
              <el-form-item label="领取后" style="width: 100%">
                <el-row>
                  <el-col :span="22">
                    <el-select
                      style="width: 100%; margin-bottom: 10px"
                      v-model="form.begin_time"
                      size="5"
                    >
                      <el-option
                        v-for="item in totalDays"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="2">生效</el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="有效天数" style="width: 100%">
                <el-input type='number' min='1' max='3650' v-model="form.days" @change="onChangeDays" placeholder="请输入有效天数"></el-input>
                <!-- <el-select
                  style="width: 100%; margin-bottom: 10px"
                  v-model="form.days"
                  size="5"
                >
                  <el-option
                    v-for="item in remainDays"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="统一过期时间" style="width: 100%">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.end_time"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  type="datetime"
                  range-separator="至"
                  value-format="timestamp"
                  :picker-options="pickerOptions"
                  :disabled="timeDisabled"
                ></el-date-picker>
              </el-form-item>
            </el-row>
          </el-form-item>



          <el-form-item label="卡券使用说明">
            <template>
              <vue-html5-editor :content="form.description.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
              <span class="tpl_item img" @click="addImgPreview" style="">
              <i class="iconfont icon-image"></i>图片
            </span>
            </template>
            <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>

            <!--          <div @click="handleImgPicker('description')" class="upload-box">-->
            <!--            <img v-if="form.description" :src="form.description" class="avatar" />-->
            <!--            <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>-->
            <!--          </div>-->
            <!--          <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>-->
          </el-form-item>

          <!--        <el-form-item label="卡券使用说明" prop="description">-->
          <!--          <el-input-->
          <!--            required-->
          <!--            type="textarea"-->
          <!--            :rows="12"-->
          <!--            resize="none"-->
          <!--            :maxlength="inputValue.description_max"-->
          <!--            show-word-limit-->
          <!--            v-model="form.description"-->
          <!--            placeholder="请填写使用本优惠券的注意事项"-->
          <!--            @change="descriptionChange"-->
          <!--          ></el-input>-->
          <!--        </el-form-item>-->
          <!--        -->


          <el-form-item label="优惠券优先级" prop="card_priority">
            <el-input
              required
              type="number"
              placeholder="请填写使用本优惠券的优先级"
              v-model="form.card_priority"
            ></el-input>
            <p class="frm-tips">数值越小，优先级越高</p>
          </el-form-item>
          <!--        <el-form-item label="优惠券模板ID" prop="card_code">
            <el-input
              :maxlength="255"
              placeholder="优惠券模板ID"
              v-model="form.card_code"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券规则ID" prop="card_rule_code">
            <el-input
              :maxlength="255"
              placeholder="优惠券规则ID"
              v-model="form.card_rule_code"
            ></el-input>
          </el-form-item>-->
        </el-card>
        <el-card shadow="never" header="适用规则">
          <el-form-item label="领取方式">
            <!--          <el-switch
                        disabled
                        v-model="form.receive"
                        active-color="#13ce66"
                        inactive-color="#d2d4db"
                      ></el-switch>-->
            <el-select v-model="form.receive" placeholder="请选择" :disabled="form.card_id != ''">
              <el-option key="true" label="前台直接领取" value="true"></el-option>
              <el-option key="password" label="口令卡领取" value="password"></el-option>
              <el-option key="condition" label="条件领取" value="condition"></el-option>
              <el-option key="share" label="导购领取" value="share"></el-option>
            </el-select>
          </el-form-item>
          <!--        <el-form-item label="核销方式" v-if="form.card_type === 'gift'">
                    <el-select v-model="form.use_scan" style="width: 100%">
                      <el-option
                        value="external_offline"
                        label="线下第三方核销"
                      ></el-option>
                      <el-option value="local_system" label="本地系统核销"></el-option>
                      <el-option value="third_system" label="对接第三方核销"></el-option>
                    </el-select>
                  </el-form-item>-->

          <PasswordForm :formValues="formPassword" :isDisabled="form.card_id != ''" :is-valid="form.receive == 'password' ? true : false" @changeForm="changeForm"></PasswordForm>       

          <el-form-item label="领券限制">
            <el-input type="number" v-model="form.get_limit"></el-input>
            <p class="frm-tips">每个用户领券上限，如不填，则默认为1。</p>
          </el-form-item>
          <el-form-item
            v-show="false"
            label="适用平台"
            v-if="!is_distributor && form.card_type == 'gift'"
          >
            <el-radio-group
              v-model="form.use_platform"
              :disabled="form.card_id != ''"
              @change="usePlatformChange"
            >
              <el-radio-button label="store" v-if="!is_distributor"
              >门店专用</el-radio-button
              >
              <el-radio-button label="mall" v-if="form.card_type != 'gift'"
              >线上商城专用</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="适用订单类型" prop="allow_order_receipt_type" v-show="form.card_type !== 'gift'">
            <el-radio-group v-model="form.allow_order_receipt_type">
              <el-radio-button label="all">不限</el-radio-button>
              <el-radio-button label="logistics">快递</el-radio-button>
              <el-radio-button label="ziti">自提</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="首单专属" prop="use_first_order" v-show="form.card_type !== 'gift'">
            <el-switch v-model="form.use_first_order"></el-switch>
          </el-form-item>
          <el-form-item label="是否同步企微" prop="sync_work_wechat">
            <el-switch v-model="form.sync_work_wechat"></el-switch>
          </el-form-item>

          <el-form-item
            label="核销场景"
            prop="use_scenes"
            v-if="false && !is_distributor && form.card_type == 'gift'"
          >
            <el-radio-group
              v-model="form.use_scenes"
              :disabled="form.card_id != ''"
            >
              <el-radio
                v-if="form.card_type != 'gift' && form.use_platform == 'mall'"
                label="ONLINE"
              >线上商城使用</el-radio
              >
              <el-radio
                v-if="form.card_type != 'gift' && form.use_platform == 'store'"
                label="QUICK"
              >快捷买单使用</el-radio
              >
              <el-radio label="SELF" v-if="form.use_platform == 'store'"
              >自助核销（到店使用）</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="仅总店商品可用"
            v-if="
            system_mode == 'platform' &&
            !is_distributor &&
            form.use_platform == 'mall'
          "
          >
            <el-switch
              v-model="form.store_self"
              :disabled="form.card_id != ''"
              active-color="#13ce66"
              inactive-color="#d2d4db"
            ></el-switch>
          </el-form-item>
          <el-form-item v-if="false && form.use_scenes == 'SELF'" label="验证码">
            <el-radio-group v-model="self_rcode" :disabled="form.card_id != ''">
              <el-radio label="1">启用验证码</el-radio>
              <el-radio label="0">不启用验证码</el-radio>
            </el-radio-group>
            <br />
            <el-input
              v-if="self_rcode === '1'"
              v-model="form.self_consume_code"
              placeholder="请输入验证码"
              maxlength="4"
              minlength="4"
            ></el-input>
          </el-form-item>
        </el-card>
        <el-card
          v-if="
          form.use_platform == 'mall' &&
          form.card_type != 'gift' &&
          form.store_self == false
        "
          header="商品"
          shadow="naver"
        >
          <div class="show-goods">
            <el-form-item label="适用商品">
              <el-select
                v-model="form.use_all_items"
                style="width: 100%"
                @change="itemTypeChange"
              >
                <el-option label="全部商品适用" value="true"></el-option>
                <el-option label="指定商品适用" value="false"></el-option>
                <!-- <el-option label="指定分类适用" value="category"></el-option>
                <el-option label="指定商品标签适用" value="tag"></el-option>
                <el-option label="指定品牌适用" value="brand"></el-option>
                <el-option label="不指定商品" value="none"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="展示前" class="item_show_quantity" v-if="form.use_all_items == 'false'">
              <el-input
                placeholder="展示数量，不填默认全部"
                v-model="form.item_show_quantity"
                @input="item_show_change"
              ></el-input>
              件商品
              <span class="el-icon-warning-outline" />拖拽商品进行排序
            </el-form-item>
          </div>
          <SkuSelector
            v-if="!zdItemHidden"
            @change="getItems"
            :data="relItems"
            dragger-class="move"
            :is-show-num="true"
          ></SkuSelector>
          <el-col :xs="12" :sm="12" :md="12">
            <div style="height: 350px" v-if="!categoryHidden">
              <treeselect
                :options="categoryList"
                :show-count="true"
                :multiple="true"
                :disable-branch-nodes="true"
                v-model="form.item_category"
              >
              </treeselect>
            </div>
          </el-col>
          <template v-if="!tagHidden">
            <div class="selected-tags view-flex">
              <div class="label">已选中标签：</div>
              <div class="view-flex-item">
                <el-tag
                  :key="index"
                  v-for="(tag, index) in tag.currentTags"
                  closable
                  size="small"
                  :disable-transitions="false"
                  @close="tagRemove(index)"
                >
                  {{ tag.tag_name }}
                </el-tag>
              </div>
            </div>
            <div>
              <el-tag
                class="tag-item"
                :key="index"
                v-for="(tag, index) in tag.tags"
                size="medium"
                color="#ffffff"
                :disable-transitions="false"
                @click.native="tagAdd(tag, index)"
              >
                {{ tag.tag_name }}
              </el-tag>
            </div>
          </template>
          <template v-if="!brandHidden">
            <div class="selected-tags view-flex">
              <div class="label">已选中品牌：</div>
              <div class="view-flex-item">
                <el-tag
                  :key="index"
                  v-for="(brand, index) in brand.currentBrands"
                  closable
                  size="small"
                  :disable-transitions="false"
                  @close="brandRemove(index)"
                >
                  {{ brand.attribute_name }}
                </el-tag>
              </div>
            </div>
            <div>
              <el-tag
                class="tag-item"
                :key="index"
                v-for="(brand, index) in brand.brands"
                size="medium"
                color="#ffffff"
                :disable-transitions="false"
                @click.native="brandAdd(brand, index)"
              >
                {{ brand.attribute_name }}
              </el-tag>
            </div>
          </template>
        </el-card>
        <el-card
          header="门店"
          shadow="naver"
          v-if="!is_distributor && form.use_platform == 'store' && false"
        >
          <el-form-item label="适用门店">
            <el-radio-group v-model="form.use_all_shops" @change="shopTypeChange">
              <el-radio label="true">全部门店适用</el-radio>
              <el-radio label="false">指定门店适用</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="form.use_all_shops === 'false'">
            <el-button type="primary" @click="addStoreAction">选择门店</el-button>
            <el-table
              v-if="relStores.length > 0"
              :data="relStores"
              style="line-height: normal"
            >
              <el-table-column
                label="ID"
                prop="wxShopId"
                width="60"
              ></el-table-column>
              <el-table-column label="名称" prop="storeName"></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="操作" width="50">
                <template slot-scope="scope">
                  <i
                    class="iconfont icon-trash-alt"
                    @click="deleteRow(scope.$index, form.items)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>

        <el-card
          header="店铺"
          shadow="naver"
          v-if="
          (system_mode !== 'platform' &&
            !is_distributor &&
            form.use_platform == 'mall') ||
          true
        "
        >
          <el-form-item label="适用店铺">
            <el-radio-group
              v-model="form.rel_distributor_by"
              @change="shopTypeChange"
            >
              <el-radio-button label="all">全部店铺适用</el-radio-button>
              <el-radio-button label="distributor">指定店铺适用</el-radio-button>
              <el-radio-button label="dealer">指定经销商适用</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div>
            <el-button
              type="primary"
              @click="addDistributorAction"
              v-if="form.rel_distributor_by !== 'all'"
            >添加</el-button
            >
            <el-table
              v-if="
              form.rel_distributor_by !== 'all' && distributor_info.length > 0
            "
              :data="distributor_info"
              style="line-height: normal"
            >
              <template v-if="form.rel_distributor_by === 'distributor'">
                <el-table-column
                  label="ID"
                  prop="distributor_id"
                  width="90"
                ></el-table-column>
                <el-table-column
                  label="AppleID"
                  prop="shop_code"
                  width="120"
                ></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column
                  prop="address"
                  label="地址"
                  show-overflow-tooltip
                ></el-table-column>
              </template>
              <template v-if="form.rel_distributor_by === 'dealer'">
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column
                  label="经销商HQID"
                  prop="dealer_hqid"
                ></el-table-column>
                <el-table-column
                  prop="distributor_hqid"
                  label="分销商HQID"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="分销商VAD"
                  prop="distributor_vad"
                ></el-table-column>
              </template>
              <el-table-column label="操作" width="50">
                <template slot-scope="scope">
                  <i
                    class="iconfont icon-trash-alt"
                    @click="deleteDistritutorRow(scope.$index, form.items)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </template>
      <template v-if="form.card_type === 'gift'">
        <el-card shadow="never" header="基础信息">
          <el-form-item
            label="兑换商品名称"
            prop="gift"
            v-if="form.card_type === 'gift'"
          >
            <el-input
              :maxlength="inputValue.gift_max"
              placeholder="兑换商品名称"
              show-word-limit
              v-model="form.gift"
              @change="giftChange"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="折扣额度"
            prop="discount"
            v-if="form.card_type === 'discount'"
          >
            <el-input
              :disabled="!!form.card_id"
              v-model="form.discount"
              placeholder="只能是大于等于1,小于10的数字"
              max="10.0"
              min="1"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="减免金额"
            prop="reduce_cost"
            v-if="form.card_type === 'cash'"
          >
            <el-input
              type="number"
              :disabled="!!form.card_id"
              v-model="form.reduce_cost"
              placeholder="只能是大于0的数字"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="核销方式" v-if="form.card_type === 'gift'">
            <el-select v-model="form.use_scan" style="width: 100%" @change="useScanChange" :disabled="!!form.card_id">
              <el-option value="third_system" label="对接第三方核销"></el-option>
              <el-option
                value="external_offline"
                label="线下第三方核销"
              ></el-option>
              <el-option value="local_system" label="本地系统核销"></el-option>
              <el-option value="third_change" label="第三方平台兑换"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="兑换连接"
            v-if="form.use_scan === 'third_change'"
          >
            <el-input
              placeholder="请输入第三方兑换连接"
              show-word-limit
              :disabled="!!form.card_id"
              v-model="form.promotion_url"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否跳转" prop="promotion_url_skip" v-show="form.use_scan === 'third_change'">
            <el-switch :disabled="!!form.card_id" v-model="form.promotion_url_skip"></el-switch>
          </el-form-item>

          <el-form-item label="按钮标题" prop="promotion_url_sub_title" v-show="form.use_scan === 'third_change'">
            <el-input
              placeholder="按钮标题"
              show-word-limit
              :disabled="!!form.card_id"
              v-model="form.promotion_url_sub_title"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="券码维护方式"
            v-if="form.use_scan === 'third_change'"
          >
            <el-radio-group v-model="form.code_option" :disabled="!!form.card_id">
              <el-radio-button label="admin"
              >上传券码</el-radio-button
              >
              <el-radio-button label="self">
                不上传券码
              </el-radio-button>
            </el-radio-group>
            <p class="frm-tips">*此选项将决定当前优惠券的券码是否可以在后台进行上传维护</p>
          </el-form-item>
          <el-form-item label="发放数量" prop="quantity">
            <el-input
              min="1"
              :disabled="!!form.card_id || (form.use_scan === 'external_offline' || (form.use_scan === 'third_change' && form.code_option==='admin'))"
              type="number"
              v-model="form.quantity"
              placeholder="只能是大于0的数字"
            ><template slot="append">份</template></el-input
            >
          </el-form-item>
          <el-form-item label="卡券标题" prop="title">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.title"
              placeholder="字数上限为12个汉字"
              @change="titleChange"
            ><template slot="append"
            >{{ inputValue.title_length }}/{{
                inputValue.title_max
              }}</template
            ></el-input
            >
            <p class="frm-tips">
              建议填写抵扣券“减免金额”及自定义内容，描述卡券提供的具体优惠
            </p>
          </el-form-item>
          <el-form-item label="卡券副标题" prop="subtitle">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.subtitle"
              type="textarea"
              :rows="5"
              show-word-limit
              maxlength="50"
              placeholder="字数上限为50个汉字"
              @change="subtitleChange"
            ></el-input>
          </el-form-item>

          <!--        <el-form-item label="优惠券标签" prop="card_label">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.card_label"
              placeholder="字数上限为9个汉字"
              show-word-limit
              maxlength="9"
              @change="cardLabelChange"
            ></el-input>
            <p class="frm-tips">建议填写“优惠活动”及自定义内容</p>
          </el-form-item>-->
          <el-row>
            <el-col :span="8">
              <el-form-item label="券LOGO">
                <div @click="handleImgPicker('logo_url')" class="upload-box">
                  <img v-if="form.logo_url" :src="form.logo_url" class="avatar" />
                  <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                </div>
                <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="使用条件" prop="useCondition">
            <el-radio-group
              :disabled="!!form.card_id"
              v-model="form.useCondition"
              @change="conditionChange"
            >
              <!-- <el-radio-button :label="1">不限制</el-radio-button> -->
              <el-radio-button :label="2" v-if="form.card_type !== 'gift'"
              >限制</el-radio-button
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.card_type !== 'gift' && form.useCondition === 2"
          >
            <el-row>
              <el-col :span="11">
                <el-input
                  type="number"
                  min="0"
                  :disabled="!!(form.card_id || form.useCondition == 1)"
                  v-model="form.least_cost"
                >
                  <template slot="prepend">满</template>
                  <template slot="append">元可用</template>
                </el-input>
              </el-col>
              <el-col
                :span="1"
                v-if="form.card_type === 'discount'"
                style="text-align: center"
              >-</el-col
              >
              <el-col :span="11">
                <el-input
                  v-if="form.card_type === 'discount'"
                  :disabled="!!(form.card_id || form.useCondition == 1)"
                  v-model="form.most_cost"
                ><template slot="prepend">最高限额</template
                ><template slot="append">元</template></el-input
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="有效期" prop="date_type">
            <el-row>
              <el-radio-group v-model="form.date_type" @change="changeDatetime" :disabled="timeDisabled">
                <el-radio-button label="DATE_TYPE_FIX_TIME_RANGE"
                >按日期</el-radio-button
                >
                <el-radio-button label="DATE_TYPE_FIX_TERM">
                  按领取
                </el-radio-button>
              </el-radio-group>
            </el-row>
            <el-row v-if="form.date_type === 'DATE_TYPE_FIX_TIME_RANGE'">
              <el-date-picker
                @change="changeDatePicker"
                v-model="date_range"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                type="datetimerange"
                range-separator="至"
                value-format="timestamp"
                :picker-options="form.card_id ? '' : pickerOptions"
                :disabled="form.date_type == 'DATE_TYPE_FIX_TERM' || timeDisabled"
              ></el-date-picker>
            </el-row>
            <el-row v-else>
              <el-form-item label="领取后" style="width: 100%">
                <el-row>
                  <el-col :span="22">
                    <el-select
                      style="width: 100%; margin-bottom: 10px"
                      v-model="form.begin_time"
                      size="5"
                    >
                      <el-option
                        v-for="item in totalDays"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="2">生效</el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="有效天数" style="width: 100%">
                <el-input type='number' min='1' max='3650' v-model="form.days" @change="onChangeDays" placeholder="请输入有效天数"></el-input>
                <!-- <el-select
                  style="width: 100%; margin-bottom: 10px"
                  v-model="form.days"
                  size="5"
                >
                  <el-option
                    v-for="item in remainDays"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="统一过期时间" style="width: 100%">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.end_time"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  type="datetime"
                  range-separator="至"
                  value-format="timestamp"
                  :picker-options="pickerOptions"
                  :disabled="timeDisabled"
                ></el-date-picker>
              </el-form-item>
            </el-row>
          </el-form-item>



          <el-form-item label="卡券使用说明">
            <template>
              <vue-html5-editor :content="form.description.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
              <span class="tpl_item img" @click="addImgPreview" style="">
              <i class="iconfont icon-image"></i>图片
            </span>
            </template>
            <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>

            <!--          <div @click="handleImgPicker('description')" class="upload-box">-->
            <!--            <img v-if="form.description" :src="form.description" class="avatar" />-->
            <!--            <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>-->
            <!--          </div>-->
            <!--          <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>-->
          </el-form-item>

          <!--        <el-form-item label="卡券使用说明" prop="description">-->
          <!--          <el-input-->
          <!--            required-->
          <!--            type="textarea"-->
          <!--            :rows="12"-->
          <!--            resize="none"-->
          <!--            :maxlength="inputValue.description_max"-->
          <!--            show-word-limit-->
          <!--            v-model="form.description"-->
          <!--            placeholder="请填写使用本优惠券的注意事项"-->
          <!--            @change="descriptionChange"-->
          <!--          ></el-input>-->
          <!--        </el-form-item>-->
          <!--        -->


          <el-form-item label="优惠券优先级" prop="card_priority">
            <el-input
              required
              type="number"
              placeholder="请填写使用本优惠券的优先级"
              v-model="form.card_priority"
            ></el-input>
            <p class="frm-tips">数值越小，优先级越高</p>
          </el-form-item>
          <!--        <el-form-item label="优惠券模板ID" prop="card_code">
            <el-input
              :maxlength="255"
              placeholder="优惠券模板ID"
              v-model="form.card_code"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠券规则ID" prop="card_rule_code">
            <el-input
              :maxlength="255"
              placeholder="优惠券规则ID"
              v-model="form.card_rule_code"
            ></el-input>
          </el-form-item>-->
        </el-card>
        <el-card shadow="never" header="适用规则">
          <el-form-item label="领取方式">
            <!--          <el-switch
                        disabled
                        v-model="form.receive"
                        active-color="#13ce66"
                        inactive-color="#d2d4db"
                      ></el-switch>-->
            <el-select v-model="form.receive" placeholder="请选择" :disabled="form.card_id != ''">
              <el-option key="true" label="前台直接领取" value="true"></el-option>
              <el-option key="password" label="口令卡领取" value="password"></el-option>
              <el-option key="condition" label="条件领取" value="condition"></el-option>
            </el-select>
          </el-form-item>
          <!--        <el-form-item label="核销方式" v-if="form.card_type === 'gift'">
                    <el-select v-model="form.use_scan" style="width: 100%">
                      <el-option
                        value="external_offline"
                        label="线下第三方核销"
                      ></el-option>
                      <el-option value="local_system" label="本地系统核销"></el-option>
                      <el-option value="third_system" label="对接第三方核销"></el-option>
                    </el-select>
                  </el-form-item>-->

          <PasswordForm :formValues="formPassword" :isDisabled="form.card_id != ''" :is-valid="form.receive == 'password' ? true : false" @changeForm="changeForm"></PasswordForm>         

          <el-form-item label="领券限制">
            <el-input type="number" v-model="form.get_limit"></el-input>
            <p class="frm-tips">每个用户领券上限，如不填，则默认为1。</p>
          </el-form-item>
          <el-form-item
            v-show="false"
            label="适用平台"
            v-if="!is_distributor && form.card_type == 'gift'"
          >
            <el-radio-group
              v-model="form.use_platform"
              :disabled="form.card_id != ''"
              @change="usePlatformChange"
            >
              <el-radio-button label="store" v-if="!is_distributor"
              >门店专用</el-radio-button
              >
              <el-radio-button label="mall" v-if="form.card_type != 'gift'"
              >线上商城专用</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="适用订单类型" prop="allow_order_receipt_type" v-show="form.card_type !== 'gift'">
            <el-radio-group v-model="form.allow_order_receipt_type">
              <el-radio-button label="all">不限</el-radio-button>
              <el-radio-button label="logistics">快递</el-radio-button>
              <el-radio-button label="ziti">自提</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="首单专属" prop="use_first_order" v-show="form.card_type !== 'gift'">
            <el-switch v-model="form.use_first_order"></el-switch>
          </el-form-item>

          <el-form-item
            label="核销场景"
            prop="use_scenes"
            v-if="false && !is_distributor && form.card_type == 'gift'"
          >
            <el-radio-group
              v-model="form.use_scenes"
              :disabled="form.card_id != ''"
            >
              <el-radio
                v-if="form.card_type != 'gift' && form.use_platform == 'mall'"
                label="ONLINE"
              >线上商城使用</el-radio
              >
              <el-radio
                v-if="form.card_type != 'gift' && form.use_platform == 'store'"
                label="QUICK"
              >快捷买单使用</el-radio
              >
              <el-radio label="SELF" v-if="form.use_platform == 'store'"
              >自助核销（到店使用）</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="仅总店商品可用"
            v-if="
            system_mode == 'platform' &&
            !is_distributor &&
            form.use_platform == 'mall'
          "
          >
            <el-switch
              v-model="form.store_self"
              :disabled="form.card_id != ''"
              active-color="#13ce66"
              inactive-color="#d2d4db"
            ></el-switch>
          </el-form-item>
          <el-form-item v-if="false && form.use_scenes == 'SELF'" label="验证码">
            <el-radio-group v-model="self_rcode" :disabled="form.card_id != ''">
              <el-radio label="1">启用验证码</el-radio>
              <el-radio label="0">不启用验证码</el-radio>
            </el-radio-group>
            <br />
            <el-input
              v-if="self_rcode === '1'"
              v-model="form.self_consume_code"
              placeholder="请输入验证码"
              maxlength="4"
              minlength="4"
            ></el-input>
          </el-form-item>
        </el-card>
        <el-card
          v-if="
          form.use_platform == 'mall' &&
          form.card_type != 'gift' &&
          form.store_self == false
        "
          header="商品"
          shadow="naver"
        >
          <div class="show-goods">
            <el-form-item label="适用商品">
              <el-select
                v-model="form.use_all_items"
                style="width: 100%"
                @change="itemTypeChange"
              >
                <el-option label="全部商品适用" value="true"></el-option>
                <el-option label="指定商品适用" value="false"></el-option>
                <!-- <el-option label="指定分类适用" value="category"></el-option>
                <el-option label="指定商品标签适用" value="tag"></el-option>
                <el-option label="指定品牌适用" value="brand"></el-option>
                <el-option label="不指定商品" value="none"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="展示前" class="item_show_quantity" v-if="form.use_all_items == 'false'">
              <el-input
                placeholder="展示数量，不填默认全部"
                v-model="form.item_show_quantity"
                @input="item_show_change"
              ></el-input>
              件商品
              <span class="el-icon-warning-outline" />拖拽商品进行排序
            </el-form-item>
          </div>
          <SkuSelector
            v-if="!zdItemHidden"
            @change="getItems"
            :data="relItems"
            dragger-class="move"
            :is-show-num="true"
          ></SkuSelector>
          <el-col :xs="12" :sm="12" :md="12">
            <div style="height: 350px" v-if="!categoryHidden">
              <treeselect
                :options="categoryList"
                :show-count="true"
                :multiple="true"
                :disable-branch-nodes="true"
                v-model="form.item_category"
              >
              </treeselect>
            </div>
          </el-col>
          <template v-if="!tagHidden">
            <div class="selected-tags view-flex">
              <div class="label">已选中标签：</div>
              <div class="view-flex-item">
                <el-tag
                  :key="index"
                  v-for="(tag, index) in tag.currentTags"
                  closable
                  size="small"
                  :disable-transitions="false"
                  @close="tagRemove(index)"
                >
                  {{ tag.tag_name }}
                </el-tag>
              </div>
            </div>
            <div>
              <el-tag
                class="tag-item"
                :key="index"
                v-for="(tag, index) in tag.tags"
                size="medium"
                color="#ffffff"
                :disable-transitions="false"
                @click.native="tagAdd(tag, index)"
              >
                {{ tag.tag_name }}
              </el-tag>
            </div>
          </template>
          <template v-if="!brandHidden">
            <div class="selected-tags view-flex">
              <div class="label">已选中品牌：</div>
              <div class="view-flex-item">
                <el-tag
                  :key="index"
                  v-for="(brand, index) in brand.currentBrands"
                  closable
                  size="small"
                  :disable-transitions="false"
                  @close="brandRemove(index)"
                >
                  {{ brand.attribute_name }}
                </el-tag>
              </div>
            </div>
            <div>
              <el-tag
                class="tag-item"
                :key="index"
                v-for="(brand, index) in brand.brands"
                size="medium"
                color="#ffffff"
                :disable-transitions="false"
                @click.native="brandAdd(brand, index)"
              >
                {{ brand.attribute_name }}
              </el-tag>
            </div>
          </template>
        </el-card>
        <el-card
          header="门店"
          shadow="naver"
          v-if="!is_distributor && form.use_platform == 'store' && false"
        >
          <el-form-item label="适用门店">
            <el-radio-group v-model="form.use_all_shops" @change="shopTypeChange">
              <el-radio label="true">全部门店适用</el-radio>
              <el-radio label="false">指定门店适用</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="form.use_all_shops === 'false'">
            <el-button type="primary" @click="addStoreAction">选择门店</el-button>
            <el-table
              v-if="relStores.length > 0"
              :data="relStores"
              style="line-height: normal"
            >
              <el-table-column
                label="ID"
                prop="wxShopId"
                width="60"
              ></el-table-column>
              <el-table-column label="名称" prop="storeName"></el-table-column>
              <el-table-column
                prop="address"
                label="地址"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="操作" width="50">
                <template slot-scope="scope">
                  <i
                    class="iconfont icon-trash-alt"
                    @click="deleteRow(scope.$index, form.items)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>

        <el-card
          header="店铺"
          shadow="naver"
          v-if="
          (system_mode !== 'platform' &&
            !is_distributor &&
            form.use_platform == 'mall') ||
          true
        "
        >
          <el-form-item label="适用店铺">
            <el-radio-group
              v-model="form.rel_distributor_by"
              @change="shopTypeChange"
            >
              <el-radio-button label="all">全部店铺适用</el-radio-button>
              <el-radio-button label="distributor">指定店铺适用</el-radio-button>
              <el-radio-button label="dealer">指定经销商适用</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div>
            <el-button
              type="primary"
              @click="addDistributorAction"
              v-if="form.rel_distributor_by !== 'all'"
            >添加</el-button
            >
            <el-table
              v-if="
              form.rel_distributor_by !== 'all' && distributor_info.length > 0
            "
              :data="distributor_info"
              style="line-height: normal"
            >
              <template v-if="form.rel_distributor_by === 'distributor'">
                <el-table-column
                  label="ID"
                  prop="distributor_id"
                  width="90"
                ></el-table-column>
                <el-table-column
                  label="AppleID"
                  prop="shop_code"
                  width="120"
                ></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column
                  prop="address"
                  label="地址"
                  show-overflow-tooltip
                ></el-table-column>
              </template>
              <template v-if="form.rel_distributor_by === 'dealer'">
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column
                  label="经销商HQID"
                  prop="dealer_hqid"
                ></el-table-column>
                <el-table-column
                  prop="distributor_hqid"
                  label="分销商HQID"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="分销商VAD"
                  prop="distributor_vad"
                ></el-table-column>
              </template>
              <el-table-column label="操作" width="50">
                <template slot-scope="scope">
                  <i
                    class="iconfont icon-trash-alt"
                    @click="deleteDistritutorRow(scope.$index, form.items)"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </template>

      <template v-if="form.card_type === 'distributor'">
        <el-card shadow="never" header="基础信息">
          <el-form-item label="兑换礼品类型">
            <el-radio-group v-model="form.shop_card_type" :disabled="!!form.card_id">
              <el-radio label="service">虚拟礼品</el-radio>
              <el-radio label="normal">实体礼品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="兑换商品名称"
            prop="gift"
          >
            <el-input
              :maxlength="inputValue.gift_max"
              placeholder="兑换商品名称"
              show-word-limit
              v-model="form.gift"
              @change="giftChange"
            ></el-input>
          </el-form-item>
          <el-form-item label="核销方式">
            <el-select v-model="form.use_scan" style="width: 100%" @change="useScanChange" :disabled="!!form.card_id">
              <el-option v-if="form.shop_card_type === 'normal'" value="third_system" label="对接第三方核销"></el-option>
              <el-option v-if="form.shop_card_type === 'service'" value="external_offline" label="线下第三方核销"></el-option>
              <el-option v-if="form.shop_card_type === 'normal'" value="local_system" label="本地系统核销"></el-option>
              <el-option v-if="form.shop_card_type === 'service'" value="third_change" label="第三方平台兑换"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="兑换连接"
            v-if="form.use_scan === 'third_change'"
          >
            <el-input
              placeholder="请输入第三方兑换连接"
              show-word-limit
              :disabled="!!form.card_id"
              v-model="form.promotion_url"
            ></el-input>
          </el-form-item>

          <el-form-item label="卡券标题" prop="title">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.title"
              placeholder="字数上限为12个汉字"
              @change="titleChange"
            ><template slot="append"
            >{{ inputValue.title_length }}/{{
                inputValue.title_max
              }}</template
            ></el-input
            >
            <p class="frm-tips">
              建议填写抵扣券“减免金额”及自定义内容，描述卡券提供的具体优惠
            </p>
          </el-form-item>
          <el-form-item label="卡券副标题" prop="subtitle">
            <el-input
              :disabled="!!form.card_id"
              v-model="form.subtitle"
              type="textarea"
              :rows="5"
              show-word-limit
              maxlength="50"
              placeholder="字数上限为50个汉字"
              @change="subtitleChange"
            ></el-input>
          </el-form-item>

          <el-row>
            <el-col :span="8">
              <el-form-item label="券LOGO">
                <div @click="handleImgPicker('logo_url')" class="upload-box">
                  <img v-if="form.logo_url" :src="form.logo_url" class="avatar" />
                  <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                </div>
                <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="有效期" prop="date_type">
            <el-row>
              <el-radio-group v-model="form.date_type" @change="changeDatetime" :disabled="timeDisabled">
                <el-radio-button label="DATE_TYPE_FIX_TIME_RANGE"
                >按日期</el-radio-button
                >
                <el-radio-button label="DATE_TYPE_FIX_TERM">
                  按领取
                </el-radio-button>
              </el-radio-group>
            </el-row>
            <el-row v-if="form.date_type === 'DATE_TYPE_FIX_TIME_RANGE'">
              <el-date-picker
                @change="changeDatePicker"
                v-model="date_range"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                type="datetimerange"
                range-separator="至"
                value-format="timestamp"
                :picker-options="form.card_id ? '' : pickerOptions"
                :disabled="form.date_type == 'DATE_TYPE_FIX_TERM' || timeDisabled"
              ></el-date-picker>
            </el-row>
            <el-row v-else>
              <el-form-item label="领取后" style="width: 100%">
                <el-row>
                  <el-col :span="22">
                    <el-select
                      style="width: 100%; margin-bottom: 10px"
                      v-model="form.begin_time"
                      size="5"
                    >
                      <el-option
                        v-for="item in totalDays"
                        :key="item.value"
                        :label="item.text"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="2">生效</el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="有效天数" style="width: 100%">
                <el-input type='number' min='1' max='3650' v-model="form.days" @change="onChangeDays" placeholder="请输入有效天数"></el-input>
                <!-- <el-select
                  style="width: 100%; margin-bottom: 10px"
                  v-model="form.days"
                  size="5"
                >
                  <el-option
                    v-for="item in remainDays"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  ></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="统一过期时间" style="width: 100%">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.end_time"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  type="datetime"
                  range-separator="至"
                  value-format="timestamp"
                  :picker-options="pickerOptions"
                  :disabled="timeDisabled"
                ></el-date-picker>
              </el-form-item>
            </el-row>
          </el-form-item>



          <el-form-item label="卡券使用说明">
            <template>
              <vue-html5-editor :content="form.description.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
              <span class="tpl_item img" @click="addImgPreview" style="">
              <i class="iconfont icon-image"></i>图片
            </span>
            </template>
            <imgPicker :dialog-visible="thumbDialog" :sc-status="isGetThumb" :isMost="true" @chooseImg="pickThumb" @closeImgDialog="closeThumbDialog"></imgPicker>

          </el-form-item>

          <el-form-item label="优惠券优先级" prop="card_priority">
            <el-input
              required
              type="number"
              placeholder="请填写使用本优惠券的优先级"
              v-model="form.card_priority"
            ></el-input>
            <p class="frm-tips">数值越小，优先级越高</p>
          </el-form-item>
        </el-card>
        <el-card shadow="never" header="适用规则">
          <el-form-item label="领取方式">
            <el-select v-model="form.receive" placeholder="请选择" :disabled="form.card_id != ''">
              <el-option v-if="form.shop_card_type === 'normal'" key="true" label="前台直接领取" value="true"></el-option>
<!--              <el-option key="password" label="口令卡领取" value="password"></el-option>-->
              <el-option key="condition" label="条件领取" value="condition"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="领券限制">
            <el-input type="number" v-model="form.get_limit"></el-input>
            <p class="frm-tips">每个用户领券上限，如不填，则默认为1。</p>
          </el-form-item>

        </el-card>

      </template>


      <div class="content-center">
        <el-button @click="cancelSubmit">取消</el-button>
        <el-button
          type="primary"
          @click="submitForm('form')"
          :disabled="submitDisabled"
          >提交</el-button
        >
      </div>
    </el-form>
    <imgPicker
      :dialog-visible="imgDialog"
      :sc-status="isGetImage"
      @chooseImg="pickImg"
      @closeImgDialog="closeImgDialog"
    ></imgPicker>

    <StoreSelect
      :store-visible="storeVisible"
      :is-valid="true"
      :relDataIds="relStores"
      :get-status="setStatus"
      @chooseStore="chooseStoreAction"
      @closeStoreDialog="closeStoreDialogAction"
    ></StoreSelect>

    <DistributorSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :relDataIds="distributor_info"
      :get-status="setDistributorStatus"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDistributorDialogAction"
      returnType="selectRow"
    ></DistributorSelect>

    <DealerSelect
      :store-visible="dealerVisible"
      :is-valid="false"
      :maxSelect="1"
      :relDataIds="distributor_info"
      :get-status="dealerStatus"
      @chooseStore="handleDealerSelected"
      @closeStoreDialog="handleCloseDealer"
    ></DealerSelect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import store from '@/store'
import StoreSelect from '@/components/function/shopSelect'
import DistributorSelect from '@/components/function/distributorSelect'
// import DistributorSelect from '@/components/storeListSelect'
import DealerSelect from '@/components/function/dealerSelect'
import SkuSelector from '@/components/function/skuSelector'
import { getCardDetail, creatCard, updateCard, getBindStoreList } from '@/api/cardticket'
import { getWxShopsList } from '@/api/shop'
import { getCategory, getTagList, getGoodsAttr } from '@/api/goods'
import imgPicker from '@/components/imageselect'
import PasswordForm from './comps/password-form.vue'

export default {
  inject: ['refresh'],
  components: {
    imgPicker,
    StoreSelect,
    SkuSelector,
    DistributorSelect,
    Treeselect,
    DealerSelect,
    PasswordForm
  },

  data () {
    let tempDays = [{ text: '当天', value: 0 }]
    let tempRemainDays = []
    for (let i = 1; i <= 90; i++) {
      tempDays.push({ text: i + '天', value: i })
      tempRemainDays.push({ text: i + '天', value: i })
    }
    var useConditionChecked = (rule, value, callback) => {
      if (this.form.card_type === 'cash' && this.form.useCondition === 2) {
        if (this.form.least_cost === '') {
          callback(new Error('请填写金额'))
        } else if (Number(this.form.least_cost) <= Number(this.form.reduce_cost)) {
          callback(new Error('填写金额必须大于减免金额'))
        } else {
          callback()
        }
      }
      if (this.form.card_type === 'discount' && this.form.useCondition === 2) {
        if (this.form.most_cost === '') {
          callback(new Error('请填写金额'))
        } else if (Number(this.form.most_cost) <= Number(this.form.least_cost)) {
          callback(new Error('最高消费限额必须大于起用金额'))
        } else {
          callback()
        }
      }
      callback()
    }
    var quantityChecked = (rule, value, callback) => {
      let reg = /^\d*$/
      if (!reg.test(value)) {
        callback(new Error('库存必须为数字'))
      } else {
        callback()
      }
    }
    var dateChecked = (rule, value, callback) => {
      if (value == 'DATE_TYPE_FIX_TIME_RANGE' && this.date_range == '') {
        callback(new Error('固定日期必填'))
      } else {
        callback()
      }
    }
    var titleChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('卡券标题不能为空'))
      } else if (this.inputValue.title_length > this.inputValue.title_max) {
        callback(new Error('卡券标题长度不超过' + this.inputValue.title_max + '个汉字或' + (this.inputValue.title_max * 2) + '个英文字母'))
      } else {
        callback()
      }
    }
    var subtitleChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('卡券副标题不能为空'))
      } else if (this.inputValue.subtitle_length > this.inputValue.subtitle_max) {
        callback(new Error('卡券副标题长度不超过' + this.inputValue.subtitle_max + '个汉字或' + (this.inputValue.subtitle_max * 2) + '个英文字母'))
      } else {
        callback()
      }
    }
    var descriptionChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('卡券使用说明不能为空'))
      } else if (this.inputValue.description_length > this.inputValue.description_max) {
        callback(new Error('卡券使用说明长度' + this.inputValue.description_max + '个汉字或' + (this.inputValue.description_max * 2) + '个英文字母'))
      } else {
        callback()
      }
    }
    /* var cardLabelChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('优惠券标签不能为空'))
      } else if (this.inputValue.card_label_length > this.inputValue.card_label_max) {
        callback(new Error('优惠券标签长度不超过' + this.inputValue.card_label_max + '个汉字或' + (this.inputValue.card_label_max * 2) + '个英文字母'))
      } else {
        callback()
      }
    } */
    var cardPriorityChecked = (rule, value, callback) => {
      let reg = /^\d*$/
      if (value === '') {
        callback(new Error('优惠券优先级不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('优惠券优先级必须为数字'))
      } else {
        callback()
      }
    }
    var discountChecked = (rule, value, callback) => {
      var reg = /(^[1-9]((\.)[0-9])?$)/
      if (value == '') {
        callback(new Error('折扣额度不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('折扣额度为1-9.9之间的数字，精确到小数点后1位'))
      } else {
        callback()
      }
    }
    var reduceCostChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('减免金额不能为空'))
      } else if (parseFloat(value) > 0) {
        callback()
      } else {
        callback(new Error('减免金额必须大于0'))

      }
    }
    var useScenesChecked = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('至少选择一项使用场景'))
      }
      callback()
    }
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      thumbDialog: false,
      isGetThumb: false,
      totalDays: tempDays,
      remainDays: tempRemainDays,
      datetimeStatus: true,
      articleItemIndex: -1,
      articleOprHidden: true,
      articleItem: { text: '', image_url: '' },
      addImgDefaultHidden: false,
      storeVisible: false,
      distributorVisible: false,
      dealerVisible: false,
      dealerStatus: false,
      relDealers: [],
      itemVisible: false,
      setItemStatus: false,
      date_range: '',
      setStatus: false,
      setDistributorStatus: false,
      relStores: [],
      distributor_info: [],
      relShopIds: [],
      relDistributorIds: [],
      submitDisabled: false,
      is_distributor: false,

      pickerImgType: 'logo_url',
      imgDialog: false,
      isGetImage: false,

      form: {
        color: '#000000',
        card_id: '',
        card_type: 'cash',
        shop_card_type: 'service',
        title: '',
        subtitle: '',
        least_cost: 0,
        reduce_cost: '',
        discount: '',
        gift: '',
        quantity: '',
        card_priority: '',
        date_type: 'DATE_TYPE_FIX_TIME_RANGE',
        begin_time: 0,
        days: 30,
        end_time: '',
        time_limit_date: [],
        use_all_shops: 'true',
        get_limit: 1,
        rel_distributor_by: 'all',
        rel_shops_ids: [],
        distributor_id: [],
        text_image_list: [],
        receive: 'true',
        use_scan: 'third_system', // 核销方式
        is_use_online: true,
        store_self: false,
        use_scenes: 'ONLINE',
        if_push_wechat: false,
        useCondition: 1,
        self_consume_code: 0,
        use_platform: 'mall',
        rel_item_ids: [],
        use_all_items: 'true',
        most_cost: 999999,
        item_category: [],
        tag_ids: [],
        brand_ids: [],
        card_label: '',
        description: '',
        allow_order_receipt_type: 'all', // 适用订单类型
        use_first_order: false, // 是否为首单专属
        sync_work_wechat: false,
        item_show_quantity: '',
        promotion_url: '',
        promotion_url_skip: false,
        promotion_url_sub_title: '',
        code_option: 'self'
      },
      relItems: [],
      kqhjCheckedItem: '',
      twxxCheckedItem: '',
      zdItemHidden: true,
      rules: {
        gift: [
          { required: true, message: '兑换商品名称不能为空', trigger: 'blur' }
        ],
        discount: [
          { type: 'number', required: true, validator: discountChecked, trigger: 'blur' }
        ],
        reduce_cost: [
          { type: 'number', required: true, validator: reduceCostChecked, trigger: 'blur' }
        ],
        title: [
          { required: true, validator: titleChecked, trigger: 'blur' }
        ],
        subtitle: [
          { required: true, validator: subtitleChecked, trigger: 'blur' }
        ],
        date_type: [
          { required: true, validator: dateChecked, trigger: 'blur' }
        ],
        useCondition: [
          { required: true, validator: useConditionChecked, trigger: 'blur' }
        ],
        description: [
          { required: true, validator: descriptionChecked, trigger: 'blur' }
        ],
        /*        card_label: [
                  { required: true, validator: cardLabelChecked, trigger: 'blur' }
                ], */
        card_priority: [
          { required: true, validator: cardPriorityChecked, trigger: 'blur' }
        ],
        quantity: [
          { required: true, validator: quantityChecked, trigger: 'blur' }
        ],
        use_scenes: [
          { required: true, validator: useScenesChecked, trigger: 'blur' }
        ]
      },
      inputValue: {
        title_length: 0,
        title_max: 12,
        subtitle_length: 0,
        subtitle_max: 50,
        description_length: 0,
        description_max: 600,
        card_label_length: 0,
        card_label_max: 9,
        accept_category_length: 0,
        accept_category_max: 5,
        reject_category_length: 0,
        reject_category_max: 15,
        object_use_for_length: 0,
        object_use_for_max: 15,
        gift_length: 0,
        gift_max: 20
      },
      wechatColor: ['#63b359', '#2c9f67', '#509fc9', '#5885cf', '#9062c0', '#d09a45', '#e4b138', '#ee903c', '#f08500', '#a9d92d', '#dd6549', '#cc463d', '#cf3e36', '#5E6671', '#026842'],
      self_rcode: '0',
      categoryHidden: true,
      categoryList: [],
      tagHidden: true,
      tag: {
        list: [],
        form: {
          tag_ids: []
        },
        currentTags: [],
        tags: []
      },
      brandHidden: true,
      brand: {
        list: [],
        form: {
          brand_ids: []
        },
        currentBrands: [],
        brands: []
      },
      bindStorePagination: { // 已绑定店铺&经销商分页数据
        page: 1,
        page_size: -1
      },
      formPassword: {},
      timeDisabled: false
    }
  },
  watch: {
    'form.shop_card_type': {
      immediate: true,
      handler (value) {
        if (this.form.card_id) {
          return
        }
        if (this.form.card_type !== 'distributor') {
          return
        }
        if (value === 'service') {
          this.form.use_scan = 'external_offline'
          this.form.receive = 'condition'
        } else if (value === 'normal') {
          this.form.use_scan = 'third_system'
          this.form.receive = 'true'
        }
      }
    },
    'form.card_type': {
      immediate: true,
      handler (value) {
        if (this.form.card_id) {
          return
        }
        if (value === 'distributor') {
          if (!this.form.card_id) {
            this.form.quantity = 0
          }
          if (this.form.shop_card_type === 'service') {
            this.form.use_scan = 'external_offline'
            this.form.receive = 'condition'
          } else if (this.form.shop_card_type === 'normal') {
            this.form.use_scan = 'third_system'
            this.form.receive = 'true'
          }
        } else {
          this.form.receive = 'true'
          this.form.use_scan = 'third_system'
        }
      }
    }
  },
  methods: {
    addImgPreview: function () {
      this.thumbDialog = true
      this.isGetThumb = true
    },
    pickImg (data) {
      if (this.pickerImgType == 'logo_url') {
        this.form.logo_url = data.url
      } else if(this.pickerImgType == 'description'){
        this.form.description = data.url

      }else {
        this.form.banner = data.url
      }
      console.log(data)
      this.imgDialog = false
    },
    pickThumb: function (arr) {
      if (arr.length != 0) {
        arr.forEach(data => {
          if (data && data.url !== '') {
            this.thumbDialog = false
            var index = this.$refs.editor.$el.id
            var loc = this.$refs.editor
            var img = new Image()
            img.src = this.wximageurl + data.url
            if (loc.range) {
              loc.range.insertNode(img)
              var referenceNode = loc.range.endContainer
              if (referenceNode.className !== 'content') {
                loc.range.setStartAfter(referenceNode)
              } else {
                loc.range.setStart(loc.range.endContainer, loc.range.endOffset)
              }
            } else {
              loc.$refs.content.appendChild(img)
              loc.focus()
              loc.restoreSelection()
            }
            this.form.description = loc.$refs.content.innerHTML
          }
        })
      }
    },
    closeThumbDialog: function () {
      this.thumbDialog = false
    },
    handleImgPicker (pickerImgType) {
      this.pickerImgType = pickerImgType
      this.imgDialog = true
      this.isGetImage = true
    },
    closeImgDialog () {
      this.imgDialog = false
      this.isGetImage = false
    },
    handleCloseDealer () {
      this.dealerVisible = false
    },
    handleDealerSelected (dealerlist) {
      this.distributor_info = dealerlist
      this.dealerVisible = false
    },
    getItems (data) {
      let ids = []
      data.forEach(item => {
        ids.push(item.itemId)
      })
      this.form.rel_item_ids = ids
    },
    usePlatformChange (val) {
      if (val == 'mall') {
        this.form.use_scenes = 'ONLINE'
      } else {
        this.form.use_scenes = 'QUICK'
      }
    },
    updateContent: function (data) {
      this.form.description = data
    },
    checkColor (e) {
      this.form.color = e.target.dataset.color
    },
    changeDatetime (val) {
      if (val === 'DATE_TYPE_FIX_TIME_RANGE') {
        this.datetimeStatus = true
      } else {
        this.datetimeStatus = false
        this.date_range = ''
        this.begin_time = 0
      }
    },
    handleTypeChange (val) {
      if (val === 'gift') {
        this.form.use_scenes = 'SELF'
        this.form.use_platform = 'store'
        this.form.useCondition = 1
      }
      if (val === 'cash' || val === 'discount') {
        this.form.useCondition = 2
        this.form.use_scenes = 'ONLINE'
        this.form.use_platform = 'mall'
      }
    },
    shopTypeChange (val) {
      this.form.rel_distributor_ids = []
      this.form.distributor_id = []
      this.distributor_info = []
      this.setDistributorStatus = false
      this.dealerStatus = false
      if (this.form.card_id) {
        this.setBindStoreList()
      }
    },
    itemTypeChange (val) {
      this.zdItemHidden = true
      this.categoryHidden = true
      this.tagHidden = true
      this.brandHidden = true
      this.form.rel_item_ids = []
      this.form.itemTreeLists = []
      this.form.item_category = []
      this.tag.currentTags = []
      if (val === 'false') {
        this.zdItemHidden = false
      } else if (val === 'true') {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.form.item_category = []
      } else if (val === 'category') {
        this.form.rel_item_ids = []
        this.form.itemTreeLists = []
        this.categoryHidden = false
        this.form.item_category = []
      } else if (val === 'tag') {
        this.tagHidden = false
        this.tag.currentTags = []
        this.showTags()
      } else if (val === 'brand') {
        this.brandHidden = false
        this.brand.currentBrands = []
        this.showBrands()
      }
    },
    cancelSubmit () {
      this.$router.go(-1)
    },
    async setBindStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: bindList } = await getBindStoreList({
        card_id: this.form.card_id,
        rel_distributor_by: this.form.rel_distributor_by,
        ...this.bindStorePagination
      })
      loading.close()
      this.bindStorePagination.total_count = bindList.data.total_count
      if (this.form.rel_distributor_by !== 'all') {
        this.distributor_info = bindList.data.list
      }
    },
    submitForm (formName) {
      // console.log('formName', this.form)
      const that = this
      if (this.form.date_type == 'DATE_TYPE_FIX_TIME_RANGE' && this.date_range.length > 0) {
        this.form.begin_time = this.date_range[0]
        this.form.end_time = this.date_range[1]
      }

      if (this.form.use_scenes == 'SELF' && this.self_rcode == '1') {
        if (!this.form.self_consume_code) {
          this.$message.error('选择自助核销并开启验证码后，验证码必填')
          return
        }

        if (this.form.self_consume_code && this.form.self_consume_code.length != 4) {
          this.$message.error('验证码必须为4个数字')
          return
        }

        let reg = /^\d*$/
        if (this.form.self_consume_code && !reg.test(this.form.self_consume_code)) {
          this.$message.error('验证码必须为纯数字')
          return
        }
      }
      if (this.form.use_all_shops === 'false' && !this.form.rel_shops_ids) {
        this.$message.error('请添加适用门店')
        return
      }
      if (this.form.use_scan === 'third_change') {
        if (!this.form.promotion_url || this.form.promotion_url === '') {
          this.$message.error('请输入第三发兑换链接')
          return
        }
        if (!this.form.code_option || this.form.code_option === '') {
          this.$message.error('请选择券码维护方式')
          return
        }
      }
      if (this.form.use_scenes == 'SELF' && this.self_rcode == '0') {
        this.form.self_consume_code = 0
      }
      let params = JSON.parse(JSON.stringify(this.form))
      params.tag_list = null // 不需要回传的参数
      params.rel_tag_ids = null // 不需要回传的参数
      params.rel_brand_ids = null // 不需要回传的参数
      params.brand_list = null // 不需要回传的参数
      params.tag_ids = JSON.stringify(this.form.tag_ids)
      params.brand_ids = JSON.stringify(this.form.brand_ids)
      params.item_category = JSON.stringify(this.form.item_category)
      params.itemTreeLists = []
      params.rel_distributor_ids = JSON.stringify(this.form.rel_distributor_ids)
      params.distributor_info = []
      params.rel_distributor_by = this.form.rel_distributor_by // 关联店铺方式
      params.allow_order_receipt_type = this.form.allow_order_receipt_type // 适用订单类型
      params.use_first_order = this.form.use_first_order ? '1' : '0' // 首单专属
      params.sync_work_wechat = this.form.sync_work_wechat ? '1' : '0' // 同步企微
      params.promotion_url = this.form.promotion_url
      params.promotion_url_skip = this.form.promotion_url_skip ? '1' : '0'
      params.promotion_url_sub_title = this.form.promotion_url_sub_title
      params.code_option = this.form.code_option

      let rel_item_ids_index = this.form.rel_item_ids
      if (this.form.rel_item_ids.length > 0 && this.form.use_all_items == 'false') {
        rel_item_ids_index = this.form.rel_item_ids.map((el, index) => `${el}_${index + 1}`)
      }
      params.rel_item_ids = JSON.stringify(rel_item_ids_index)

      if (params.date_type == 'DATE_TYPE_FIX_TIME_RANGE') {
        params.begin_time = params.begin_time / 1000
        params.end_time = params.end_time / 1000
      } else {
        params.end_time = params.end_time / 1000
      }

      // 判断使用条件-是否不限额度
      if (params.useCondition === 1) {
        params.least_cost = 0
        params.most_cost = 999999
      }

      if (params.use_all_items != 'false') {
        delete params.item_show_quantity
      }
      console.log(7);

      /** 抵扣券或者兑换券时新增参数： */
      if (this.form.rel_distributor_by === 'distributor' || this.form.rel_distributor_by === 'dealer') {
        if (!this.distributor_info.length) {
          // return this.$message.error('指定的店铺或经销商不能为空')
        }
        params[`${this.form.rel_distributor_by}s`] = this.distributor_info.map(item => item[`${this.form.rel_distributor_by}_id`])
      }

      // 领取方式为口令卡，需要相关参数加入params中
      if (this.form.receive == "password") {
        params = {...params, ...this.formPassword}
      }

      console.log(params, 'params', this.form)

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitDisabled = true
          console.log(this.form.card_id)
          if (this.form.card_id) {
            params.if_push_wechat = false
            updateCard(params).then(res => {
              if (res.data.data && res.data.data.status) {
                this.$message({
                  message: '更新成功',
                  type: 'success',
                  duration: 2 * 1000,
                  onClose () {
                    that.refresh()
                    that.$router.go(-1)
                  }
                })
              } else {
                this.$message.error('更新优惠券失败!')
                this.submitDisabled = false
                return false
              }
            }).catch(() => {
              this.submitDisabled = false
            })
          } else {
            creatCard(params).then(res => {
              if (res.data.data.status) {
                this.$message({
                  message: '添加成功',
                  type: 'success',
                  duration: 2 * 1000,
                  onClose () {
                    that.refresh()
                    that.$router.go(-1)
                  }
                })
              } else {
                this.$message.error('添加优惠券失败!')
                this.submitDisabled = false
                return false
              }
            }).catch(() => {
              this.submitDisabled = false
            })
          }
        } else {
          return false
        }
      })
    },
    addStoreAction () {
      this.storeVisible = true
      this.setStatus = true
      this.relShopIds = this.form.rel_shops_ids
      console.log(this.form.rel_shops_ids)
    },
    addDistributorAction () {
      if (this.form.rel_distributor_by === 'dealer') {
        this.dealerVisible = true
        this.dealerStatus = true
      } else {
        this.distributorVisible = true
        this.setDistributorStatus = true
      }
      this.relDistributorIds = this.form.distributor_id
    },
    chooseStoreAction (data) {
      console.warn('data', data)
      this.storeVisible = false
      this.form.rel_shops_ids = []
      if (data === null || data.length <= 0) return
      this.relStores = data
      for (var i = 0; i < data.length; i++) {
        if (this.form.rel_shops_ids.indexOf(Number(data[i].wxShopId)) < 0) {
          this.form.rel_shops_ids.push(Number(data[i].wxShopId))
        }
      }
    },

    chooseDistributorAction (data) {
      this.distributorVisible = false
      if (!data || data === null || data.length <= 0) {
        this.distributor_info = []
        return
      }
      this.distributor_info = data
      this.form.distributor_id = []
      for (let i = 0; i < data.length; i++) {
        const id = data[i].distributor_id
        this.form.distributor_id.push(Number(id))
      }
    },
    closeStoreDialogAction () {
      this.storeVisible = false
    },
    closeDistributorDialogAction () {
      this.distributorVisible = false
    },
    deleteRow (index) {
      this.setStatus = false
      this.relStores.splice(index, 1)
      // this.form.rel_shops_ids = []
      let shopDatas = this.relStores
      for (var i = 0; i < shopDatas.length; i++) {
        if (this.form.rel_shops_ids.indexOf(Number(shopDatas[i].wxShopId)) < 0) {
          this.form.rel_shops_ids.push(Number(shopDatas[i].wxShopId))
        }
      }
    },
    deleteDistritutorRow (index) {
      this.distributor_info.splice(index, 1)
      let shopDatas = this.distributor_info
      this.form.distributor_id = []
      for (let i = 0; i < shopDatas.length; i++) {
        if (this.form.distributor_id.indexOf(Number(shopDatas[i].distributor_id)) < 0) {
          this.form.distributor_id.push(Number(shopDatas[i].distributor_id))
        }
      }
      this.relDistributorIds = this.form.distributor_id
    },
    giftChange (value) {
      this.inputValue.gift_length = this.getValueLength(value)
    },
    titleChange (value) {
      this.inputValue.title_length = this.getValueLength(value)
    },
    subtitleChange (value) {
      this.inputValue.subtitle_length = this.getValueLength(value)
    },
    descriptionChange (value) {
      this.inputValue.description_length = this.getValueLength(value)
    },
    /*    cardLabelChange (value) {
          this.inputValue.card_label_length = this.getValueLength(value)
        }, */
    getValueLength (value) {
      let realLength = 0, charCode = -1, count = 0
      for (var i = 0; i < value.length; i++) {
        charCode = value.charCodeAt(i)
        if (charCode >= 0 && charCode <= 128) {
          count++
        } else {
          realLength++
        }
      }
      if (count > 0) {
        realLength = realLength + parseInt(count / 2)
        if (count % 2 > 0) {
          realLength += 1
        }
      }
      return realLength
    },
    conditionChange (val) {
      if (val == 1) {
        this.form.least_cost = 0
      }
      if (val == 2 && this.form.card_type === 'discount') {
        this.form.most_cost = 999999
      }
    },
    fetchMainCate () {
      getCategory({ is_main_category: true }).then(response => {
        this.categoryList = response.data.data
      })
    },
    addItemTag () {
      this.tag.currentTags = []
      if (this.item_id.length) {
        this.showTags()
        this.tag.form.item_ids = this.item_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个商品!'
        })
      }
    },
    showTags () {
      this.tag.tags = [...this.tag.list]
      let active_tags = []// 可选标签
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr == -1) active_tags.push(item)
      })
      this.tag.tags = active_tags
    },
    tagRemove (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
    },
    tagAdd (item, index) {
      let isInArr = this.tag.currentTags.findIndex(n => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
      this.form.tag_ids = []
      this.tag.currentTags.forEach(item => {
        this.form.tag_ids.push(item.tag_id)
      })
    },
    getAllTagLists () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tag.list = response.data.data.list
        this.showTags()
      })
    },
    // 获取品牌列表
    getBrandList (searchVal = '', isInit = false) {
      const list = []
      getGoodsAttr({
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: searchVal,
        attribute_ids: isInit ? this.form.brand_id : ''
      }).then(res => {
        for (let item of res.data.data.list) {
          list.push({ attribute_name: item.attribute_name, attribute_id: item.attribute_id })
        }
        this.brand.list = list
        this.showBrands()
      })
    },
    showBrands () {
      this.brand.brands = [...this.brand.list]
      let active_brands = []
      this.brand.brands.forEach((item, index) => {
        let isInArr = this.brand.currentBrands.findIndex((n) => n.attribute_id == item.attribute_id)
        if (isInArr == -1) active_brands.push(item)
      })
      this.brand.brands = active_brands
    },
    brandAdd (item, index) {
      let isInArr = this.brand.currentBrands.findIndex(n => n.attribute_id == item.attribute_id)
      if (isInArr == -1) {
        this.brand.currentBrands.push(item)
        this.brand.brands.splice(index, 1)
      }
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    brandRemove (index) {
      this.brand.brands.unshift(this.brand.currentBrands[index])
      this.brand.currentBrands.splice(index, 1)
      this.form.brand_ids = []
      this.brand.currentBrands.forEach(item => {
        this.form.brand_ids.push(item.attribute_id)
      })
    },
    useScanChange (value) {
      if (!this.card_id) {
        if (value === 'external_offline') {
          this.form.quantity = 0
        }
        if (value === 'third_change' && this.form.quantity === '') {
          this.form.quantity = 0
        }
        console.log(value)
        console.log(this.form.quantity)
      }
    },
    item_show_change () {
      this.form.item_show_quantity = Number(this.form.item_show_quantity.replace(/\D+/, '')) || ''
    },
    changeForm(data) {
      this.formPassword = data
    },
    changeDatePicker(){
      if (this.date_range.length > 0) {
        this.form.begin_time = this.date_range[0]
        this.form.end_time = this.date_range[1]
      }
      this.disabledChange()
    },
    disabledChange () {
      let newDate = Date.parse(new Date())
      if (newDate > this.form.end_time) {
        this.timeDisabled = true
      } else {
        this.timeDisabled = false
      }
    },
    onChangeDays(v) {
      this.form.days = parseInt(v)
      if (Number(v) < 1) {
        this.form.days = 1
      } 

      if (Number(v) > 3650) {
        this.form.days = 3650
      }
    }
  },
  async mounted () {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
      this.form.is_distributor = true
      this.form.use_platform = 'mall'
    }
    // if (this.$route.query.chooseCardtype) {
    //   this.form.card_type = this.$route.query.chooseCardtype
    // }
    if (this.form.card_type == 'cash') {
      this.form.useCondition = 2
    }
    if (this.$route.query.cardId) {
      this.form.card_id = this.$route.query.cardId
    }
    if (!this.form.card_id && this.form.card_type == 'gift') {
      this.form.use_scenes = 'SELF'
    }
    if (this.form.card_id) {
      const res = await getCardDetail({
        card_id: this.form.card_id
      })
      this.form = { ...this.form, ...res.data.data }
      this.setBindStoreList()
      // 口令卡领取，整理参数
      if (res.data.data.receive == 'password') {
        let obg = res.data.data
        let obg1 = {}
        obg1.password_create_type = obg.password_create_type
        obg1.password_manual_type = obg.password_manual_type
        obg1.password_custom_title = obg.password_custom_title
        obg1.password_custom_remark = obg.password_custom_remark
        obg1.password_manual_code = obg.password_manual_code
        obg1.password_custom_data = obg.password_custom_data
        this.formPassword = obg1
      }

      if (res.data.data.distributor_id == ',0,') {
        this.form.store_self = true
      } else {
        this.form.store_self = false
      }
      if (this.form.date_type == 'DATE_TYPE_FIX_TIME_RANGE') {
        this.form.begin_time = this.form.begin_time * 1000
        this.form.end_time = this.form.end_time * 1000
        this.date_range = [this.form.begin_time, this.form.end_time]
        this.form.days = 30
      } else {
        if (this.form.end_time <= 0) {
          this.form.end_time = ''
        } else {
          this.form.end_time = this.form.end_time * 1000
        }
      }
      this.disabledChange()

      if (this.form.promotion_url_skip === '1') {
        this.form.promotion_url_skip = true
      } else if (this.form.promotion_url_skip === '0') {
        this.form.promotion_url_skip = false
      }

      this.form.tag_ids = this.form.tag_ids
      this.form.brand_ids = this.form.brand_ids

      if (this.form.use_all_items === 'false') {
        this.zdItemHidden = false
      }

      if (this.form.use_all_items === 'category') {
        this.categoryHidden = false
      }

      if (this.form.use_all_items === 'tag') {
        this.tagHidden = false
        this.tag.currentTags = res.data.data.tag_list || []
        this.showTags()
      }

      if (this.form.use_all_items === 'brand') {
        this.brandHidden = false
        this.brand.currentBrands = res.data.data.brand_list || []
        this.showBrands()
      }

      if (this.form.card_type == 'gift' && (this.form.use_scenes != 'SWEEP' && this.form.use_scenes != 'SELF')) {
        this.form.use_scenes = 'SELF'
      }

      if (this.form.card_type !== 'gift' && this.form.card_type !== 'distributor') {
        delete this.form.use_scan
      }

      if (parseInt(this.form.least_cost)) {
        this.form.useCondition = 2
      } else {
        this.form.useCondition = 1
      }

      if (!this.form.self_consume_code || this.form.self_consume_code == 0) {
        this.self_rcode = '0'
      } else {
        this.self_rcode = '1'
      }

      if (this.form.rel_shops_ids.length > 0) {
        this.shopTypeChange('false')
        let shopParams = { wx_shop_id: this.form.rel_shops_ids, page: 1, pageSize: 500, isValid: true }
        getWxShopsList(shopParams).then(res => {
          this.relStores = res.data.data.list
        })
      }
      let response = res.data.data
      this.form.distributor_id = []

      this.form.use_first_order = response.use_first_order === '1'
      this.form.sync_work_wechat = response.sync_work_wechat === '1'
      this.$nextTick(() => {
        this.relItems = response.itemTreeLists
      })
      this.titleChange(this.form.title)
      this.descriptionChange(this.form.description)
      // this.cardLabelChange(this.form.card_label)
      // this.cardPriorityChange(this.form.card_priority)
    }
    this.fetchMainCate()
    this.getAllTagLists()
    this.getBrandList('', true)
  }
}

</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  padding-bottom: 90px;
  .el-form {
    .el-form-item {
      width: 700px;
    }
  }
  .vue-html5-editor {
    vertical-align: top;
    display: inline-block;
  }
  .show-goods {
    display: flex;
    justify-content: space-between;
    .item_show_quantity {
      .el-input {
        width: 200px;
      }
      .el-icon-warning-outline {
        color: #409EFF;
        margin: 0px 5px 0px 15px;
        font-size: 16px;
      }
    }
  }
}
.fallback-class {
  width: 118px;
  height: 118px;
}
.chosen-class {
  position: relative;
  height: 118px;
  text-align: center;
  .svg-icon {
    width: 26px;
    height: 26px;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 118px;
    right: 118px;
    bottom: 0;
    background: #ffb28b;
    box-shadow: inset 0 0 0 2px #ff5000;
    content: "";
  }
}
.sku-value {
  margin-right: 10px;
}
.sku-img {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}
.sku-img-pick {
  color: #999;
  cursor: pointer;
}
.avatar-uploader-icon {
  font-size: 48px;
}
.sku-select__item {
  display: flex;
  position: relative;
}
.sku-select__checkgroup {
  padding-left: 20px;
  flex: 1;
}
.sku-select__checkitem {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}
.sku-remove__icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -20px;
  right: -20px;
  background: #ff5000;
  line-height: 30px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}
.sku-tip {
  padding-left: 10px;
  font-size: 12px;
  color: #999;
}

.pics-box {
  overflow: hidden;
  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;
    .goodspic {
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .goodspic-mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        cursor: pointer;
        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}

.tpl_item {
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fff;
  text-align: left;
  padding: 0 15px;
  line-height: 36px;
  cursor: pointer;
  border-radius: 3px;
  .iconfont {
    margin-right: 5px;
  }
}

.tpl_item .fa {
  width: 12px;
  height: 22px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  font-size: 20px;
  text-align: center;
}

.vue-html5-editor {
  vertical-align: top;
  display: inline-block;
}

.demo-ruleForm {
  .vue-html5-editor {
    width: 80%;
  }
}

</style>
