<template>
  <div>
    <el-form class="consumecard" ref="form" :model="form" label-width="50px">
      <el-form-item
        label="code"
        prop="code"
        :rules="[{ required: true, message: 'code不能为空' }]"
      >
        <el-input
          placeholder="请输入内容"
          class="input-with-select"
          v-model="form.code"
          clearable
        >
        </el-input>
        <el-button
          type="primary"
          plain
          class="hexiao"
          @click="submitForm('form')"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- <el-descriptions
      title="卡券详情"
      direction="vertical"
      :column="4"
      border
      v-if="data.title"
    >
      <el-descriptions-item label="卡券id">{{ data.id }}</el-descriptions-item>
      <el-descriptions-item label="卡券标题">{{
        data.title
      }}</el-descriptions-item>
      <el-descriptions-item label="卡券副标题">{{
        data.subtitle
      }}</el-descriptions-item>
      <el-descriptions-item label="兑换商品" :span="2">{{
        data.gift
      }}</el-descriptions-item>
      <el-descriptions-item label="有效期">
        <el-tag size="small">{{ data.hasTime }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="使用说明">{{
        data.description
      }}</el-descriptions-item>
      <el-descriptions-item label="用户id">{{
        data.user_id
      }}</el-descriptions-item>
      <el-descriptions-item label="source_type">{{
        data.source_type
      }}</el-descriptions-item>
      <el-descriptions-item :contentStyle="{ 'text-align': 'center' }">
        <el-button @click="handleClick('form')" type="primary">核销</el-button>
      </el-descriptions-item>
    </el-descriptions> -->

    <el-tabs type="border-card">
      <el-table :data="data" style="width: 100%" v-loading="loading">
        <el-table-column prop="card_id" label="卡券id" width="80"> </el-table-column>
        <el-table-column prop="title" label="卡券标题"> </el-table-column>
        <el-table-column prop="subtitle" label="卡券副标题"> </el-table-column>
        <el-table-column prop="gift" label="兑换商品"> </el-table-column>
        <el-table-column prop="start_time" label="开始时间"> </el-table-column>
        <el-table-column prop="end_time" label="结束时间"> </el-table-column>
        <el-table-column prop="description" label="使用说明">
          <template slot-scope="scope">
            <div v-html="scope.row.description"></div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == '1'">未核销</span>
            <span v-if="scope.row.status == '2'">已核销</span>
            <span v-if="scope.row.status == '5'">已过期</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleClick('form')" size="mini" type="primary" :disabled="scope.row.status != '1'">核销</el-button>
          </template>
        </el-table-column
        >
        <!-- <el-table-column label="操作结果">
          <template slot-scope="scope">
            <el-button type="text">
              {{ scope.row.isfail ? "失败" : "成功111" }}
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </el-tabs>
  </div>
</template>
<script>
import { getCouponList, postCouponCode } from '@/api/coupon'

export default {
  data () {
    return {
      loading: false,
      giveLogList: [],
      data: [],
      form: {
        code: ''
      },
      isdisabled: false
    }
  },
  mounted () {
  },
  methods: {
    getTaskTime (strDate) {
      let date = new Date(strDate)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let str = y + '-' + m + '-' + d
      return str
    },
    submitForm (form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          this.loading = true
          await this.getCouponInfo();
        } else {
          this.loading = false
          return false
        }
      })
    },
    async getCouponInfo () {
      this.data = [];
      try {
        const { data: { data } } = await getCouponList(this.form)
        const { id, card_type, end_date, source_type, title, user_id, begin_date, status, card_id } = data.detail
        const { description, gift, subtitle } = data.card_info
        let item = {
          id,
          card_type,
          start_time: this.getTaskTime(new Date(parseInt(begin_date) * 1000)),
          end_time: this.getTaskTime(new Date(parseInt(end_date) * 1000)),
          hasTime: this.getTaskTime(new Date(parseInt(begin_date) * 1000)) + '--' + this.getTaskTime(new Date(parseInt(end_date) * 1000)),
          source_type,
          title,
          user_id,
          gift,
          description,
          subtitle,
          status,
          card_id,
        }
        this.data = [...[], item]
        this.loading = false
      } catch (err) {
        this.data = []
        this.loading = false
      }
    },
    async handleClick () {
      try {
        await postCouponCode(this.form)
        this.$message({ message: '核销成功！', type: 'success' })
        await this.getCouponInfo()
        this.$forceUpdate()
      } catch (err) {
      }
    }
  }
}
</script>
<style scoped lang="scss">
.consumecard {
  .input-with-select {
    width: 150px;
  }
  .hexiao {
    margin-left: 10px;
  }
}
</style>

