<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1"v-loading="postloading" element-loading-text="生成中,请勿刷新页面！">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input  class="input-b" placeholder="请输入兑换码" v-model="params.code" clearable     size="medium">
            <template slot="append">
              <el-button  @click="getCouponActivityCode">搜索</el-button>
            </template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input  class="input-b" placeholder="请输入订单号" v-model="params.customer_order_id" clearable    size="medium">
            <template slot="append">
              <el-button  @click="getCouponActivityCode">搜索</el-button>
            </template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-select
            clearable
            v-model="params.activity_type"
            placeholder="请选择类型"
            @change="getCouponActivityCode"
          >
            <el-option
              label="平台导流"
              value="1"
            ></el-option>
            <el-option
              label="订单兑礼"
              value="2"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-left: 0px;">
        <el-button
          v-if="login_type === 'disti'"
          type="success"
          size="medium"
          icon="el-icon-download"
          @click="exportData('notuse', 1)" v-loading="exportloading">
          导出平台导流可用兑换码
        </el-button>
        <el-button
          v-if="login_type === 'disti'"
          type="info"
          size="medium"
          icon="el-icon-download"
          @click="exportData('used', 1)" v-loading="exportloading">
          导出平台导流已使用兑换码
        </el-button>
        <el-button
          v-if="login_type === 'disti'"
          type="success"
          size="medium"
          icon="el-icon-download"
          @click="exportData('notuse', 2)" v-loading="exportloading">
          导出订单兑礼可用兑换码
        </el-button>
        <el-button
          v-if="login_type === 'disti'"
          type="info"
          size="medium"
          icon="el-icon-download"
          @click="exportData('used', 2)" v-loading="exportloading">
          导出订单兑礼已使用兑换码
        </el-button>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-button
            v-if="login_type === 'disti'"
            type="primary"
            size="medium"
            icon="el-icon-circle-plus"
            @click="addActivityCode">
            添加兑换码
          </el-button>
        </el-col>

      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="待使用" name="notuse"></el-tab-pane>
        <el-tab-pane label="已使用" name="used"></el-tab-pane>
        <el-tab-pane label="已过期" name="overdue"></el-tab-pane>
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="id" width="50" label="ID"></el-table-column>
          <el-table-column prop="code" width="150" label="兑换码">
            <template slot-scope="scope">
              <span style="color:#409EFF">{{scope.row.code}}</span>
              <el-tooltip effect="dark" content="复制" placement="top-start">
              <i v-clipboard:copy="scope.row.code" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
            </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="status" min-width="100" label="使用状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.status === '0' &&  new Date().getTime() < scope.row.overdue_time * 1000">待使用</el-tag>
              <el-tag type="warning" v-if="scope.row.status === '0' &&   new Date().getTime() >= scope.row.overdue_time * 1000">待使用</el-tag>
              <el-tag type="info" v-if="scope.row.status === '0' && new Date().getTime() >= scope.row.overdue_time * 1000">已过期</el-tag>
              <el-tag type="danger" v-if="scope.row.status === '1'">已使用</el-tag>

            </template>
          </el-table-column>
          <el-table-column prop="activity_type" min-width="100" label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.activity_type == '1'"> 平台导流 </span>
              <span v-else>订单兑礼</span>
            </template>
          </el-table-column>
          <el-table-column prop="overdue_time" min-width="200" label="过期时间">
            <template slot-scope="scope">
              <span v-if=" new Date().getTime() >= scope.row.overdue_time * 1000" style="color: #909399">{{ scope.row.overdue_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
              <span v-else>{{ scope.row.overdue_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="customer_order_id" min-width="130" label="使用订单号">
          </el-table-column>
          <el-table-column prop="use_time" width="200" label="使用时间">
            <template slot-scope="scope" >
              <span v-if="scope.row.use_time">{{ scope.row.use_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ImportDialog from '@/components/importDialog'
import {getCouponActivityCode,createCouponActivityCode} from '@/api/promotions'
import {getCouponUserList} from "../../../api/cardticket";
import { useDialog } from '@shopex/finder'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data () {
    return {
      exportloading: false,
      postloading: false,
      dialogTitle: '适用店铺列表',
      dialogId: 0,
      ImportDialogTitle: '',
      is_activity_end: false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        pageSize: 10,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      dialogLoading: true,
      create_time: '',
      params: {
        page: 1,
        pageSize: 10,
        activity_title: '',
        customer_order_id: '',
        activity_type: '',
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    onCopy () {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      });
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getCouponActivityCode()
    },
    dialogToUpload() {
      this.distributorDialogVisible = false
      var dialogBtnId = document.getElementById('coupon_up_' + this.dialogId)
      dialogBtnId.click()
    },
    addActivityCode () {
      useDialog(null, {
        title: `添加兑换码`,
        width: '600px',
        component: () => import('./codekey.vue'),
        actions: [
          { label: '取消', key: 'close', size: 'default' },
          { label: '确定', key: 'save', type: 'primary', size: 'default' }
        ]
      }).then(arg => {
        if(arg) {
          this.createCouponActivityCode(arg)
        }
      })
      // this.$prompt('请输入生成的数量【500以内】', '添加兑换码', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   inputPattern: /^[0-9]+$/,
      //   inputErrorMessage: '请输入正确的数字'
      // }).then(({value}) => {
      //   if (value > 500) {
      //     this.$message({
      //       type: 'info',
      //       message: '生成数量不大于500'
      //     })
      //     return
      //   }
      //   this.createCouponActivityCode(value)
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '生成失败'
      //   })
      // })
    },
    createCouponActivityCode(data) {
      this.postloading = true
      createCouponActivityCode(data).then(response => {
        setTimeout(() => {
          this.$message({
            type: 'success',
            message: '生成成功，请刷新列表查看！'
          })
          this.postloading = false
        }, 1000)
      }).catch(error => {
        this.$message({
          type: 'info',
          message: '生成失败！'
        })
        this.postloading = false
      })
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getCouponActivityCode()
    },
    exportData (status, activity_type) {
      this.exportloading = true
      // this.params.export = 1
      let param = {export: 1, status, activity_type}
      // param.export = 1
      getCouponActivityCode(param).then(res => {
        this.exportloading = false
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (res.data.data.url) {
          this.downloadUrl = res.data.data.url
          this.downloadName = res.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch( error => {
        this.exportloading = false
      })
    },
    checkTag(source, row) {
      if (row.source.includes(source)) {
        return true
      }
      return false
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getCouponActivityCode() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getCouponActivityCode(filter).then(response => {
        this.list = response.data.data.list
        console.log(this.list);
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setCouponStoreList()
    },
    async setCouponStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getCouponActivityShops(this.activity_id,this.bindStorePagination).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
      this.dialogLoading = false
    },
    showDistributors(row) {
      this.dialogLoading = true
      this.activity_id = row.activity_id
      this.distributorDialogVisible = true
      this.dialogTitle = '活动（' + row.activity_title + '）- 适用店铺列表'
      this.dialogId = row.activity_id
      this.setCouponStoreList()
    },
    beforeCloseDistributorDialog(){
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
    },
  },
  mounted () {
    this.getCouponActivityCode()
  },
  watch: {
    '$route' () {
      this.getCouponActivityCode()
    }
  },
}
</script>
<style scoped lang="scss">
.empty-box {
  font-size: 18px;
  color: #999;
  text-align: center;
  height: 300px;
  line-height: 50px;
}
</style>
