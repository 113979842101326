<template>
  <el-drawer
    :title="limitTitle"
    :visible.sync="showDialog"
    direction="rtl"
    custom-class="limit-drawer"
    ref="drawer"
    size='750px'
    @close="onCancelChange"
  >
    <div><ImportDialog v-if="limitType == 'normal'" buttonName="导入限额" style="margin-left: 10px" fileName="导入限额模版" fileType="lottery_normal_winning_num" :relId="limitRowData.marketing_id"></ImportDialog></div>
    <div class="limit-drawer__content">
      <el-tabs v-model="limitTabName" @tab-click="onDialogTabClick" class="dialog-table">
        <el-tab-pane :label="'赠品'+ (idx + 1)" :name="item.gid" v-for="(item, idx) in limitTabList" :key="item.gid" />
        <el-row style="margin-bottom: 10px;">
          <el-col span="auto">
            <span>赠品：{{ giftName }}</span>
          </el-col>
          <el-col :span="5" :offset="1" v-if="limitType == 'limit'">
            <span>总库存：{{ stockNum }}</span>
          </el-col>
        </el-row>
        <el-form ref="limitForm" :model="limitForm" v-if="limitType == 'normal'" class="limitForm">
          <el-button class="btn_input" @click="onDialog('shop')">
            {{ shop_num }}
            <i class="el-icon-circle-plus icon"></i>
          </el-button>
          <el-button class="btn_input" @click="onDialog('sgency')">
            {{ agency_num }}
            <i class="el-icon-circle-plus icon"></i>
          </el-button>
          <el-button type="primary" @click="onSearchChange" v-loading="isLoading">查询</el-button>
          <el-button type="primary" @click="onExportChange" v-loading="isLoading">导出</el-button>
        </el-form>
        <el-table
          :data="tableList"
          style="width: 100%"
          :height="wheight-190"
          v-loading="isLoading"
          element-loading-text="数据加载中"
          v-if="limitType == 'normal'"
          :row-key="getRowKeys"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.round_list" style="margin: 0 auto;" :show-header="false">
                <el-table-column width="100" prop="round_name" />
                <el-table-column prop="overdue_time" label="有效时间">
                  <template slot-scope="props">
                    {{ props.row.start_time | datetime('yyyy-MM-dd hh:mm:ss') }} ~ {{ props.row.end_time | datetime('yyyy-MM-dd hh:mm:ss') }}
                  </template>
                </el-table-column>
                <el-table-column width="180">
                  <template slot-scope="props">
                    <span style="margin-right:10px">中奖限额</span>
                    <el-input @change="onInputChange(scope.$index, scope.row, props.$index, props.row)" style="width:80px" size="small" v-model="props.row.total_quota" />
                  </template>
                </el-table-column>
                <el-table-column width="120">
                  <template slot-scope="props">
                    <span style="margin-right:10px">已用额度</span>
                    <span>{{ props.row.used_quota }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="门店" />
          <el-table-column prop="shop_code" width="140" label="门店Apple ID" />
          <el-table-column prop="stock" width="140" label="库存" />
        </el-table>
        <el-table :data="tableList" style="width: 100%" :height="wheight-190" v-loading="isLoading" element-loading-text="数据加载中" v-else-if="limitType == 'limit'">
          <el-table-column width="120" prop="round_name" label="场次" />
          <el-table-column prop="overdue_time" label="有效时间">
            <template slot-scope="props">
              {{ props.row.start_time | datetime('yyyy-MM-dd hh:mm:ss') }} ~ {{ props.row.end_time | datetime('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="中奖限额" width="200">
            <template slot-scope="scope">
              <span style="margin-right:10px">中奖限额</span>
              <el-input @change="onLimitChange(scope.$index, scope.row)" style="width:80px" size="small" v-model="scope.row.total_quota" placeholder="" />
            </template>
          </el-table-column>
          <el-table-column prop="used_quota" label="已用额度" width="120" />
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            v-if="limitType == 'normal'"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="onDialogSizeChange"
            @current-change="onDialogCurrentChange"
            :current-page.sync="limitForm.page"
            :total='total_count'
            :page-size="limitForm.page_size"
          >
          </el-pagination>
        </div>
      </el-tabs>
    </div>
    <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :distributor-valid="'true,false'" :relDataIds="distributor_info"
       :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
       returnType="selectRow"></DistributorSelect>
    <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers"
      :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
    ></DealerSelect>
  </el-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import { getDistributorQuotaList, setDistributorQuota, getDistributorQuotaInfo } from '@/api/lottery'
import ImportDialog from '@/components/importDialog'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
export default {
  components: { ImportDialog, DistributorSelect, DealerSelect },
  props: {
    limitVisible: {
      type: Boolean,
      default: false
    },
    limitType: { // normal:常规礼品  limit：限定礼品
      type: String,
      default: ''
    },
    limitRowData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      tableList: [],
      limitForm: {
        page: 1,
        page_size: 10,
        distributor_ids: [],
      },
      total_count: 0,
      isLoading: false,
      limitTabName: '',
      limitTabList: [],
      limitTitle: '',
      giftName: '',
      // 所属店铺&经销商
      shop_num: '归属店铺',
      agency_num: '归属经销商',
      dialog_type: 1,
      distributorVisible: false,
      DealerVisible: false,
      DealerStatus: false,
      distributor_info: [],
      setDistributorStatus: false,
      relDealers: [],
      isValid: true,
      stockNum: 0
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type']),
    showDialog: {
      get: function() {
        return this.limitVisible
      },
      set: function(v) {
      }
    }
  },
  watch: {
    limitVisible (newVal) {
      if (newVal) {
        this.limitTabList = this.limitRowData.gift_list
        this.limitTabName = this.limitRowData.gift_list[0].gid
        this.giftName = this.limitRowData.gift_list[0].gift_name
        this.limitTitle = this.limitType == 'normal' ? '常规礼品门店限额' : '限定礼品限额'
        this.getDialogList()
      }
    }
  },
  methods: {
    onDialog(type) {
      if (type === 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    getRowKeys(row) {
      return row.distributor_id;
    },
    getDialogList () {
      this.isLoading = true
      let params = {
        ...this.limitForm,
        gid: this.limitTabName,
        marketing_id: this.limitRowData.marketing_id,
      }
      let apis = ''
      if (this.limitType == 'normal') {
        apis = getDistributorQuotaList
      } else if (this.limitType == 'limit') {
        params['distributor_id'] = 0
        apis = getDistributorQuotaInfo
      }
      apis(params).then((response) => {
        if (response.data.data.list) {
          this.tableList = response.data.data.list || []
          this.total_count = response.data.data.total_count || 0
          this.stockNum = response.data.data.stock || 0
        }
        this.isLoading = false
      })
    },
    onDialogTabClick (tab, event) {
      const findObj = this.limitTabList.find(item => item.gid == tab.name)
      this.limitForm.page = 1
      this.giftName = findObj.gift_name
      this.getDialogList()
    },
    onDialogSizeChange (page_size) {
      this.limitForm.page = 1
      this.limitForm.page_size = page_size
      this.getDialogList()
    },
    onDialogCurrentChange (page_num) {
      this.limitForm.page = page_num
      this.getDialogList()
    },
    onSearchChange () {
      this.isLoading = true
      this.getDialogList()
    },
    onCancelChange () {
      this.limitTitle = ''
      this.limitTabList = []
      this.tableList = []
      this.limitForm = {
        page: 1,
        page_size: 10,
        distributor_ids: [],
        dealer_ids: []
      }
      this.dialog_type = 1
      this.relDealers = []
      this.distributor_info = []
      this.shop_num = '归属店铺'
      this.agency_num = '归属经销商'
      this.$emit('onCloseChange')
    },
    onInputChange (p_index, p_item, index, item) { // 常规礼品-中奖限额校验
      let reg = /^\d*$/
      if (!reg.test(item.total_quota)) {
        this.tableList[p_index].round_list[index].total_quota = 0
        this.$message.error('请输入整数')
        return
      }
      const params = {
        distributor_id: p_item.distributor_id,
        marketing_id: item.marketing_id,
        round_id: item.id,
        gid: this.limitTabName,
        total_quota: item.total_quota
      }
      this.submitDistributorQuota(params)
    },
    onLimitChange (index, item) { // 限定礼品-中奖限额校验
      console.log(item)
      let reg = /^\d*$/
      if (!reg.test(item.total_quota)) {
        this.tableList[index].total_quota = 0
        this.$message.error('请输入整数')
        return
      }
      const params = {
        distributor_id: 0,
        marketing_id: item.marketing_id,
        round_id: item.id,
        gid: this.limitTabName,
        total_quota: item.total_quota
      }
      this.submitDistributorQuota(params)
    },
    async submitDistributorQuota (params) {
      this.isLoading = true
      await setDistributorQuota(params).then(res => {
        this.$message.success('设置成功')
        this.getDialogList()
      }).catch(err => {
        this.isLoading = false
      })
    },
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      })
      this.limitForm.distributor_ids = arr.join(',')
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '归属店铺'
      }
      this.closeDialogAction()
    },
    closeDialogAction() {
      this.limitForm.page = 1
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
      // this.getDialogList()
    },
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.limitForm.dealer_ids = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.closeDialogAction()
    },
    onExportChange () {
      this.isLoading = true
      let params = {
        ...this.limitForm,
        gid: this.limitTabName,
        marketing_id: this.limitRowData.marketing_id,
        is_export: true
      }
      if (this.limitType == 'limit') {
        params['distributor_id'] = 0
      }
      getDistributorQuotaList(params).then(res => {
        this.isLoading = false
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch(error => {
        this.isLoading = false
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.limit-drawer {
  .el-drawer__header {
    margin-bottom: 15px;
  }
  .limitForm {
    margin-bottom: 20px;
  }
  .dialog-table {
    margin: 0px 20px;
  }
}
</style>
