<template>
  <div>
    <el-form ref=form :model=form class=box-set label-width=120px v-loading.fullscreen.lock="dataLoading" :rules="rules">
      <el-card header=活动信息>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="活动名称 "  prop="activity_title">
              <el-input v-model="form.activity_title" class="input-b" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="活动时间"  prop="dateTime">
              <el-date-picker
                v-model="dateTime"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                @change="activityUpDateTimeChange"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" >
            <el-form-item label="可领取下单时间"  prop="dateTimeOrder">
              <el-date-picker
                v-model="dateTimeOrder"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                @change="orderTimeChange"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
              <span  class="frm-tips">指定时间内下单的平台订单适用</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8">
            <el-form-item label="活动Banner" prop="banner">
              <div @click="handleImgPicker('description')" class="upload-box">
                <img v-if="form.banner" :src="form.banner" class="avatar"/>
                <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
              </div>
              <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
            </el-form-item>

          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="适用平台" prop="source">
              <el-checkbox-group v-model="form.source" size="medium" >
                <el-checkbox-button label="jd" name="type">京东到家</el-checkbox-button>
                <el-checkbox-button label="wxapp" name="type">微信小程序</el-checkbox-button>
                <el-checkbox-button label="tb" name="type">淘宝</el-checkbox-button>
                <el-checkbox-button label="eleme" name="type">饿了么</el-checkbox-button>
                <el-checkbox-button label="mt" name="type">美团闪购</el-checkbox-button>
              </el-checkbox-group>
              <el-checkbox-group v-model="form.source" size="medium" style="margin-top: 15px">
                <el-checkbox-button label="jdzy" name="type">京东自营</el-checkbox-button>
                <el-checkbox-button label="dy" name="type">抖音</el-checkbox-button>
                <el-checkbox-button label="amap" name="type" >高德</el-checkbox-button>
                <el-checkbox-button label="qyg" name="type" >企业购小程序</el-checkbox-button>
                <el-checkbox-button label="pos" name="type" >线下单</el-checkbox-button>


              </el-checkbox-group>
              <span  class="frm-tips">指定平台订单可享领取资格</span>
            </el-form-item>
          </el-col>
        </el-row>
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :xs="24" :sm="12" :md="8">-->
        <!--            <el-form-item label="可领取订单金额">-->
        <!--              <el-input v-model="form.total_fee"  placeholder="请输入可领取订单实付金额" class="input-b" maxlength="20" show-word-limit></el-input>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->

        <!--        </el-row>-->

        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="订单限制"  prop="outbound_day">
              <el-input placeholder="大于等于0" v-model="form.outbound_day"  type="number" :min="0">
                <template slot="prepend">订单完成后</template>
                <template slot="append">天可领取</template>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="适用订单门店">
              <span  class="frm-tips">平台订单的履约门店需在指定门店中方可领取，不传门店即为不限，活动保存后在列表中上传门店</span>
            </el-form-item>
          </el-col>

        </el-row>
      </el-card>
      <el-card header="活动赠品规则" v-loading="loader">
        <el-row :gutter="20">
          <el-col :span="2" ><span style="color: #F56C6C">* </span>适用商品</el-col>
          <el-col :span="20">
            <el-button type="primary" class="el-icon-plus" @click="relItems"  size="mini">选择商品</el-button>
            <template>
              <el-table :data="bind_items" stripe border	 highlight-current-row	 style="width: 100%;margin-top: 10px">
                <el-table-column prop="item_bn" label="商品编码" width="200"></el-table-column>
                <el-table-column prop="itemName" label="商品名称" width="200">
                  <template slot-scope="item">
                    {{item.row.itemName}}<br>
                    <span  class="frm-tips">{{item.row.item_spec_desc}}</span>
                  </template>
                </el-table-column>
                <!--                <el-table-column prop="card" label="赠送卡券"></el-table-column>-->
                <el-table-column prop="isValidSwitch" label="是否校验"  width="100">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.isValidSwitch" active-color="#13ce66" inactive-color="#cccccc" @change="switcChange(scope.$index, scope.row.isValidSwitch)" />
                  </template>
                </el-table-column>
                <el-table-column prop="option" label="操作">

                  <template slot-scope="scope">
                    <!--                    <el-button type="text"  size="mini"   icon="el-icon-edit">设置卡券</el-button>-->
                    <!--                    <el-divider direction="vertical"></el-divider>-->
                    <el-button type="text"  size="mini" icon="el-icon-delete" @click="deleteItemRow(scope.$index, bind_items,bind_items.item_id)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <span  class="frm-tips">平台订单中适用赠券的商品，按照订单中购买的赠券商品的数量赠券。</span>

          </el-col>

        </el-row>
        <el-divider></el-divider>

        <el-row :gutter="20">
          <el-col :span="2"><span style="color: #F56C6C">* </span>赠券设置</el-col>
          <el-col :span="20">
               <span  v-if="status==='nostart'" >
              <el-button type="primary" class="el-icon-plus" @click="relGiftsCouponClick" size="mini"round>选优惠券</el-button>
              <span  class="frm-tips">【仅支持选择一种优惠券】</span>
            </span>
            <template>
              <el-table :data="couponList" stripe border	 highlight-current-row	 style="width: 100%;margin-top: 10px">
                <el-table-column prop="card_id" label="券ID" width="180"></el-table-column>
                <el-table-column prop="title" label="券名称" width="180"></el-table-column>
                <el-table-column prop="type" label="卡券类型" width="120">
                  <template slot-scope="card_type">
                    {{card_type.row.card_type|formatCardStr}}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="卡券有效期">
                  <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <template v-if="scope.row.date_type !==  'DATE_TYPE_FIX_TIME_RANGE'">
                      <span v-if="scope.row.end_date > 0">
                        统一过期时间：{{ scope.row.end_date | datetime }}
                        <span style="color: #F56C6C">{{
                            Date.parse(new Date()) > scope.row.end_date * 1000
                              ? "【已过期】"
                              : ""
                          }}</span>
                      </span>
                    </template>
                    <template v-else>
                      {{ scope.row.begin_date | datetime }}
                      <template v-if="scope.row.end_date">~</template>
                      {{ scope.row.end_date | datetime }}
                      <span style="color: #F56C6C">{{
                          new Date().getTime() > scope.row.end_date * 1000
                            ? "【已过期】"
                            : ""
                        }}</span>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column prop="option" label="操作"  v-if="status==='nostart'">
                  <template slot-scope="scope">
                    <el-button type="text"  size="mini" icon="el-icon-delete"  @click="deleteCouponRow(scope.$index, bind_items,bind_items.goods_id)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>


          </el-col>

        </el-row>

      </el-card>
    </el-form>

    <div class=content-center style="margin: 20px 0">
      <el-button
        v-if="this.is_end !== 1"
        type=primary
        @click="submitcouponActivity"
        :loading="submitBtnLoading"
      >保存
      </el-button>
      <el-button @click.native=handleCancel>返回</el-button>
    </div>
    <imgPicker
      :dialog-visible="imgDialog"
      :sc-status="isGetImage"
      @chooseImg="pickImg"
      @closeImgDialog="closeImgDialog"
    ></imgPicker>
    <CouponPicker
      unwantedGift
      :visible="giftCouponVisible"
      :statusIn="true"
      :searchFirstOrder = false
      :get-status="setGiftCouponStatus"
      :rel-items-ids="couponList"
      receive="condition"
      is-spec
      @pickCoupon="pickCouponAction"
      @closeDialog="pickCouponDialogAction"
    ></CouponPicker>
    <GoodsSelectOne :items-visible="itemVisible" :is-spec=true   :item-tier="itemNormal" :get-status="setItemStatus" :rel-items-ids="relItemsIds"  @chooseStore="chooseItemsAction" @closeStoreDialog="closeItemDialogAction"></GoodsSelectOne> <!--AC+-->

  </div>
</template>

<script>
import SkuSelector from '@/components/function/skuSelector'
import CouponPicker from '@/components/coupon_picker'

import imgPicker from '@/components/imageselect'
import videoPicker from '@/components/videoselect'
import GoodsSelectOne from '@/components/goodsSelect'
import {saveCouponActivity,getCouponActivityDetail} from '@/api/promotions'
import {getMarketingActivityList} from "../../../api/promotions";

export default {
  inject: ['refresh'],
  components: {
    videoPicker,
    SkuSelector,
    CouponPicker,
    imgPicker,
    GoodsSelectOne
  },
  data () {
    return {
      imgDialog: false,
      isGetImage: false,
      itemNormal: [
        'normal',
        'ec'
      ],
      itemVisible: false,
      itemAcVisible: false,
      setItemStatus: false,
      relItemsIds: [],
      status: 'nostart',
      ItemsIds: [],
      bind_items: [],
      submitBtnLoading: false,
      is_end: 0,
      dateTime: '',
      dateTimeOrder: '',
      loader: false,
      dataLoading: false,
      giftVisible: false,
      giftCouponVisible: false,
      setGiftCouponStatus: false,
      couponList: [],
      itemsList: [],
      item_ids: [],
      form: {
        activity_title: '',
        outbound_day: 0,
        activity_begin_time: '',
        dateTimeOrder: '',
        dateTime: '',
        activity_end_time: '',
        order_begin_time: '',
        order_end_time: '',
        item_ids: [],
        card_ids: [],
        source: [],
        banner: ''
      },
      rules: {
        activity_title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        dateTimeOrder: [
          { required: true, message: '请选择可领取下单时间', trigger: 'blur' }
        ],
        dateTime: [
          { required: true, message: '请选择活动时间', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '请选择活动banner', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '请选择适用平台', trigger: 'blur' }
        ],
        outbound_day: [
          {required: true, message: '天数不能为空', trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    relItems () {
      this.itemVisible = true
      this.setItemStatus = true
    },
    pickImg (data) {
      this.form.banner = data.url
      this.imgDialog = false
    },
    handleImgPicker (pickerImgType) {
      this.pickerImgType = pickerImgType
      this.imgDialog = true
      this.isGetImage = true
    },
    closeImgDialog () {
      this.imgDialog = false
      this.isGetImage = false
    },
    pickCouponAction (data) {
      this.giftCouponVisible = false
      // 优惠券数据
      if (data.length > 1) {
        this.$message({message: '当前仅支持选择一种优惠券', type: 'error'})
        return
      }
      this.couponList = data
    },
    pickCouponDialogAction (data) {
      this.giftCouponVisible = false
    },
    closeGiftsDialogAction () {
      this.giftVisible = false
    },
    relGiftsCouponClick () {
      this.giftCouponVisible = true
      this.setGiftCouponStatus = true
    },
    getCouponActivityDetail () {
      this.loading = true
      getCouponActivityDetail(this.activity_id).then(response => {
        this.form = response.data.data
        // this.form.activity_begin_time = response.data.data.activity_begin_time * 1000
        // this.form.activity_end_time = response.data.data.activity_end_time * 1000
        // this.form.order_begin_time = response.data.data.order_begin_time * 1000
        // this.form.order_end_time = response.data.data.order_end_time * 1000
        //


        console.log(this.form.order_end_time);
        console.log(this.form.activity_end_time);
        console.log(this.form.activity_begin_time);
        console.log(this.form.order_begin_time);
        this.dateTime = this.form.dateTime = [response.data.data.activity_begin_time * 1000, response.data.data.activity_end_time * 1000]
        this.dateTimeOrder = this.form.dateTimeOrder = [response.data.data.order_begin_time * 1000, response.data.data.order_end_time * 1000]
        this.couponList = response.data.data.cards
        this.bind_items = response.data.data.items
        this.relItemsIds = response.data.data.items
        this.loading = false
        if (response.data.data.activity_begin_time >= new Date().getTime() / 1000) {
          this.status = 'nostart'
        } else if (response.data.data.activity_begin_time <= new Date().getTime() / 1000 && response.data.data.activity_end_time >= new Date().getTime() / 1000) {
          this.status = 'ongoing'
        } else {
          this.status = 'end'
        }
      })
    },
    deleteCouponRow: function (index) {
      this.setGiftCouponStatus = false
      this.couponList.splice(index, 1)
    },
    activityUpDateTimeChange () {
      this.form.dateTime = this.dateTime
      const dateTime = JSON.parse(JSON.stringify(this.dateTime))
      if (dateTime) {
        this.form.activity_begin_time = dateTime[0] === undefined ? 0 : dateTime[0]
        this.form.activity_end_time = dateTime[1] === undefined ? 0 : dateTime[1]
        this.form.activity_begin_time = this.form.activity_begin_time / 1000
        this.form.activity_end_time = this.form.activity_end_time / 1000

      } else {
        this.form.activity_end_time = this.form.activity_begin_time = 0
      }
    },
    orderTimeChange () {
      this.form.dateTimeOrder = this.dateTimeOrder
      const dateTimeOrder = JSON.parse(JSON.stringify(this.dateTimeOrder))
      if (dateTimeOrder) {
        this.form.order_begin_time = dateTimeOrder[0] === undefined ? 0 : dateTimeOrder[0]
        this.form.order_begin_time = this.form.order_begin_time / 1000
        this.form.order_end_time = dateTimeOrder[1] === undefined ? 0 : dateTimeOrder[1]
        this.form.order_end_time = this.form.order_end_time / 1000
      } else {
        this.form.order_begin_time = this.form.order_end_time = 0
      }
    },
    chooseItemsAction (data) {
      this.itemVisible = false
      this.relItemsIds = data
      const onSwitchList = this.bind_items.map(el => {
        if (el.isValidSwitch) {
          return el.item_id
        }
      })
      this.bind_items = []
      if (data === null || data.length <= 0) return
      // if (data.length > 1) {
      //   this.$message({message: '当前仅支持选择一件商品', type: 'error'})
      //   return
      // }
      let arr = []
      data.forEach((item, index) => {
        console.log(item);
        let newData = ''
        let isInArr = this.bind_items.findIndex((n) => n.item_id === item.item_id)
        if (isInArr === -1) {
          newData = {
            item_id: item.item_id,
            itemId: item.item_id,
            itemBn: item.itemBn,
            item_bn: item.itemBn,
            item_name: item.item_name,
            itemName: item.item_name,
            sort: item.sort,
            item_spec_desc: item.item_spec_desc,
            isValidSwitch: item.isValidSwitch
          }
        } else {
          newData = this.bind_items[index]
        }
        if (onSwitchList.includes(item.item_id)) {
          newData.isValidSwitch = true
        }
        arr.push(newData)
      })
      this.bind_items = arr
    },
    closeItemDialogAction () {
      this.itemVisible = false
    },
    handleCancel() {
      this.$router.go(-1)
    },
    deleteItemRow (index, rows ,item_id) {
      const new_list =this.bind_items.filter(el => el.item_id !== item_id)
      this.bind_items = new_list
      rows.splice(index, 1)
      this.bind_items = rows
      console.log(this.bind_items)
      this.setItemStatus = false
      this.relItemsIds = this.bind_items
    },
    getItems(data) {
      let ids = []
      data.forEach(item => {
        ids.push(item.itemId)
        this.itemsList.push(item)
      })
      this.form.item_ids = ids
    },
    submitcouponActivity () {
      this.form.item_ids = []
      this.form.card_ids = []
      this.form.cards = []
      this.form.items = []
      this.bind_items.forEach((item, index) => {
        this.form.item_ids.push(item.item_id+ '_' + (item.isValidSwitch || 'false'))
      })
      this.couponList.forEach((item, index) => {
        this.form.card_ids.push(item.card_id)
      })
      if (this.form.activity_title === '') {
        this.$message({message: '请填写活动名称', type: 'error'})
        return
      }
      if (this.form.banner === '') {
        this.$message({message: '请上传活动banner', type: 'error'})
        return
      }
      if (this.form.activity_begin_time === '' || this.form.activity_end_time === '') {
        this.$message({message: '请选择活动时间', type: 'error'})
        return
      }
      if (this.form.order_begin_time === '' || this.form.order_end_time === '') {
        this.$message({message: '请选择可领取下单时间', type: 'error'})
        return
      }
      if (this.form.source.length <= 0) {
        this.$message({message: '请选择适用平台', type: 'error'})
        return
      }
      if (this.form.item_ids.length <= 0) {
        this.$message({message: '请选择适用商品', type: 'error'})
        return
      }
      if (this.form.card_ids.length <= 0) {
        this.$message({message: '请选择优惠券', type: 'error'})
        return
      }
      if (this.form.outbound_day < 0) {
        this.$message({message: '请输入正确的订单出库限制天数', type: 'error'})
        return
      }
      if (this.is_edit) {
        this.form.activity_id = this.activity_id
      }
      saveCouponActivity(this.form).then(res => {
        if (res.data.data) {
          this.$message({
            message: this.is_edit ? '更新成功' : '保存成功',
            type: 'success',
            duration: 2 * 1000
          })
          this.handleCancel()

        } else {
          this.$message.error(this.is_edit ? '更新失败！' : '保存失败！')
          return false
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    switcChange (index, cval) {
      this.bind_items[index].isValidSwitch = cval
      this.relItemsIds[index].isValidSwitch = cval
    }
  },
  mounted () {
    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id
      this.is_edit = true
      this.getCouponActivityDetail()
    }
    this.loader = false
  },
}
</script>

<style>
.vue-html5-editor {
  vertical-align: top;
  display: inline-block;
}
.vue-html5-editor {
  width: 80%;
}
</style>
