<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <SpFilterForm :model="formData" @onSearch="onSearch" @onReset="onReset">
        <SpFilterFormItem prop="status" label="">
          <el-input v-model="formData.activity_title" placeholder="请输入活动标题" />
        </SpFilterFormItem>
        <SpFilterFormItem prop="login_name" label="">
          <el-input v-model="formData.activity_id" placeholder="活动ID" />
        </SpFilterFormItem>
        <SpFilterFormItem prop="activity_times" label="">
          <el-date-picker
            v-model="formData.activity_times"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </SpFilterFormItem>
      </SpFilterForm>
      <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="250" :row-actions-align="'left'"
        :fixed-row-action="true" url="/promotions/bundleDiscount/list" :hooks="{
          beforeSearch: beforeSearch,
          afterSearch: afterSearch
        }" :split-count="5">
        <template slot="tableTop">
          <el-tabs v-model="activeName" type="card" @tab-click="onSearch">
            <el-tab-pane label="全部" name="0"></el-tab-pane>
            <el-tab-pane label="未开始" name="1"></el-tab-pane>
            <el-tab-pane label="进行中" name="2"></el-tab-pane>
            <el-tab-pane label="已失效" name="3"></el-tab-pane>
          </el-tabs>
        </template>
      </SpFinder>
    </div>
    <SpStoreDialog
      title="查看参与门店"
      :visible="shopDialog"
      :isdetail="shopSelectRow.status_desc == 'invalid'"
      :params="{ activity_id: shopSelectRow.activity_id }"
      url="/promotions/bundleDiscount/distributors"
      @onDelete="onDeleteShopChange"
      @onCloseDialog="onShopDialogChange"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { createSetting } from '@shopex/finder'
import SpStoreDialog from './comps/sp-store-dialog'
import { isArray } from '@/utils';
import {copyBundleDiscount, deleteBundleDistributorList} from '@/api/discount'
export default {
  components: {
    SpStoreDialog
  },
  data() {
    return {
      setting: createSetting({
        columns: [
          { name: '活动ID', key: 'activity_id' },
          { name: '活动标题', key: 'activity_title' },
          { name: '状态', key: 'status_msg' },
          { name: '有效时间', key: 'last_updated_time', render: (h, { row }) => h('span', row.start_time ? [row.start_time, ' - ', row.end_time] : '')},
          { name: '创建时间', key: 'created', render: (h, { row }) => h('span', [row.created]) },
          {
            name: '优先级',
            key: 'sort' ,
            headRender: (h) => {
              return (
                <div>
                  优先级
                  <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    content="排序数值越大，活动展示的位置越靠前，数值相同则参考创建时间由近到远排序"
                  >
                    <span slot="reference"><i class="el-icon-info"></i></span>
                  </el-popover>
                </div>
              )
            },
            render: (h, { row }) => {
              return <el-input v-model={row.sort} placeholder='序号' on-change={(value) => this.onSortChange(row, value)} />
            }
          },
        ],
        actions: [
          {
            name: '查看',
            key: 'show',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: async (row) => {
                this.$router.push({ path: this.matchHidePage('editor'), query: { activity_id: row[0].activity_id, detail: true } })
              }
            }
          },
          {
            name: '编辑',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.status_desc == 'going' || row.status_desc == 'waiting' || !row.start_time
            },
            action: {
              handler: async (row) => {
                this.$router.push({ path: this.matchHidePage('editor'), query: { activity_id: row[0].activity_id, edit: true } })
              }
            }
          },
          {
            name: '复制',
            key: 'bind',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: async (row) => {
                // this.$router.push({ path: this.matchHidePage('editor'), query: { activity_id: row[0].activity_id, copy: true} })
                this.handleCopyActivity(row[0]);
              }
            }
          },
          {
            name: '中止',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.status_desc == 'going'
            },
            action: {
              handler: (row) => {
                this.handleRevertExamineDialog(row[0])
              }
            }
          },
          {
            name: '删除',
            key: 'delete',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.status_desc == 'waiting'
            },
            action: {
              handler: (row) => {
                this.handleDeleteDialog(row[0])
              }
            }
          },
          {
            name: '查看门店',
            key: 'delete',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.distributor_type != 'all'
            },
            action: {
              handler: (row) => {
                this.handleDeleteShopDialog(row[0])
              }
            }
          },
          {
            name: '创建活动',
            key: 'add',
            slot: 'header',
            type: 'button',
            buttonType: 'primary',
            size: 'small',
            action: {
              type: 'link',
              handler: (row) => {
                this.$router.push({ path: this.matchHidePage('editor') })
                // this.$router.push({
                //   path: '/marketing/coupon/membermarketing/membermarketing_editor',
                //   // query: { operator_id: row[0].operator_id }
                // })
              }
            }
          }
        ]
      }),
      formData: {
        activity_title: '',
        activity_id: '',
        activity_times: []
      },
      activeName: '0',
      shopDialog: false,
      shopSelectRow: {}
    }
  },
  methods: {
    onSortChange (row, value) {
      this.$api.discount.updateBundleDiscountSort({ sort: value, activity_id: row.activity_id }).then(res => {
        this.$message.success('修改成功!')
        this.$refs.finder.refresh()
      }).catch(err => {
        this.$message.error('修改失败!')
        this.$refs.finder.refresh()
      })
    },
    beforeSearch(params) {
      const { activity_times, activity_title, activity_id } = this.formData
      const pamars = {
        ...params,
        status: this.activeName,
        start_time: activity_times ? activity_times[0] : '',
        end_time: activity_times ? activity_times[1] : '',
        activity_title,
        activity_id
      }
      return pamars
    },
    afterSearch(res) {
      const { data } = res.data
      return data
    },
    async handleRevertExamineDialog(row) {
      this.$confirm('确定中止活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          await this.$api.discount.stopBundleDiscount({ activity_id: row.activity_id })
          this.$message({
            type: 'success',
            message: '中止成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => { })
    },
    async handleCopyActivity(row) {
      this.$confirm('确定复制活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$api.discount.copyBundleDiscount({ activity_id: row.activity_id })
        console.log(res)
        this.$message({
          type: 'success',
          message: '复制成功!'
        })
        this.$nextTick(() => {
          this.$router.push({ path: this.matchHidePage('editor'), query: { activity_id: res.activity_id, edit: true } })
          // this.$refs.finder.refresh()
        })
      })
        .catch(() => { })
    },
    async handleDeleteDialog(row) {
      this.$confirm('确定删除活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
          await this.$api.discount.deleteBundleDiscount({ activity_id: row.activity_id })
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => { })
    },
    handleDeleteShopDialog (row) {
      this.shopDialog = true
      this.shopSelectRow = row
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onReset () {
      this.formData = {
        activity_title: '',
        activity_id: '',
        activity_times: []
      }
      this.$refs.finder.refresh(true)
    },
    async onDeleteShopChange (value, callback) {
      console.log(value, '----onDeleteShopChange---')
      const params = {
        id: isArray(value) ? value.join(',') : value,
        activity_id: this.shopSelectRow.activity_id
      }
      deleteBundleDistributorList(params).then(res => {
        this.$message.success('删除成功!')
        callback()
      })
    },
    onShopDialogChange () {
      this.shopDialog = false
      this.shopSelectRow = {}
      this.$refs.finder.refresh()
    },
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.sp-finder-hd {
  padding-top: 10px !important;
}

.view-template-dialog {
  .sp-finder-hd {
    display: none;
  }

  .el-dialog__body {
    padding: 0 !important;

    .el-form {
      margin: 0 !important;
    }

    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
}
</style>
