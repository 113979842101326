import { render, staticRenderFns } from "./receiveDialog.vue?vue&type=template&id=3db0eec8&scoped=true"
import script from "./receiveDialog.vue?vue&type=script&lang=js"
export * from "./receiveDialog.vue?vue&type=script&lang=js"
import style0 from "./receiveDialog.vue?vue&type=style&index=0&id=3db0eec8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db0eec8",
  null
  
)

export default component.exports