<template>
  <div v-if="isValid">
    <el-form ref="form" :model="formParams" label-width="110px">

      <el-form-item label="口令码生成方式">
        <el-radio-group v-model="formParams.password_create_type" @input="passwordCreateType" :disabled="isDisabled">
          <el-radio-button label="system">系统生成</el-radio-button>
          <el-radio-button label="manual">手动导入</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="口令码输入项名称定义">
        <el-input type="text" maxlength="4" v-model="formParams.password_custom_title" @blur="changeForm"></el-input>
      </el-form-item>

      <el-form-item label="口令码填写说明">
        <el-input type="text" v-model="formParams.password_custom_remark" @blur="changeForm"></el-input>
      </el-form-item>

      <div v-if="formParams.password_create_type == 'manual'">

        <el-form-item label="口令码形式">
          <el-radio-group v-model="formParams.password_manual_type" @input="passwordManualYype">
            <el-radio label="upload">按导入</el-radio>
            <el-radio label="unified">统一口令码</el-radio>
          </el-radio-group>
        </el-form-item>

        <div v-if="formParams.password_manual_type == 'upload'">
          <el-form-item label="自定义校验项">

            <div>
              <el-card class="box-card" v-for="(item, index) in formParams.password_custom_data" :key="'custom' + index"
                shadow="never">
                <div slot="header" class="clearfix">
                  <span>校验项{{ index + 1 }}</span>
                  <el-button class="detile-btn" type="text" @click="deleteStandard(index)">删除</el-button>
                </div>
                <el-form-item label="名称">
                  <el-input type="text" maxlength="4" v-model="item.custom_title"></el-input>
                </el-form-item>
                <el-form-item label="填写说明">
                  <el-input type="text" v-model="item.custom_remark"></el-input>
                </el-form-item>
              </el-card>
            </div>

            <div class="mag">添加了“自定义校验项”导入时会校验导入文件是否填写，且不校验唯一性，用户端领取时必须填写校验项进行一致性校验</div>

            <el-button type="primary" plain @click="addStandard">添加校验项</el-button>
          </el-form-item>
        </div>

        <div v-else>
          <el-form-item label="口令码">
            <el-input type="text" v-model="formParams.password_manual_code" @blur="changeForm"></el-input>
          </el-form-item>
        </div>

      </div>

    </el-form>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      rules: [],
      formParams: {
        password_create_type: 'system',
        password_manual_type: 'upload',
        password_custom_title: '',
        password_custom_remark: '',
        password_manual_code: '',
        password_custom_data: []
      }
    }
  },
  props: {
    isValid: {
      type: Boolean,
      default: false
    },
    formValues: {
      type: Object,
      default: {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    isValid(val) {
      if (val) {
        // this.changeForm()
      }
    },
    'formValues': {
      handler(nVal) {
        if (nVal.password_create_type) {
          this.formParams = nVal
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    passwordCreateType(v) {
      if (v == 'system') {
        this.formParams.password_custom_data = []
        this.formParams.password_manual_code = ''
      }
      this.changeForm()
    },
    passwordManualYype(v) {
      if (v == 'upload') {
        this.formParams.password_manual_code = ''
      } else {
        this.formParams.password_custom_data = []
      }
      this.changeForm()
    },
    addStandard() {
      let arr = [...this.formParams.password_custom_data]
      arr.push({
        custom_title: '',
        custom_remark: '',
      })
      this.formParams.password_custom_data = arr
    },
    deleteStandard(index) {
      this.formParams.password_custom_data.splice(index, 1)
      this.changeForm()
    },
    changeForm() {
      this.$emit('changeForm', this.formParams)
    }
  }
}

</script>

<style scoped lang="scss">
.el-form {
  .el-form-item {
    width: 700px;
  }
}

.box-card {
  .el-form-item {
    width: 400px;
  }
}

.mag {
  font-size: 12px;
  line-height: 20px;
  color: #c0c4cc;
  margin: 20px 0px;
}

.clearfix {
  height: 20px;
  line-height: 20px
}

.detile-btn {
  padding: 0px;
  float: right;
}
</style>
