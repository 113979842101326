<template>
  <el-drawer
    :title="dialogTitle"
    :visible.sync="showDialog"
    direction="rtl"
    custom-class="record-drawer"
    ref="drawer"
    :size='dialogWidth'
    @close="onCancelChange"
  >
    <div class="record-drawer__content">
      <el-row style="margin-bottom: 10px; padding-left: 20px;" v-if="dialogType == 'lottery'">
        <el-col :span="5">
          <span>抽奖数：</span>
          <span>{{ record_count }}</span>
        </el-col>
        <el-col :span="5">
          <span>中奖数：</span>
          <span>{{ winning_count }}</span>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 10px; padding-left: 20px;" v-else>
        <el-col :span="5">
          <span>活动：{{ dialogRowData.marketing_title }}</span>
        </el-col>
      </el-row>
      <el-form ref="dialogForm" :model="dialogForm" label-width="100px" class="dialogForm" v-if="dialogType == 'lottery'">
        <el-date-picker
          style="width: 400px"
          v-model="selectime"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          @change="onDataChange"
        ></el-date-picker>
        <el-button type="primary" @click="onLottryExportChange" v-loading="dialogLoading">导出记录</el-button>
      </el-form>
      <el-form ref="dialogForm" :model="dialogForm" class="dialogForm" v-else>
        <el-input placeholder="中奖者手机号" clearable v-model="dialogForm.receive_mobile" style="width:160px" v-if="isLimitType" />
        <el-button class="btn_input" @click="onDialog('shop')" v-if="login_type != 'distributor'">
            {{ shop_num }}
            <i class="el-icon-circle-plus icon"></i>
          </el-button>
          <el-button class="btn_input" @click="onDialog('sgency')" v-if="login_type == 'disti'">
            {{ agency_num }}
            <i class="el-icon-circle-plus icon"></i>
          </el-button>
          <el-date-picker
            v-if="isLimitType"
            style="width: 400px"
            v-model="selectime"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            type="datetimerange"
            range-separator="至"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
          />
        <el-button type="primary" v-if="login_type != 'distributor'" @click="onSearchChange" v-loading="dialogLoading">查询</el-button>
        <el-button type="primary" @click="onWinnerExportChange" v-loading="dialogLoading">导出名单</el-button>
      </el-form>
      <el-tabs v-model="dialogTabName" @tab-click="onDialogTabClick" class="dialog-table">
        <el-tab-pane :label="(item.num == 0 || item.num > 0) ? `${item.value}(${item.num})` : item.value" :name="item.key" v-for="item in dialogTabList" :key="item.key" />
        <el-table :data="tableList" style="width: 100%" :height="wheight-300" v-loading="dialogLoading" element-loading-text="数据加载中" v-if="dialogType == 'lottery'">
          <el-table-column prop="dummy_id" width="100" label="Dummy ID"></el-table-column>
          <el-table-column prop="lottery_time" width="160" label="抽奖时间">
            <template slot-scope="scope">
              <span v-if="scope.row.lottery_time > 0">{{ scope.row.lottery_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="gift_name" min-width="200" label="中奖礼品" />
          <el-table-column width="100" label="抽奖结果">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 'success' ? '中奖' : scope.row.status == 'fail' ? '未中奖' :''}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="winning_code" width="200" label="中奖编码" />
          <el-table-column prop="shop_code" width="100" label="AppleID" />
          <el-table-column prop="distributor_name" label="门店名称" />
        </el-table>
        <el-table :data="tableList" style="width: 100%" :height="wheight-300" v-loading="dialogLoading" element-loading-text="数据加载中" v-else-if="dialogType == 'winner'">
          <el-table-column prop="winning_code" width="100" label="中奖编号" />
          <el-table-column prop="receive_mobile" width="100" label="领取手机号" v-if="isLimitType">
            <template slot-scope="scope">
              <span v-if="scope.row.receive_mobile">
                {{ scope.row.receive_mobile }}
                <PiiInfo pageType='2' memberType='receiver_mobile' optionScene="lottery_detail"  :associatedId='scope.row.id'/>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="gift_name" width="130" label="中奖礼品" />
          <el-table-column prop="title" width="180" label="核销门店" v-if="login_type != 'distributor'">
            <template slot-scope="scope">
              <span>{{ scope.row.distributor_info.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="title" width="130" label="门店Apple ID" v-if="login_type != 'distributor'">
            <template slot-scope="scope">
              <span>{{ scope.row.distributor_info.shop_code }}</span>
            </template>
          </el-table-column>
          <el-table-column width="100" label="状态" prop="status_desc">
            <template slot-scope="scope">
              <span :style="`color:${scope.row.status_desc.text_color}`">{{ scope.row.status_desc.text }}</span>
            </template>
          </el-table-column>
          <el-table-column width="160" label="预约时间" v-if="isLimitType">
            <template slot-scope="scope">
              <span v-if="scope.row.submit_mobile_time > 0">{{ scope.row.submit_mobile_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="receive_time" width="160" label="核销时间">
            <template slot-scope="scope">
              <span v-if="scope.row.receive_time > 0">{{ scope.row.receive_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="关联订单" v-if="isLimitType">
            <template slot-scope="scope">
              {{ scope.row.limit_info.order_id }}
            </template>
          </el-table-column>
          <el-table-column width="130" label="订单状态" v-if="isLimitType">
            <template slot-scope="scope">
              <span>{{ scope.row.order_info.order_status_msg }}</span>
            </template>
          </el-table-column>
          <el-table-column width="100" label="操作" v-if="login_type == 'distributor' && isLimitType">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.status == 'init' && scope.row.notice_status == 0 && scope.row.apply_aftersales_07_disable" @click="onClickChange(scope.row)">货已到店</el-button>
              <span v-else-if="scope.row.notice_status == 1">已通知</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="onDialogSizeChange"
            @current-change="onDialogCurrentChange"
            :current-page.sync="dialogForm.page"
            :total='total_count'
            :page-size="dialogForm.page_size"
          >
          </el-pagination>
        </div>
      </el-tabs>
    </div>
    <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
       :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
       returnType="selectRow"></DistributorSelect>
    <DealerSelect :store-visible="DealerVisible" :is-valid="true" :rel-data-ids="relDealers"
      :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
    ></DealerSelect>
  </el-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import { getActivityWinningList, sendNoticeMsg, getActivityRecordList } from '@/api/lottery'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import PiiInfo from '@/components/piiInfo/index'

export default {
  components: { DistributorSelect, DealerSelect, PiiInfo },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogType: { // lottery：抽奖记录 winner：中奖名单
      type: String,
      default: ''
    },
    dialogRowData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      tableList: [],
      dialogForm: {
        page: 1,
        page_size: 10,
        receive_mobile: '',
        distributor_ids: [],
        dealer_ids: []
      },
      selectime: '',
      total_count: 0,
      winning_count: 0,
      record_count: 0,
      dialogLoading: false,
      dialogTabName: '',
      dialogTabList: [],
      dialogTitle: '',
      dialogWidth: '',
      // 所属店铺&经销商
      shop_num: '归属店铺',
      agency_num: '归属经销商',
      dialog_type: 1,
      distributorVisible: false,
      DealerVisible: false,
      DealerStatus: false,
      distributor_info: [],
      setDistributorStatus: false,
      relDealers: [],
      isLimitType: ''
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type']),
    showDialog: {
      get: function() {
        return this.dialogVisible
      },
      set: function(v) {
      }
    }
  },
  watch: {
    dialogVisible (newVal) {
      if (newVal) {
        this.getDialogList()
        this.isLimitType = this.dialogRowData.marketing_type == 'limit'
      }
    },
    dialogType (val) {
      if (val == 'lottery') {
        this.dialogTabList = [
          {key: '', value: '全部'},
          {key: '1', value: '中奖'},
          {key: '2', value: '未中奖'},
        ]
        this.dialogWidth = '80%'
        this.dialogTitle = '抽奖记录'
      } else if (val == 'winner') {
        this.dialogTabList = [
          {key: '', value: '全部'},
          {key: '1', value: '未预约', num: 0},
          {key: '2', value: '未核销', num: 0},
          {key: '3', value: '已核销'},
        ]
        this.dialogWidth = '80%'
        this.dialogTitle = '中奖名单'
      }
    }
  },
  methods: {
    onDialog(type) {
      if (type === 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    getDialogList () {
      this.dialogLoading = true
      if (this.dialogType == 'winner' ) { // 中奖记录
        const params = {
          ...this.dialogForm,
          status_filter: this.dialogTabName,
          lottery_time_start	: this.selectime && this.selectime[0] / 1000,
          lottery_time_end	: this.selectime && this.selectime[1] / 1000,
          marketing_id: this.dialogRowData.marketing_id
        }
        getActivityWinningList(params).then((response) => {
          if (response.data.data.list) {
            this.tableList = response.data.data.list || []
            this.total_count = response.data.data.total_count || 0
            this.dialogTabList[1].num = response.data.data.tab1_num || 0
            this.dialogTabList[2].num = response.data.data.tab2_num || 0
          }
          this.dialogLoading = false
        })
      } else if (this.dialogType == 'lottery') { // 抽奖记录
        const params = {
          ...this.dialogForm,
          status_filter: this.dialogTabName,
          time_start	: this.selectime && this.selectime[0] / 1000,
          time_end	: this.selectime && this.selectime[1] / 1000,
          marketing_id: this.dialogRowData.marketing_id
        }
        getActivityRecordList(params).then((response) => {
          if (response.data.data.list) {
            this.tableList = response.data.data.list
            this.total_count = response.data.data.total_count || 0
            this.record_count = response.data.data.record_count || 0
            this.winning_count = response.data.data.winning_count || 0
          }
          this.dialogLoading = false
        })
      }
    },
    onDataChange () {
      this.dialogForm.page = 1
      this.getDialogList()
    },
    onDialogTabClick () {
      this.dialogForm.page = 1
      this.getDialogList()
    },
    onDialogSizeChange (size) {
      this.dialogForm.page = 1
      this.dialogForm.page_size = size
      this.getDialogList()
    },
    onDialogCurrentChange (page_num) {
      this.dialogForm.page = page_num
      this.getDialogList()
    },
    onSearchChange () {
      this.dialogLoading = true
      this.getDialogList()
    },
    onLottryExportChange () {
      this.dialogLoading = true
      const params = {
        ...this.dialogForm,
        status_filter: this.dialogTabName,
        time_start	: this.selectime && this.selectime[0] / 1000,
        time_end	: this.selectime && this.selectime[1] / 1000,
        marketing_id: this.dialogRowData.marketing_id,
        is_export: 1
      }
      getActivityRecordList(params).then(res => {
        this.dialogLoading = false
        if (res.data.data.success) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch(error => {
        this.dialogLoading = false
      })
    },
    onWinnerExportChange () {
      const params = {
        ...this.dialogForm,
        status_filter: this.dialogTabName,
        lottery_time_start	: this.selectime && this.selectime[0] / 1000,
        lottery_time_end	: this.selectime && this.selectime[1] / 1000,
        marketing_id: this.dialogRowData.marketing_id,
        is_export: 1
      }
      getActivityWinningList(params).then(res => {
        this.dialogLoading = false
        if (res.data.data.success) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch(error => {
        this.dialogLoading = false
      })
    },
    onCancelChange () {
      this.dialogTitle = ''
      this.dialogWidth = ''
      this.dialogTabList = []
      this.tableList = []
      this.dialogForm = {
        page: 1,
        page_size: 10,
        receive_mobile: '',
        distributor_ids: [],
        dealer_ids: []
      }
      this.selectime = ''
      this.shop_num = '归属店铺'
      this.agency_num = '归属经销商'
      this.relDealers = []
      this.distributor_info = []
      this.$emit('onCloseChange')
    },
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      })
      this.dialogForm.distributor_ids = arr.join(',')
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '归属店铺'
      }
      this.closeDialogAction()
    },
    closeDialogAction() {
      this.dialogForm.page = 1
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
    },
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.dialogForm.dealer_ids = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.closeDialogAction()
    },
    onClickChange (row) {
      console.log(row)
      this.dialogLoading = true
      sendNoticeMsg({ id: row.id }).then((res) => {
        this.$message.success('通知成功')
        this.getDialogList()
      }).catch(error => {
        this.getDialogList()
      })
    }
  },
}
</script>
<style scoped lang="scss">
.record-drawer {
  .el-drawer__header {
    margin-bottom: 15px;
  }
  .dialogForm {
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .dialog-table {
    margin: 0px 20px;
  }
}
</style>
