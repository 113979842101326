<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row :gutter="30">
        <el-col :span="7">
          <el-input  class="input-b" placeholder="请输入活动名称" v-model="params.activity_title">
            <template slot="prepend">活动名称</template>
          </el-input>
        </el-col>
        <el-col :span="10">
          创建时间
          <el-date-picker
            v-model="params.updated"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="开始时间"
            align="right" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" size="medium" @click="getCouponActivityList">查询</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="4" :lg="8">
          <el-button
            v-if="login_type === 'disti'"
            type="primary"
            size="medium"
            icon="el-icon-circle-plus"
            @click="addActivityData">
            添加营销活动
          </el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="进行中" name="going"></el-tab-pane>
        <el-tab-pane label="未开始" name="not_start"></el-tab-pane>
        <el-tab-pane label="已结束" name="stopped"></el-tab-pane>
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中" >
          <el-table-column  type="expand">
            <template slot-scope="scope">
              <el-link type="success" style="margin-left: 30px">参与平台：｜ </el-link>
              <span style="color:#F56C6C " v-if="checkTag('jd',scope.row)"> 京东到家 ｜</span>
              <span style="color:#E6A23C " v-if="checkTag('tb',scope.row)">淘宝 ｜</span>
              <span style="color:#E6A23C " v-if="checkTag('mt',scope.row)">美团闪购 ｜</span>
              <span style="color:#909399 " v-if="checkTag('wxapp',scope.row)">微信小程序 ｜</span>
              <span style="color:#909399 " v-if="checkTag('dy',scope.row)">抖音 ｜</span>
              <span style="color:#F56C6C " v-if="checkTag('jdzy',scope.row)">京东自营 ｜</span>
              <span style="color:#409EFF " v-if="checkTag('eleme',scope.row)">饿了么 ｜</span>
              <span style="color:#409EFF " v-if="checkTag('amap',scope.row)">高德 ｜</span>
              <span style="color:#909399 " v-if="checkTag('qyg',scope.row)">企业购小程序 ｜</span>
              <span style="color:#909399 " v-if="checkTag('pos',scope.row)">线下单 ｜</span>
            </template>
          </el-table-column>
          <el-table-column prop="activity_id" width="50" label="ID"></el-table-column>
          <el-table-column prop="activity_title" width="300" label="活动名称">
            <template slot-scope="scope">
              <div >
                {{ scope.row.activity_title}}
              </div>

                <div class="order-store" style="font-size: 12px;">

                </div>
            </template>
          </el-table-column>
          <el-table-column prop="total_fee" min-width="50" label="活动状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.activity_begin_time >= new Date().getTime()/1000" type="warning" effect="light" size="mini">未开始</el-tag>
              <el-tag v-else-if="scope.row.activity_begin_time <= new Date().getTime()/1000 && scope.row.activity_end_time >= new Date().getTime()/1000" type="success" effect="light" size="mini">进行中</el-tag>
              <el-tag v-else type="danger"  size="mini" effect="light">已结束</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="total_fee" min-width="130" label="有效时间">
            <template slot-scope="scope">
              <div>
                <template>
                  {{ scope.row.activity_begin_time | datetime("yyyy-MM-dd hh:mm:ss") }} ～
                  {{scope.row.activity_end_time | datetime("yyyy-MM-dd hh:mm:ss")}}
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="created" width="180" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.created | datetime("yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="适用范围" width="100">
            <template slot-scope="scope">
              <div>

<!--                <el-button type="warning" size="mini" round  @click="showDistributors(scope.row)">查看适用商品</el-button>-->
                <el-button type="text"  @click="showDistributors(scope.row)">查看适用店铺</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="120">
            <template slot-scope="scope">
              <div>
                <ImportDialog
                  v-if="login_type === 'disti'"
                  buttonName="导入适用门店"
                  :buttonId="'coupon_up_'+scope.row.activity_id"
                  buttonType="text"
                  buttonSize="medium"
                  fileName="上传平台优惠券店铺模版"
                  fileType="coupon_activity_distributors"
                  :relId="scope.row.activity_id"
                  :title="'活动（'+scope.row.activity_title+'）- 适用门店导入'"
                ></ImportDialog>
<!--                <ImportDialog-->
<!--                  v-if="login_type === 'disti'"-->
<!--                  buttonName="导入商品"-->
<!--                  buttonType="text"-->
<!--                  fileName="上传活动商品模版"-->
<!--                  fileType="offline_activity_distributors"-->
<!--                  :relId="scope.row.activity_id"-->
<!--                ></ImportDialog>-->
                <el-divider direction="vertical"></el-divider>
                <el-button type="text"  v-if="login_type === 'disti'" @click="editActivityAction(scope.$index, scope.row)">编辑</el-button>
                <el-button type="text"  v-if="scope.row.activity_begin_time >= new Date().getTime()/1000" @click="delActivityAction(scope.$index, scope.row)" >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-dialog
      :before-close="beforeCloseDistributorDialog"
      :title=dialogTitle
      :visible.sync="distributorDialogVisible"
      width="60%">
      <div v-loading="dialogLoading"  element-loading-text="数据加载中">
        <div  v-if="distributorList.length > 0">
          <el-input placeholder="AppleId" class="input-b" v-model="bindStorePagination.shop_code" clearable></el-input>
          <el-input placeholder="店铺名称" class="input-b" v-model="bindStorePagination.distributor_name" clearable></el-input>
          <el-button type="primary" icon="el-icon-search" @click="setCouponStoreList"></el-button>
          <el-button type="primary" @click="batchDeleteDistributor">删除</el-button>
        </div>
        <div class="empty-box"  v-if="distributorList.length <= 0 ">
            <div style="height: 200px;">
              <i class="el-icon-menu" style="font-size: 160px;color: #ecebeb"></i>
            </div>
            <span style="color:#409EFF;font-size: 16px"><i class="el-icon-warning-outline"></i> 当前未上传平台订单的履约门店，即为不限门店</span>
            <br>
            <el-button type="primary" @click="dialogToUpload">导入适用门店<i class="el-icon-upload el-icon--right"></i></el-button>

        </div>
        <el-table
          v-if="distributorList.length > 0 "
          :data="distributorList"
          ref="distributorTable"
          @selection-change="handleSelectionChange"
          row-key="distributor_id"
          style="line-height: normal"
        >
          <template>
            <el-table-column type="selection" align="center" label="全选" :reserve-selection="true"></el-table-column>
            <el-table-column label="门店名称" prop="name"></el-table-column>
            <el-table-column
              label="AppleID"
              prop="shop_code"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="dealer_name"
              label="所属经销商"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              width="100"
              prop=""
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteDistributor(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="content-padded content-center" v-if="distributorList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            @current-change="bindStoreCurrentChange"
            :current-page.sync="bindStorePagination.page"
            :total='totalStoreCount'
            :page-size="bindStorePagination.pageSize">
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getBindOfflineActivityStoreList,
  changeDistributorJoinStatus,
  syncLingzhiOfflineActivityDistributors,
} from '@/api/promotions'
import ImportDialog from '@/components/importDialog'
import {getCouponActivity,getCouponActivityShops,delCouponActivity,delCouponActivityShops} from '@/api/promotions'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start,end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start,end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime());
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start,end]);
          }
        }]
      },
      dialogTitle: '适用店铺列表',
      dialogId: 0,
      ImportDialogTitle: '',
      is_activity_end: false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      bindStorePagination: {
        distributor_name: '',
        shop_code: '',
        status: '',
        page: 1,
        pageSize: 10,
      },
      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      dialogLoading: true,
      create_time: '',
      params: {
        page: 1,
        pageSize: 10,
        activity_title: '',
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
      choseShopCodeData: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    handleSelectionChange (val) {
      let shopCodeData = []
      for (let i in val) {
        shopCodeData.push(val[i].shop_code)
      }
      this.choseShopCodeData = shopCodeData
    },
    deleteDistributor (row) {
      delCouponActivityShops(this.activity_id, {
        shop_code: row.shop_code
      }).then(response => {
        this.$message({
          type: 'success',
          message: '删除成功'
        })
        this.setCouponStoreList()
      })
    },
    batchDeleteDistributor () {
      if (this.choseShopCodeData.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择要删除的店铺'
        })
        return false
      }
      delCouponActivityShops(this.activity_id, {
        shop_code: this.choseShopCodeData.join(',')
      }).then(response => {
        this.$message({
          type: 'success',
          message: '删除成功'
        })
        this.choseShopCodeData = []
        this.$refs['distributorTable'].clearSelection()
        this.setCouponStoreList()
      })
    },
    // 切换tab
    handleClick(tab, event) {
      this.activeName = tab.name
      this.params.status = tab.name == 'all' ? '' : tab.name
      this.params.page = 1
      this.getCouponActivityList()
    },
    dialogToUpload() {
      this.distributorDialogVisible = false
      var dialogBtnId = document.getElementById('coupon_up_' + this.dialogId)
      dialogBtnId.click()
    },
    addActivityData () {
      this.$router.push({path: this.matchHidePage('editor')})
    },
    editActivityAction (index, row) {
      this.$router.push({path: this.matchHidePage('editor'), query: {activity_id: row.activity_id}})
    },
    seeActivityAction (index, row) {
      this.$router.push({path: this.matchHidePage('detail'), query: {activity_id: row.activity_id}})
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getCouponActivityList()
    },
    checkTag(source, row) {
      if (row.source.includes(source)) {
        return true
      }
      return false
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    delActivityAction (index, row) {
      delCouponActivity(row.activity_id).then(response => {
        this.getCouponActivityList()
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    getCouponActivityList() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getCouponActivity(filter).then(response => {
        this.list = response.data.data.list
        console.log(this.list);
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    bindStoreCurrentChange(val) {
      this.bindStorePagination.page = val
      this.setCouponStoreList()
    },
    async setCouponStoreList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const {data: bindList} = await getCouponActivityShops(this.activity_id,this.bindStorePagination).catch(error => {
        loading.close()
      })
      loading.close()
      this.totalStoreCount = bindList.data.total_count
      this.distributorList = bindList.data.list
      this.dialogLoading = false
    },
    showDistributors(row) {
      this.dialogLoading = true
      this.activity_id = row.activity_id
      this.distributorDialogVisible = true
      if (this.distributorList.length > 0) {
        this.$refs['distributorTable'].clearSelection()
      }
      this.dialogTitle = '活动（' + row.activity_title + '）- 适用店铺列表'
      this.dialogId = row.activity_id
      this.setCouponStoreList()
    },
    beforeCloseDistributorDialog(){
      this.bindStorePagination.page = 1
      this.bindStorePagination.distributor_name = ''
      this.bindStorePagination.shop_code = ''
      this.bindStorePagination.status = ''
      this.distributorDialogVisible = false
      if (this.distributorList.length > 0) {
        this.$refs['distributorTable'].clearSelection()
      }
    },
  },
  mounted () {
    this.getCouponActivityList()
  },
  watch: {
    '$route' () {
      this.getCouponActivityList()
    }
  },
}
</script>
<style scoped lang="scss">
.empty-box {
  font-size: 18px;
  color: #999;
  text-align: center;
  height: 300px;
  line-height: 50px;
}
</style>
