<style type="text/css" lang="scss">
  .right-dialog {
    .el-transfer-panel {
      width: 240px;
    }
    .el-transfer-panel__footer {
      height: 38px;
      clear: both;
    }
  }
  .transfer-footer {
    padding: 6px 5px;
  }
  .alert-txt {
    padding: 15px 0;
  }
  .delay-dialog {
    .el-textarea {
      width: 80%;
      vertical-align: middle;
    }
    .option-box {
      padding: 15px 0;
      .el-date-editor {
        width: 70%;
      }
    }
  }
</style>

<template>
  <div>
    <el-table :data="list" :height="wheight-150" v-loading="loading">
      <el-table-column label="导购员">
        <template slot-scope="scope">
          {{scope.row.salesperson_info.salesman_name}}
        </template>
      </el-table-column>
      <el-table-column label="是否是朋友">
        <template slot-scope="scope">
          <span v-if="scope.row.is_friend == 0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="信息" min-width="90"></el-table-column>
      <el-table-column label="日志时间">
        <template slot-scope="scope">
          {{scope.row.created|datetime('yyyy-MM-dd hh:mm:ss')}}
        </template>
      </el-table-column>
    </el-table>
    <div v-if="total_count > params.pageSize" class="content-padded content-center">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="params.page"
        :total="total_count" :page-size="params.pageSize">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getWorkWechatRelLogsList } from '@/api/wechat'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      activeName: 'salespersonlogs',
      total_count: 0,
      list: [],
      user_id: 0,
      params: {
        page: 1,
        pageSize: 20,
      }
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    handleCurrentChange() {
      this.getWorkWechatRelLogsList()
    },
    getWorkWechatRelLogsList() {
      this.loading = true
      getWorkWechatRelLogsList(this.user_id, this.params).then(res => {
        this.list = res.data.data.list
        this.total_count = res.data.data.total_count
        this.loading = false
      })
    }
  },
  mounted () {
    this.user_id = this.$route.query.user_id
    this.getWorkWechatRelLogsList()
  }
}
</script>
