<template>
  <div>



    <el-row :gutter="20">
      <el-col :span="2">  </el-col>

      <el-col :span="2">
        <ImportDialog v-if="this.operator_type == 'admin' || this.operator_type == 'staff' || this.operator_type == 'disti'" style="margin-left: 10px"
         fileName="导入注销文件" fileType="upload_wechatuseroff"></ImportDialog>
         <!-- upload_distributor_operator -->
      </el-col>
      <!--
       -->
      <el-col :span="5">
        &nbsp;&nbsp;&nbsp;
        <el-button icon="el-icon-search" @click="dataSearch">查询导入信息</el-button>
      </el-col>

      <el-col :span="10">
        &nbsp;&nbsp;&nbsp;
        <el-button icon="el-icon-delete" @click="dataDelete">删除导入用户信息</el-button>
      </el-col>

      <el-col :span="6">
        <!-- <el-input placeholder="账号名" v-model="login_name"></el-input> -->
        <!-- el-icon-search -->
         <!-- slot="append" -->
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="1"></el-col>
      <el-col :span="18"> *步骤：  1 导入   ------>  2 查询导入信息 ------>  3 删除导入用户信息</el-col>
    </el-row>


    <el-table :data="accountsList" :height="wheight-300" v-loading="loading">
      <el-table-column prop="name" label="导入openId" width="320" >
        <template slot-scope="scope">{{scope.row.open_id}}</template>
      </el-table-column>

      <el-table-column prop="name" label="用户查询" width="200">
        <template slot-scope="scope">
            <span v-if="scope.row.is_user == true"   >用户存在 </span>

            <span v-if="scope.row.orderCount_active >0 " style="font-size: small;  color:red"> </br></br>有未完成订单或售后不可删除！ </span>
            <span v-if="scope.row.is_user == false" style="font-size: small;  color:red" >用户不存在！ </span>

        </template>
      </el-table-column>

      <el-table-column prop="name" label="订单数量" width="300">
        <template slot-scope="scope">
            <span v-if="scope.row.orderCount_active > 0" style="color:red"  >未完成：{{scope.row.orderCount_active}} </span></br>

            <span v-if="scope.row.orderCount_done   > 0"  >已完成：{{scope.row.orderCount_done}}  </span>

        </template>
      </el-table-column>
      <el-table-column prop="name" label="未完成订单" width="300">
        <template slot-scope="scope">
          <li v-for="item in scope.row.orderlist" @click="handleItemClick(item)">

            <div v-if="item.order_status=='PAYED' || item.order_status=='NOTPAY' || item.order_status=='WAIT_BUYER_CONFIRM' " class="">
            <router-link  target="_blank"  :to="{ path: '/distiadmin/order/entitytrade/tradenormalorders/tradenormalorders_detail', query: { orderId: item.order_id }}">
              {{item.order_id}}
              <!-- <el-tooltip effect="light" content="订单" placement="top">
                <i class="iconfont icon-eye"></i>
              </el-tooltip> -->
            </router-link>
            </div>
          </li>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="未完成售后" width="300">
        <template slot-scope="scope">
          <li v-for="item in scope.row.aftersaleslist.list" @click="handleItemClick(item)">

            <div v-if="item.aftersales_status=='0' || item.aftersales_status=='1' || item.aftersales_status=='5'  " class="">
            <router-link  target="_blank"  :to="{ path: '/distiadmin/order/entitytrade/aftersaleslist/aftersaleslist_detail', query: { aftersales_bn: item.aftersales_bn }}">
              {{item.aftersales_bn}}
              <!-- <el-tooltip effect="light" content="订单" placement="top">
                <i class="iconfont icon-eye"></i>
              </el-tooltip> -->
            </router-link>
            </div>
          </li>
        </template>
      </el-table-column>      

    </el-table>
    <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
      <el-pagination
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="params.page"
      :total="total_count" :page-size="params.pageSize">
      </el-pagination>
    </div>

  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Message } from 'element-ui'

  // import {createAccount, getAccountInfo, getAccountList, updateAccountInfo, deleteAccountInfo, getRolesList } from '../../../api/company'
  import {getwechatuserofflist, setwechatuseroffdone } from '../../../api/member'

  import ImportDialog from '@/components/importDialog'
  import store from '@/store'

  export default {
    components: {
      ImportDialog
    },
    props: {
      status: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        operator_type: '',
        isValid: true,
        DistributorStatus: false,
        relDistributors: [],
        DistributorVisible: false,
        login_type: 'default',
        isEdit: false,
        editVisible: false,
        editTitle: '',
        form: {
          operator_type: 'distributor',
          mobile: '',
          login_name: '',
          username: '',
          distributor_ids: [],
          password: '',
          role_id: []
        },
        activeName: 'distributor',
        mobile: '',
        login_name:'',
        editLoginName: '',
        editMobile: '',
        accountsList: [],
        detailData: {},
        loading: false,
        total_count: 0,
        params: {
          page: 1,
          pageSize: 20,
          operator_type: 'distributor'
        },
        operator_id: 0,
        rolesListData: [],
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {

      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getwechatuserofflist()
      },

      submitAction () { // 提交物料
        this.form.shop_ids = []
        this.form.distributor_ids = []
        if (this.relDistributors.length > 0) {
          this.relDistributors.forEach( distributor => {
            this.form.distributor_ids.push({name: distributor.name, distributor_id: distributor.distributor_id});
          })
        }
        // updateAccountInfo(this.operator_id, this.form).then(response => {
        //   this.$message.success('保存成功')
        //   this.detailData = response.data.data
        //   this.editVisible = false
        //   this.getwechatuserofflist()
        // })
      },
      dataSearch() {
        this.params.login_name = this.login_name
        this.params.page = 1
        this.getwechatuserofflist()
      },

      dataDelete() {
        // this.params.login_name = this.login_name
        // this.params.page = 1
        // this.getwechatuserofflist()

        let params = {keyfileid: this.keyfileid};

        this.$confirm('此操作将删除存在的用户, 但有未完成订单或未完成售后单不进行处理，是否继续? ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          setwechatuseroffdone(params).then(response => {
            this.$message({
              message: '处理成功！',
              type: 'success',
              duration: 5 * 1000
            })
          this.getwechatuserofflist()

          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消！'
          })
        })

      },

      getwechatuserofflist () {
        this.loading = true
        getwechatuserofflist(this.params).then(response => {
          console.log("response.data:::::::",response.data);
          this.accountsList = response.data.data.list
          // response.data.data.list || []
          this.total_count = response.data.data.total_count
          this.keyfileid   = response.data.data.key

          this.loading = false

        })
      },


    },
    mounted () {
      this.login_type = this.$store.getters.login_type
      this.getwechatuserofflist()
      this.operator_type = store.getters.login_type
    },

    watch: {
      status (val) {
        if (val) {
          this.getwechatuserofflist()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .el-tag + .el-tag {
    margin-left: 5px;
  }
  .new-tag {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .button-new-tag {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    height: 40px;
    line-height: 40px;
    width:138px;
    vertical-align: bottom;
  }

  .el-icon-delete {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    color: #ff5000;
    background-color: rgb(220, 150, 20);
    z-index: 2;
  }

  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 10px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
